import React,{useState} from 'react'
import MemberDetailPagePopup from './MemberDetailPagePopup';
import DrillsPage from '../../Admin/DrillsPage/DrillsPage';
import UpdateUserPopup from '../../Admin/UpdateUser/UpdateUserPopup';
// import AllUsersInfoandSessionPage from '../AllUsersInfoandSession/AllUsersInfoandSessionPage';
// import DrillsPage from '../DrillsPage/DrillsPage';
// import UpdateUserPopup from '../UpdateUser/UpdateUserPopup';

function AllMembersComp() {
    const [openUserInfoPage,setopenUserInfoPage] = useState(false);
    const [openDrillsPage,setopenDrillsPage] = useState(false);
    const [openUpdateUserPopup,setopenUpdateUserPopup] = useState(false);
    const [openemberDetailPage,setopenemberDetailPage] = useState(false);
    return (
    <>
        <div className='grid1_body p_876' >
            <div>Afaq Razaq</div>
            <div>Afaqwebdev@gmail.com</div>
            <div>12 May, 2024</div>
            {/* <div className='flex_center'>
                <button className='search_btn' >Clubs</button>
            </div> */}
            <div>
                <button className='search_btn' onClick={()=>{setopenemberDetailPage(true)}} >Sessions</button>
            </div>
            <div>
                <button className='search_btn' onClick={()=>{setopenDrillsPage(true)}} >Drills</button>
            </div>
            <div onClick={()=>{setopenUpdateUserPopup(true)}} >
                <svg className='pointer' width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_199787_64)">
                    <path d="M25.8398 3.45117L26.5488 4.16016C27.0996 4.71094 27.0996 5.60156 26.5488 6.14648L24.8438 7.85742L22.1426 5.15625L23.8477 3.45117C24.3984 2.90039 25.2891 2.90039 25.834 3.45117H25.8398ZM12.293 15.0117L20.1563 7.14258L22.8574 9.84375L14.9883 17.707C14.8184 17.877 14.6074 18 14.3789 18.0645L10.9512 19.043L11.9297 15.6152C11.9941 15.3867 12.1172 15.1758 12.2871 15.0059L12.293 15.0117ZM21.8613 1.46484L10.3008 13.0195C9.79102 13.5293 9.42188 14.1563 9.22852 14.8418L7.55273 20.7012C7.41211 21.1934 7.54688 21.7207 7.91016 22.084C8.27344 22.4473 8.80078 22.582 9.29297 22.4414L15.1523 20.7656C15.8438 20.5664 16.4707 20.1973 16.9746 19.6934L28.5352 8.13867C30.1816 6.49219 30.1816 3.82031 28.5352 2.17383L27.8262 1.46484C26.1797 -0.181641 23.5078 -0.181641 21.8613 1.46484ZM5.15625 3.75C2.30859 3.75 0 6.05859 0 8.90625V24.8438C0 27.6914 2.30859 30 5.15625 30H21.0938C23.9414 30 26.25 27.6914 26.25 24.8438V18.2813C26.25 17.502 25.623 16.875 24.8438 16.875C24.0645 16.875 23.4375 17.502 23.4375 18.2813V24.8438C23.4375 26.1387 22.3887 27.1875 21.0938 27.1875H5.15625C3.86133 27.1875 2.8125 26.1387 2.8125 24.8438V8.90625C2.8125 7.61133 3.86133 6.5625 5.15625 6.5625H11.7188C12.498 6.5625 13.125 5.93555 13.125 5.15625C13.125 4.37695 12.498 3.75 11.7188 3.75H5.15625Z" fill="#1ADEE5"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_199787_64">
                    <rect width="30" height="30" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
            </div>
        </div>
        { openemberDetailPage?(
            <>
                <MemberDetailPagePopup setopenemberDetailPage={setopenemberDetailPage} />
            </>
        ):
        <></>
        }
        { openDrillsPage?(
            <>
                <DrillsPage setopenDrillsPage={setopenDrillsPage} />
            </>
        ):<></>
        }
        { openUpdateUserPopup?(
            <>
                <UpdateUserPopup setopenUpdateUserPopup={setopenUpdateUserPopup} />
            </>
        ):<></>
        }
    </>
  )
}

export default AllMembersComp
