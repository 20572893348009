import React,{useContext, useEffect, useState} from 'react'
import SessionPage from './Session/SessionPage';
import DrillsPopup from './Drills/DrillsPopup';
import ConfirmPopup from '../ConfirmPopup';
import { userData } from '../../Context/Context';
// import MemberDetailPagePopup from './MemberDetailPagePopup';
// import DrillsPage from '../../Admin/DrillsPage/DrillsPage';
// import UpdateUserPopup from '../../Admin/UpdateUser/UpdateUserPopup';
// import AllUsersInfoandSessionPage from '../AllUsersInfoandSession/AllUsersInfoandSessionPage';
// import DrillsPage from '../DrillsPage/DrillsPage';
// import UpdateUserPopup from '../UpdateUser/UpdateUserPopup';

function AllMembersComp({club,user,isMember,isOwner,getClubDataById}) {
    // console.log(club);
    // console.log(user);
    const { userdata, url } = useContext(userData);
    const [openUserInfoPage,setopenUserInfoPage] = useState(false);
    const [openDrillsPage,setopenDrillsPage] = useState(false);
    const [openUpdateUserPopup,setopenUpdateUserPopup] = useState(false);
    const [openemberDetailPage,setopenemberDetailPage] = useState(false);
    const [openSessionPage,setopenSessionPage] = useState(false);
    const [userClubSessions,setuserClubSessions] = useState([]);
    const [userClubDrills,setuserClubDrills] = useState([]);
    const [openDrillsPopup,setopenDrillsPopup] = useState(false);
    const [openDeleteUserConfirmPopup,setopenDeleteUserConfirmPopup] = useState(false);

    useEffect(()=>{
        const userSessions = club.session.filter(session => session.created_by._id.toString() === user._id);
        setuserClubSessions(userSessions);

        const uniqueSessionDrillIds = Array.from(
            new Set(
                userSessions.flatMap(session => 
                session.drills.map(drill => drill.id)
              )
            )
          );
          const filteredDrills = club.drills.filter(drill =>
            uniqueSessionDrillIds.includes(drill._id)
          );
          setuserClubDrills(filteredDrills);

    },[])

    const [loading,setloading] = useState(false);
    const handleConfirmDeletePopup = async () =>{
        if(!isOwner){
            return;
        }
        setloading(true);
        const res = await fetch(`${url}deleteClubMember`,{
            method: "POST",
            headers: {
              "Content-Type" : "application/json"
            },
            body: JSON.stringify({
              clubId: club._id,
              memberId: user._id,
            }),
        });
        const data = await res.json();
        setloading(false);
        // console.log(data.data);
        if(data.msg === "success"){
            getClubDataById();
            setopenDeleteUserConfirmPopup(false);
        }
    }

    return (
    <>
        <div className='grid1_body p_876' >
            <div> {user?.name} </div>
            <div> {user?.email} </div>
            <div>12 May, 2024</div>
            {/* <div className='flex_center'>
                <button className='search_btn' >Clubs</button>
            </div> */}
            <div>
                <button className='search_btn' onClick={()=>{setopenSessionPage(true)}} >Sessions</button>
            </div>
            <div>
                <button className='search_btn' onClick={()=>{setopenDrillsPopup(true)}} >Drills</button>
            </div>
            { isOwner &&
                <div className='flex' >
                    <svg className='pointer' onClick={()=>{setopenDeleteUserConfirmPopup(true)}} xmlns="http://www.w3.org/2000/svg" fill='#1ADEE5' height="18" width="16" viewBox="0 0 448 512"><path d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z"/></svg>
                </div>
            }
        </div>
        { openSessionPage?(
            <>
                <SessionPage setopenSessionPage={setopenSessionPage} userSession={false} isOwner={isOwner} userClubSessions={userClubSessions} club={club} user={user} />
            </>
        ):<></>
        }
        {   openDrillsPopup ? (
            <>
                <DrillsPopup setopenDrillsPopup={setopenDrillsPopup} club={club} user={user} drills={userClubDrills}
                    isMember={false} isOwner={isOwner}
                />
            </>
        ):<></>
        }
        { openDeleteUserConfirmPopup?
            <>
                <ConfirmPopup open={openDeleteUserConfirmPopup} setOpen={setopenDeleteUserConfirmPopup} handleconfirm={handleConfirmDeletePopup}
                    message={`Are you sure you want to delete your this user?`} loading={loading} />
            </>:<></>
        }
    </>
  )
}

export default AllMembersComp
