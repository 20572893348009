import React,{useEffect,useState} from 'react'

function PrintClubSessionComp({club,drill}) {
    const [drillData,setdrillData] = useState({});
    // console.log(drill.id);
    // console.log(matchedDrill);
    
    useEffect(()=>{
        const matchedDrill = club.drills.filter( (mydrill) =>{ return mydrill._id === drill.id });
        setdrillData(matchedDrill[0]);
    },[]);

    return (
    <>
        <div className='ps-index' style={{whiteSpace:'pre-line'}} > {drill.name} </div>
        <div className='ps-img-con' >
            <div className='ps-img-con-left' >
                <img src={drillData?.imageUrl} />
            </div>
            <div className='ps-txt-con-right' >
                <div style={{whiteSpace:'pre-line'}} > <span style={{color:'black',fontSize:'13px',fontWeight:'700'}} >Setup</span> { drillData?.setup } </div>
                <div style={{whiteSpace:'pre-line'}} > <span style={{color:'black',fontSize:'13px',fontWeight:'700'}} >Instructions</span> {drillData?.instructions} </div>
                <div style={{whiteSpace:'pre-line'}} > <span style={{color:'black',fontSize:'13px',fontWeight:'700'}} >Coaching Points</span> {drillData?.points} </div>                        
            </div>
        </div>
        <hr style={{width:'90%',margin:'1rem auto',marginBottom: '0.2rem'}} />  
    </>
  )
}

export default PrintClubSessionComp
