import React, { useRef, useEffect, useState } from 'react';

function CanvasApp({ selectedTool }) {
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [endX, setEndX] = useState(0);
  const [endY, setEndY] = useState(0);
  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
  const [endPoint, setEndPoint] = useState({ x: 0, y: 0 });
  const dashLength = 10; // Adjust the length of dashes as needed
  const [lineDash,setlineDash] = useState(true);

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = window.innerWidth * 0.7; // Adjust canvas size as needed
    canvas.height = window.innerHeight * 0.7;

    const context = canvas.getContext('2d');
    context.lineCap = 'round';
    context.lineJoin = 'round';
    context.strokeStyle = 'black';
    context.lineWidth = 2;
    contextRef.current = context;
  }, []);

  const startDrawing = ({ nativeEvent }) => {
    const { offsetX, offsetY } = nativeEvent;
    if (selectedTool === 'pen') {
      setIsDrawing(true);
      setStartPoint({ x: offsetX, y: offsetY });
      setEndPoint({ x: offsetX, y: offsetY });
    } else {
      setIsDrawing(true);
      setStartX(offsetX);
      setStartY(offsetY);
      setEndX(offsetX);
      setEndY(offsetY);
    }
  };

  const draw = ({ nativeEvent }) => {
    if (!isDrawing) return;

    const { offsetX, offsetY } = nativeEvent;

    if (selectedTool === 'pen') {
      contextRef.current.beginPath();
      contextRef.current.moveTo(startPoint.x, startPoint.y);
      contextRef.current.lineTo(offsetX, offsetY);
      contextRef.current.stroke();
      setStartPoint({ x: offsetX, y: offsetY });
    } else {
      setEndX(offsetX);
      setEndY(offsetY);
    }
  };

  const endDrawing = () => {
    if (selectedTool === 'pen') {
      contextRef.current.closePath();
    } else if (selectedTool === 'line') {
      if(lineDash){
        contextRef.current.setLineDash([dashLength, dashLength]); // Set the line dash pattern
      }
      contextRef.current.beginPath();
      contextRef.current.moveTo(startX, startY);
      contextRef.current.lineTo(endX, endY);
      contextRef.current.stroke();
      if(lineDash){
        contextRef.current.setLineDash([]); // Reset the line dash pattern
      }
    } else {
      // Handle shape drawing logic here
      if (selectedTool === 'rectangle') {
        contextRef.current.strokeRect(
          startX,
          startY,
          endX - startX,
          endY - startY
        );
      } else if (selectedTool === 'circle') {
        const radius = Math.sqrt(
          Math.pow(endX - startX, 2) + Math.pow(endY - startY, 2)
        );
        contextRef.current.beginPath();
        contextRef.current.arc(startX, startY, radius, 0, 2 * Math.PI);
        contextRef.current.stroke();
      } else if (selectedTool === 'triangle') {
        contextRef.current.beginPath();
        contextRef.current.moveTo(startX, startY);
        contextRef.current.lineTo(endX, endY);
        contextRef.current.lineTo(startX - (endX - startX), endY);
        contextRef.current.closePath();
        contextRef.current.stroke();
      }
    }

    setIsDrawing(false);
  };

  return (
    <canvas
      ref={canvasRef}
      onMouseDown={startDrawing}
      onMouseMove={draw}
      onMouseUp={endDrawing}
    />
  );
}

export default CanvasApp;
