import React, { useState } from 'react'
import ClubSessionDrillsPopup from './ClubSessionDrillsPopup';
// import SessionDrillsPopup from '../SessionDrillsPopup/SessionDrillsPopup';

function ClubSessionComp() {
  const [openclubSessionDrillsPopup,setopenclubSessionDrillsPopup] = useState(false);
  
  return (
    <>
        <div className='session_comp' onClick={()=>{setopenclubSessionDrillsPopup(true)}} >
            <div className='session_comp_left' >
                <div className='normal_text' > <span className='g_mini_text fs_20' >Name : </span> 27/7 sample session name </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Team : </span> BlackCubes </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Duration : </span> 1 min, 30 sec </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Intensity : </span> 5 </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Date : </span> 24 May, 2024 </div>
            </div>
            <div className='session_comp_right' >
                <div className='normal_text' > <span className='g_mini_text fs_20' >Drills added : </span> 24 </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Note : </span></div>
                <div className='normal_text scroll_admin_section height_3r' >Lorem ipsum dolor sit Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt utamet, consectetur adipiscing elit, sed do eiusmod tempor incididunt utLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna eiusmod tempor. </div>
            </div>
        </div>
        {   openclubSessionDrillsPopup ? (
            <>
                <ClubSessionDrillsPopup setopenclubSessionDrillsPopup={setopenclubSessionDrillsPopup} />
            </>
        ):<></>
        }
    </>
  )
}
export default ClubSessionComp