import React, { useContext, useState } from 'react'
import { userData } from '../../Context/Context';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function InviteClubComp({club,status}) {
    const {url,userdata,getAllDataforAdmin} = useContext(userData);
    const [loading,setloading] = useState(false);
    const [openSuccess,setopenSuccess] = useState(false);
    const [openFailed,setopenFailed] = useState(false);
    const [openDublicate,setopenDublicate] = useState(false);
    const [openAlreadyMember,setopenAlreadyMember] = useState(false);
    const [openOwner,setopenOwner] = useState(false);
    const [requested,setrequested] = useState(false);

    const handleRequestJoinClub = async (e) => {
        e.preventDefault();
        setloading(true);
        const res = await fetch(`${url}requestJoinClub`,{
            method: "POST",
            headers: {
              "Content-Type" : "application/json",
              "authToken": localStorage.getItem("authToken"),
            },
            body: JSON.stringify({
              userId: userdata._id,
              userEmail: userdata.email,
              clubId: club._id,
              title: club.title,
              owner: club.owner.name,
              description: club.description,
            }),
        });
        const data = await res.json();
        setloading(false);

        if(data.msg === "success"){
            // console.log(data.club);
            getAllDataforAdmin();
            setopenSuccess(true);
            setrequested(true);
            setTimeout(() => {
                setopenSuccess(false);
            }, 3000);
        }else if(data.msg === "duplicate_request"){
            setrequested(false);
            setopenDublicate(true);
            setTimeout(() => {
                setopenDublicate(false);
            }, 3000);
            // console.log('dublicate');
        }else if(data.msg === 'already member'){
            setrequested(false);
            setopenAlreadyMember(true);
            setTimeout(() => {
                setopenAlreadyMember(false);
            }, 3000);
        }else if(data.msg === 'owner'){
            setopenOwner(true);
            setTimeout(() => {
                setopenOwner(false);
            }, 3000);
        }else{
            setrequested(false);
            setopenFailed(true);
            setTimeout(() => {
                setopenFailed(false);
            }, 3000);
            // console.log('failed');
        }
    };
  return (
    <>
        <Snackbar open={openSuccess} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="success" sx={{ width: '100%' }}>
                Requested to join the club successfully!
            </Alert>
        </Snackbar>
        <Snackbar open={openFailed} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="error" sx={{ width: '100%' }}>
                Updating Failed!
            </Alert>
        </Snackbar>
        <Snackbar open={openDublicate} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="error" sx={{ width: '100%' }}>
                You have already requested to join the club!
            </Alert>
        </Snackbar>
        <Snackbar open={openAlreadyMember} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="error" sx={{ width: '100%' }}>
                You are already a member of this club!
            </Alert>
        </Snackbar>
        <Snackbar open={openOwner} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="error" sx={{ width: '100%' }}>
                You are the owner of this club!
            </Alert>
        </Snackbar>
        <div className='club_comp' >
            <div>
                <div className='normal_text flex_between2' >
                    <div><span className='g_mini_text fs_20' >Title : </span> { club.title } </div>
                    <button className='search_btn' disabled={loading} onClick={handleRequestJoinClub} > {loading ? 'Joining' : 'Join'} { requested && 'Requested' } </button>
                    {/* <div className='normal_text' > <span className='g_mini_text fs_20' >Status : </span> <span className={ status === 'approved' ? 'approved_text' : status === 'rejected' ? 'rejected_text' : 'pending_text' } > {status} </span> </div> */}
                </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Members : </span> {club.members.length} </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Owner : </span> {club.owner?.name} </div>
                <div> <span className='g_mini_text fs_20' >Description</span>  </div>
                <div className='normal_text scroll_admin_section height_3r' > {club.description} </div>
            </div>    
            <div>
                <div className='flex_end' ><span className='g_mini_text fs_20' >
                {new Date(club.created_at).toLocaleString('en-US', { day: 'numeric', month: 'short',year: 'numeric' })}
                </span></div>
            </div>
        </div> 
    </>
  )
}

export default InviteClubComp
