import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';

function DrillCompAdd({drill,id,index,userdata,setuserdata,getSessionDrills,url,alradyErrorMsg,setalradyErrorMsg,addSuccessMsg,setaddSuccessMsg,getLoggedin,popup,selectedDrillId,setsessionPopup,setselectedDrillId}) {
    const [dropDown,setDropDown] = useState(false);
    const [showSetting,setshowSetting] = useState(false);
    const [submitting,setSubmitting] = useState(false);
    const handleMouseOver = ()=>{
        setshowSetting(true);
    }
    const handleMouseLeave = ()=>{
        setshowSetting(false);
        setDropDown(false);
    }
    const deleteSession = async ()=>{
        // setSubmitting(true);
        setDropDown(false);
        const res = await fetch(`${url}deleteSession`,{
          method: "POST",
          headers: {
            "Content-Type" : "application/json"
          },
          body: JSON.stringify({
            id: userdata._id,
            sessionId: drill._id,
          }),
        });
        const data = await res.json();
        
        if(data.msg === "success"){
            console.log('success');
            // getLoggedin();
            setuserdata(data.data);
        }else{
            console.log('failed');
        }
    }
    const handleAddDrillToSession = async ()=>{
        setSubmitting(true);
        const res = await fetch(`${url}addDrillToSession`,{
            method: "POST",
            headers: {
              "Content-Type" : "application/json"
            },
            body: JSON.stringify({
              id: userdata._id,
              sessionId: id,
              drillId: drill._id,
            }),
          });
          setSubmitting(false);
          const data = await res.json();
  
          if(data.msg === "success"){
            //   console.log('success');
                getSessionDrills();
              setaddSuccessMsg(true);
              setTimeout(() => {
                setaddSuccessMsg(false);
              }, 4000);
              setsessionPopup(false);
              setselectedDrillId('');
              // getLoggedin();
              setuserdata(data.data);
          }else if(data.msg=== 'already exists'){
            setalradyErrorMsg(true);
            setTimeout(() => {
              setalradyErrorMsg(false);
            }, 4000);
          }else{
              setsessionPopup(false);
            //   console.log('failed');
          }
    }

    return (
    <>
        <div className='session-elem' style={{display:'flex',justifyContent:'space-between',alignItems:'center'}} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseOver} >
          <div>
            <div className='s-con-title1' > {drill.name} </div>
            <div> <span style={{color:'black',fontWeight:'500'}} >Setup:</span> {drill.setup} </div>
            <div className='s-con-title2' > <span style={{color:'black',fontWeight:'500'}} >Date:</span> {new Date(drill.date).toLocaleString('en-US', { day: 'numeric', month: 'short',year: 'numeric' })} ( drills) </div>
          </div>
          <div>
            <div className='select-add-session' onClick={handleAddDrillToSession} style={{color:'#2f8c2f'}} > { submitting?'SAVING':'SELECT' } </div>
          </div>
        </div>
    </>
  )
}

export default DrillCompAdd
