import React, { useContext, useState, useEffect } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import Navbar from '../../../Navbar';
import { userloggedIn } from '../../../Context/Context'
import { userData } from '../../../Context/Context'
import '../../../Library/PrintSession.css'

function PrintClubDrill() { 
    const { userlogged,setuserLogged } = useContext(userloggedIn);
    const { userdata,setuserdata,url,homeNav,sethomeNav,getLoggedin } = useContext(userData);
    // sethomeNav(false);
    const location = useLocation();
    const clubId = new URLSearchParams(location.search).get('club');
    const drillId = new URLSearchParams(location.search).get('drill');

    const Navigate = useNavigate();
    const [drillData,setdrillData] = useState({});
    const [printReady,setprintReady] = useState(false);
    
    const handlePrint = () => {
        setprintReady(true);
        setTimeout(() => {
            window.print();
        }, 500);
        setTimeout(() => {
            setprintReady(false);
        }, 2000);
    };
    const cancelPrint = ()=>{
        Navigate(-1);
    }
    const getclubDrillData = async ()=>{
        const res = await fetch(`${url}getClubDrillData`,{
          method: "POST",
          headers: {
            "Content-Type" : "application/json"
          },
          body: JSON.stringify({
            clubId, drillId
          }),
        });
        const data = await res.json();
        if(data.msg === "success"){
            console.log('success');
            console.log(data.data);
            setdrillData(data.data);
        }else{
            console.log('failed');
        }
    }
    useEffect(()=>{
        getclubDrillData();
    },[]);
    
    useEffect(() => {
        const handleAfterPrint = () => {
        //   console.log('Print dialog closed');
          setprintReady(false);
    
          // Check if the user canceled the print
          const isPrintCancelled = !document.hidden;
    
          if (isPrintCancelled) {
            // console.log('Print was canceled');
          } else {
            console.log('Print was successful');
          }
    
          // Remove the event listener
          window.removeEventListener('afterprint', handleAfterPrint);
        };
    
        // Add an event listener for the afterprint event
        window.addEventListener('afterprint', handleAfterPrint);
    
        return () => {
          // Remove the event listener when the component unmounts
          window.removeEventListener('afterprint', handleAfterPrint);
        };
    }, []);
      
  return (
    <>
        { !printReady && <Navbar />}
        <div className='library-main' style={{display:'flex',flexDirection:'column',alignItems: 'center',gap:'1rem',paddingBottom:'2rem'}} >
            <div className='overlay' ></div>
            <div className='print-mini-head' style={{display: printReady?'none':'block' }}>
                <button className='navbar-save-btn' onClick={handlePrint} >PRINT </button>
                <button className='navbar-save-btn' onClick={cancelPrint} >CANCEL </button>
            </div>
            <div className='print-mini-head alert-warning' style={{display: printReady?'none':'block' }} >
                Use<span style={{fontWeight:'500'}} >&nbsp;A4&nbsp;</span>papar size for best print output.
            </div>
            <div className='print-content'>
                <div className='print-header' style={{whiteSpace: 'pre'}}>
                    { drillData.name }
                </div>
                <div className='print-img'>
                    <img src={drillData.imageUrl} />
                    <div className='print-img-text-bot' >
                        <div className='print-img-mini-txt'>
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg>
                            <div> {drillData.duration} </div>
                        </div>
                        <div className='print-img-mini-txt' >
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M344 0H488c13.3 0 24 10.7 24 24V168c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39-87 87c-9.4 9.4-24.6 9.4-33.9 0l-32-32c-9.4-9.4-9.4-24.6 0-33.9l87-87L327 41c-6.9-6.9-8.9-17.2-5.2-26.2S334.3 0 344 0zM168 512H24c-13.3 0-24-10.7-24-24V344c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39 87-87c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8z"/></svg>                            
                            <div> {drillData.fieldSize} </div>
                        </div>
                    </div>
                </div>
                <div className='print-text-con'>
                    <div className='print-title' > Setup </div>
                    <div className='print-description' style={{whiteSpace: 'pre-line'}} > {drillData.setup} </div> 
                    <div className='print-title' > Instructions </div>
                    <div className='print-description' style={{whiteSpace: 'pre-line'}} > {drillData.instructions} </div>
                    <div className='print-title' > Coaching Points </div>
                    <div className='print-description' style={{whiteSpace: 'pre-line'}} >{drillData.points} </div>
                </div>
                <div className='print-footer'>
                    <hr style={{width:'10rem'}} />
                    <div>Generated by <span style={{color:'black',fontWeight:'500'}} > www.tacticalcentre.com.au </span></div>
                    <img src='/img/soccer-specific-logo-square.png' style={{height:'4rem'}} />
                    <hr style={{width:'10rem'}} />
                </div>
            </div>
        </div>
    </>
  );
}

export default PrintClubDrill;