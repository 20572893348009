import React, { useContext, useState, useEffect } from 'react';
import { useParams,useNavigate,useLocation } from 'react-router-dom';
import Navbar from '../Navbar';
import { userloggedIn } from '../Context/Context'
import { userData } from '../Context/Context'
import './PrintSession.css'

function PrintSession() { 
    const { userlogged,setuserLogged } = useContext(userloggedIn);
    const { userdata,setuserdata,url,homeNav,sethomeNav,getLoggedin } = useContext(userData);
    sethomeNav(false);
    const { id } = useParams();
    const Navigate = useNavigate();
    const [drills,setdrills] = useState({});
    const [printReady,setprintReady] = useState(false);

    const [name,setName] = useState('');
    const [notes,setNotes] = useState('');
    const [team,setTeam] = useState('');
    const [duration,setDuration] = useState('');
    const [intensity,setIntensity] = useState('');
    const [date,setDate] = useState('');

    const handlePrint = () => {        
        setprintReady(true);
        setTimeout(() => {
            window.print();
        }, 500);
        setTimeout(() => {
            setprintReady(false);
        }, 2000);
    };
    const cancelPrint = ()=>{
        Navigate(-1);
    }
    const getSessionDrills = async ()=>{
        const res = await fetch(`${url}getSessionDrills`,{
          method: "POST",
          headers: {
            "Content-Type" : "application/json"
          },
          body: JSON.stringify({
            id: userdata._id,
            sessionId: id,
          }),
        });
        const data = await res.json();
        // console.log(data);
        if(data.msg === "success"){
            // console.log(data.data);
            setdrills(data.data);
            // getLoggedin();
        }else{
            // console.log('failed');
        }
    }
    const getSessionInfo = async ()=>{
        // setLoading(true);
        const res = await fetch(`${url}getSessionInfo`,{
          method: "POST",
          headers: {
            "Content-Type" : "application/json"
          },
          body: JSON.stringify({
            id: userdata._id,
            sessionId: id,
          }),
        });
        const data = await res.json();
        // console.log(data);
        // setLoading(false);
        if(data.msg === "success"){
            // console.log(data.user);
            // getLoggedin();
            setuserdata(data.user);
            setName(data.data.name);
            setNotes(data.data.notes);
            setTeam(data.data.team);
            setIntensity(data.data.intensity);
            setDuration(data.data.duration);
            setDate(data.data.date);
        }else{
            // console.log('failed');
        }
    }
    useEffect(()=>{
        getSessionInfo();
        getSessionDrills();
    },[]);
    
    useEffect(() => {
        const handleAfterPrint = () => {
        //   console.log('Print dialog closed');
          setprintReady(false);
    
          // Check if the user canceled the print
          const isPrintCancelled = !document.hidden;
    
          if (isPrintCancelled) {
            // console.log('Print was canceled');
          } else {
            console.log('Print was successful');
          }
    
          // Remove the event listener
          window.removeEventListener('afterprint', handleAfterPrint);
        };
    
        // Add an event listener for the afterprint event
        window.addEventListener('afterprint', handleAfterPrint);
    
        return () => {
          // Remove the event listener when the component unmounts
          window.removeEventListener('afterprint', handleAfterPrint);
        };
      }, []);
      
  return (
    <>
        { !printReady && <Navbar />}
        <div className='library-main' style={{display:'flex',flexDirection:'column',alignItems: 'center',gap:'1rem',paddingBottom:'2rem'}} >
            <div className='overlay' ></div>
            <div className='print-mini-head' style={{display: printReady?'none':'block' }}>
                <button className='navbar-save-btn' onClick={handlePrint} >PRINT </button>
                <button className='navbar-save-btn' onClick={cancelPrint} >CANCEL </button>
            </div>
            <div className='print-mini-head alert-warning' style={{display: printReady?'none':'block' }} >
                Use<span style={{fontWeight:'500'}} >&nbsp;A4&nbsp;</span>papar size for best print output.
            </div>
            <div className='print-content'>
                <div className='print-s-header'>
                    <div className='ps-title' > { name } </div>
                    <div className='ps-txt-con' >
                        <div>Date <span style={{color:'black',fontWeight:'500'}} > {date} </span> </div>
                        <div>Duration <span style={{color:'black',fontWeight:'500'}} > { duration } </span> </div>
                        <div>Team <span style={{color:'black',fontWeight:'500'}} > { team } </span> </div>
                    </div>
                </div>
                <hr style={{width:'90%',margin:'1rem auto',marginBottom: '0.2rem'}} />
                {  drills.length>0 && drills.map((drill,index)=>{
                    return(
                        <>
                            <div className='ps-index' style={{whiteSpace:'pre-line'}} > {drill.name} </div>
                            <div className='ps-img-con' >
                                <div className='ps-img-con-left' >
                                    <img src={drill.imageUrl} />
                                </div>
                                <div className='ps-txt-con-right' >
                                    <div style={{whiteSpace:'pre-line'}} > <span style={{color:'black',fontSize:'13px',fontWeight:'700'}} >Setup</span> { drill.setup } </div>
                                    <div style={{whiteSpace:'pre-line'}} > <span style={{color:'black',fontSize:'13px',fontWeight:'700'}} >Instructions</span> {drill.instructions} </div>
                                    <div style={{whiteSpace:'pre-line'}} > <span style={{color:'black',fontSize:'13px',fontWeight:'700'}} >Coaching Points</span> {drill.points} </div>                        
                                </div>
                            </div>
                            <hr style={{width:'90%',margin:'1rem auto',marginBottom: '0.2rem'}} />
                        </>
                    )
                })
                }
                <div className='print-footer'>
                    <hr style={{width:'10rem'}} />
                    <div>Generated by <span style={{color:'black',fontWeight:'500'}} > www.tacticalcentre.com.au </span></div>
                    <img src='/img/soccer-specific-logo-square.png' style={{height:'4rem'}} />
                    <hr style={{width:'10rem'}} />
                </div>
            </div>
        </div>
    </>
  );
}

export default PrintSession;