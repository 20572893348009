import React, { useState,useContext } from 'react'
import ClubDetailPage from './ClubDetailPage';
import { NavLink } from 'react-router-dom';

function ClubsComp({club,index}) {
    const [openClubDetailPage,setopenClubDetailPage] = useState(false);
    
    const [isCopied, setIsCopied] = useState(false);
    const handleCopyClick = () => {
      // Create a temporary textarea element to copy the text
      const textarea = document.createElement('textarea');
      textarea.value = club.link;
      document.body.appendChild(textarea);

      // Select the text in the textarea
      textarea.select();

      try {
        // Execute the copy command
        document.execCommand('copy');
        setIsCopied(true);
      } catch (err) {
        console.error('Unable to copy text', err);
      }

      // Remove the temporary textarea
      document.body.removeChild(textarea);

      // Reset the copy status after a short delay
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    };
  return (
    <>
        <div className='club_comp' >
            <div>
                <div className='normal_text flex_between2' >
                    <div><span className='g_mini_text fs_20' >Title : </span> { club.title } </div>
                    {/* <div className='scale_cube' onClick={()=>{setopenClubDetailPage(true)}} > */}
                    <NavLink to={`/club/${club._id}`} className='scale_cube' >
                        <svg width="21" height="25" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.28571 2.25C1.02143 2.25 0 3.25547 0 4.5V11.25C0 12.4945 1.02143 13.5 2.28571 13.5C3.55 13.5 4.57143 12.4945 4.57143 11.25V6.75H9.14286C10.4071 6.75 11.4286 5.74453 11.4286 4.5C11.4286 3.25547 10.4071 2.25 9.14286 2.25H2.28571ZM4.57143 24.75C4.57143 23.5055 3.55 22.5 2.28571 22.5C1.02143 22.5 0 23.5055 0 24.75V31.5C0 32.7445 1.02143 33.75 2.28571 33.75H9.14286C10.4071 33.75 11.4286 32.7445 11.4286 31.5C11.4286 30.2555 10.4071 29.25 9.14286 29.25H4.57143V24.75ZM22.8571 2.25C21.5929 2.25 20.5714 3.25547 20.5714 4.5C20.5714 5.74453 21.5929 6.75 22.8571 6.75H27.4286V11.25C27.4286 12.4945 28.45 13.5 29.7143 13.5C30.9786 13.5 32 12.4945 32 11.25V4.5C32 3.25547 30.9786 2.25 29.7143 2.25H22.8571ZM32 24.75C32 23.5055 30.9786 22.5 29.7143 22.5C28.45 22.5 27.4286 23.5055 27.4286 24.75V29.25H22.8571C21.5929 29.25 20.5714 30.2555 20.5714 31.5C20.5714 32.7445 21.5929 33.75 22.8571 33.75H29.7143C30.9786 33.75 32 32.7445 32 31.5V24.75Z" fill="#41BAC7"/>
                        </svg>
                    </NavLink>
                </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Members : </span> {club.members.length} </div>
                {/* <div className='normal_text' > <span className='g_mini_text fs_20' >Owner : </span> {club.owner} </div> */}
                <div className='m_7878' > 
                    <span className='g_mini_text fs_20' >Link : </span> 
                    <span className='link_copy_field normal_text' > {club.link} 
                        <span>
                            { isCopied ?
                                <svg className='m_546' xmlns="http://www.w3.org/2000/svg" height="12" width="10" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                            :
                                <svg onClick={handleCopyClick} fill='green' className='m_546' width="12" height="14" viewBox="0 0 16 18" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_199820_1817)">
                                    <path d="M13.7143 11.8125H6.85714C6.54286 11.8125 6.28571 11.5594 6.28571 11.25V2.25C6.28571 1.94062 6.54286 1.6875 6.85714 1.6875H11.8607L14.2857 4.07461V11.25C14.2857 11.5594 14.0286 11.8125 13.7143 11.8125ZM6.85714 13.5H13.7143C14.975 13.5 16 12.491 16 11.25V4.07461C16 3.62812 15.8179 3.19922 15.4964 2.88281L13.075 0.495703C12.7536 0.179297 12.3179 0 11.8643 0H6.85714C5.59643 0 4.57143 1.00898 4.57143 2.25V11.25C4.57143 12.491 5.59643 13.5 6.85714 13.5ZM2.28571 4.5C1.025 4.5 0 5.50898 0 6.75V15.75C0 16.991 1.025 18 2.28571 18H9.14286C10.4036 18 11.4286 16.991 11.4286 15.75V14.625H9.71429V15.75C9.71429 16.0594 9.45714 16.3125 9.14286 16.3125H2.28571C1.97143 16.3125 1.71429 16.0594 1.71429 15.75V6.75C1.71429 6.44063 1.97143 6.1875 2.28571 6.1875H3.42857V4.5H2.28571Z" fill="#1E1A26"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_199820_1817">
                                    <rect width="16" height="18" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            }
                        </span>
                    </span>
                </div>
                <div> <span className='g_mini_text fs_20' >Description</span>  </div>
                <div className='normal_text scroll_admin_section height_3r' > {club.description} </div>
            </div>    
            <div>
                <div className='flex_end' ><span className='g_mini_text fs_20' >
                {new Date(club.created_at).toLocaleString('en-US', { day: 'numeric', month: 'short',year: 'numeric' })}
                </span></div>
            </div>
        </div>
        { openClubDetailPage?(
            <>
                <ClubDetailPage setopenClubDetailPage={setopenClubDetailPage} />
            </>
          ):<></>
        }
    </>
  )
}
export default ClubsComp