import { useState,useEffect } from 'react';
import { Routes, Route, NavLink,useContext,useNavigate } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import Navbar from './components/Navbar';
import SignUp from './components/SignUp';
import LoginForm from './components/LoginForm'
import Favicon from "react-favicon";

import { Adminloggedin } from './components/Context/Context';
import { userloggedIn } from './components/Context/Context';
import { userData } from './components/Context/Context';
import Library from './components/Library/Library';
import NewSession from './components/NewSession';
import EditHomeDrill from './components/EditHomeDrill';
import EditSession from './components/Library/EditSession';
import Profile from './components/Profile';
import PrintDrill from './components/PrintDrill';
import PrintSession from './components/Library/PrintSession';
import Admin from './components/Admin/Admin';

import Dashboard from './components/Admin/Dashboard';
import AddUser from './components/Admin/AddUser';
import AllUsers from './components/Admin/All Users/AllUsers';
import Clubs from './components/Admin/Clubs/Clubs';
import History from './components/Admin/History';
import Password from './components/Admin/Password';
import ClubMain from './components/Club/ClubMain';
import ClubDetail from './components/Club/ClubDetail';
import ClubDetailAdmin from './components/Admin/Clubs/ClubDetail/ClubDetailAdmin';
import PrintClubSession from './components/Club/Print/Session/PrintClubSession';
import PrintClubDrill from './components/Club/Print/Drill/PrintClubDrill';

function App() {
  const [adminLogin,setAdminLogin] = useState(false);
  const [userlogged,setuserLogged] = useState(false);
  const [homeNav,sethomeNav] = useState(false);
  const [userdata,setuserdata] = useState({});
  const [clubsUserOwned,setclubsUserOwned] = useState([]);
  const [clubsUserJoined,setclubsUserJoined] = useState([]);

  // Admin
  const [allUsersData,setallUsersData] = useState([]);
  const [allClubsData,setallClubsData] = useState([]);
  const [notifications,setnotifications] = useState([]);
  const [ClubRequests,setClubRequests] = useState([]);
  const [ClubJoinRequests,setClubJoinRequests] = useState([]);
  const [UserSignedUpRequests,setUserSignedUpRequests] = useState([]);
  const [showSearchUser,setShowSearchUser] = useState(false);
  const isMobile = window.innerWidth <= 480;
  const [url,seturl] = useState('https://server.tacticalcentre.com.au/');
  // const [url,seturl] = useState('/');
  const Navigate = useNavigate();
  // cloudinary.config({
  //   cloud_name: 'your_cloud_name',
  //   api_key: 'your_api_key',
  //   api_secret: 'your_api_secret',
  // });
  const logout =async ()=>{
    const res = await fetch(`${url}logout`,{
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type":"application/json",
        "authToken": localStorage.getItem("authToken"),
      }
    });
    const data = await res.json();
    if(data){
      if(data.msg === "loggedOut"){
        localStorage.removeItem("authToken");
        window.location.reload('/');
      }
    }
  }
  const getLoggedin = async ()=>{
      const res = await fetch(`${url}isloggedin`,{
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type":"application/json",
        "authToken": localStorage.getItem("authToken"),
      }
    });
    const data = await res.json();
    // console.log(data.data);
    if(data.msg === "loggedin"){
      setuserLogged(true);
      // console.log(data.clubsUserJoined);
      // console.log(data.clubsUserOwned);
      setclubsUserOwned(data.clubsUserOwned);
      setclubsUserJoined(data.clubsUserJoined);
      setuserdata(data.data);
      // console.log(userData);
    }else if(data.msg === 'admin'){
      setAdminLogin(true);
      setuserdata(data.data);
      Navigate('/');
    }else{
      // console.log("USER NOT LOGGED");
      setAdminLogin(false);
      setuserLogged(false);
      setuserdata({});
    }
    // console.log(userData);
  }
  useEffect(()=>{
    getLoggedin();
    // allArticles();
  },[]);

  const [activeSidebar,setactiveSidebar] = useState(1);
  function handleChange(number){
      // console.log(number);
      setactiveSidebar(number);
  }
  const [numberOfUsers,setnumberOfUsers] = useState(0);
  const [numberOfClubs,setnumberOfClubs] = useState(0);
  const getAllDataforAdmin = async ()=>{
    const res = await fetch(`${url}getAllDataforAdmin`,{
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type":"application/json",
        "authToken": localStorage.getItem("authToken"),
      }
    });
    const data = await res.json();
    // console.log(data.data);
    if(data.msg === "success"){
      // console.log(data.data);
      setnumberOfUsers(data.data.length);
      setallUsersData(data.data);
      setallClubsData(data.allClubs);
      setnumberOfClubs(data.allClubs.length);
      // console.log(data.allClubs);
      setnotifications(data.notifications);
      setClubRequests(data.ClubRequests);
      setClubJoinRequests(data.ClubJoinRequests);
      setUserSignedUpRequests(data.UserSignedUpRequests);
      // console.log(data.ClubJoinRequests);
    }
  }
  useEffect(()=>{
    getAllDataforAdmin();
  },[]);

  const [userSignedNotify,setuserSignedNotify] = useState([]);
  const [clubCreatedNotify,setclubCreatedNotify] = useState([]);
  const [historyNotifications,sethistoryNotifications] = useState([])
  useEffect(()=>{
    const userSigned = notifications.filter((notify)=>{
      return notify.type === 'userSigned'
    });
    setuserSignedNotify(userSigned);

    const historyNotify = notifications.filter((notify,ind)=>{
      return notify.type === 'clubCreated' || notify.type === 'clubApproved' || notify.type === 'clubRejected' 
    })
    sethistoryNotifications(historyNotify);
    // const clubCreated = notifications.filter((notify)=>{
    //   return notify.type === 'clubCreated'
    // });
    // setclubCreatedNotify(clubCreated);
  },[notifications]);

  return (
    <userloggedIn.Provider value={{userlogged,setuserLogged}}>
    <userData.Provider value={{userdata,setuserdata,allUsersData,setallUsersData,url,homeNav,sethomeNav,logout,getLoggedin,getAllDataforAdmin,
      allClubsData,setallClubsData,notifications,ClubRequests,ClubJoinRequests,clubsUserOwned,clubsUserJoined,UserSignedUpRequests}}>
      <div className="App">
      <Favicon url="/favicon2.png" />
        {/* <Navbar /> */}
          { userlogged? (<>
            <Routes>
              <Route path='/' element={<Library/>} />
              <Route path='/new-drill' element={<Home/>} />
              <Route path='/library' element={<Library/>} />
              <Route path='/clubs' element={<ClubMain />} />
              <Route path='/club/:id' element={<ClubDetail />} />
              <Route path='/library/drill-edit/:id' element={<EditHomeDrill/>} />
              <Route path='/library/edit-session/:id' element={<EditSession/>} />
              <Route path='/new-session' element={<NewSession/>} />
              <Route path='/profile' element={<Profile/>} />
              <Route path='/print-session/:id' element={<PrintSession/>} />
              <Route path='/print-drill/:id' element={<PrintDrill/>} />
              <Route path='/print-club-session' element={<PrintClubSession/>} />
              <Route path='/print-club-drill' element={<PrintClubDrill/>} />
              <Route path='*' element={<Library/>} />
            </Routes>
          </>):
          adminLogin? (<>
            <div className='admin_cover' >
              <div className='eclipse_bot_left'></div>
              <div className='eclipse_top_right'></div>
              <div className='a_navbar'>
                  <div className='a_nav_left'>
                      <img src='/img/soccer-specific-logo-square.png' />
                  </div>
                  <div className='a_nav_right'>
                      <svg width="22" height="26" viewBox="0 0 27 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_199785_134)">
                          <path d="M13.5 15.5C15.546 15.5 17.5081 14.6835 18.9548 13.2301C20.4015 11.7767 21.2143 9.80543 21.2143 7.75C21.2143 5.69457 20.4015 3.72333 18.9548 2.26992C17.5081 0.816516 15.546 0 13.5 0C11.454 0 9.49189 0.816516 8.04518 2.26992C6.59847 3.72333 5.78571 5.69457 5.78571 7.75C5.78571 9.80543 6.59847 11.7767 8.04518 13.2301C9.49189 14.6835 11.454 15.5 13.5 15.5ZM10.7458 18.4062C4.80937 18.4062 0 23.2379 0 29.2018C0 30.1947 0.801563 31 1.78996 31H25.21C26.1984 31 27 30.1947 27 29.2018C27 23.2379 22.1906 18.4062 16.2542 18.4062H10.7458Z" fill="#49D0DC"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_199785_134">
                          <rect width="27" height="31" fill="white"/>
                          </clipPath>
                          </defs>
                      </svg>
                      <div className='mini-bold-title' >
                        Skinnertony
                      </div>
                      <div className='reload pointer zi_5' onClick={logout} >
                        <svg xmlns="http://www.w3.org/2000/svg" fill='#49D0DC' height="18" width="18" viewBox="0 0 512 512"><path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"/></svg>
                      </div>
                  </div>
              </div>
              <div className='admin_body_main' >
                  <div className='a_body_left' >
                      <div className='g_mini_text m_10' >Admin</div>
                      <div className='sidebar_comp' >
                          <NavLink to='/' className={`sidebar_selection ${activeSidebar===1?'sidebar_bg':''}`} onClick={()=>{handleChange(1)}} >
                              <div className='sidebar_svg_con' >
                                  <svg width="22" height="18" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g clip-path="url(#clip0_199794_1245)">
                                      <path d="M27.9903 12.4756C27.9903 13.3545 27.2611 14.043 26.4347 14.043H24.8792L24.9132 21.8652C24.9132 21.9971 24.9035 22.1289 24.8889 22.2607V23.0469C24.8889 24.126 24.0188 25 22.9444 25H22.1667C22.1132 25 22.0597 25 22.0063 24.9951C21.9382 25 21.8701 25 21.8021 25H20.2222H19.0556C17.9813 25 17.1111 24.126 17.1111 23.0469V21.875V18.75C17.1111 17.8857 16.416 17.1875 15.5556 17.1875H12.4444C11.584 17.1875 10.8889 17.8857 10.8889 18.75V21.875V23.0469C10.8889 24.126 10.0188 25 8.94445 25H7.77778H6.22708C6.15417 25 6.08125 24.9951 6.00833 24.9902C5.95 24.9951 5.89167 25 5.83333 25H5.05556C3.98125 25 3.11111 24.126 3.11111 23.0469V17.5781C3.11111 17.5342 3.11111 17.4854 3.11597 17.4414V14.043H1.55556C0.680556 14.043 0 13.3594 0 12.4756C0 12.0361 0.145833 11.6455 0.486111 11.3037L12.95 0.390625C13.2903 0.0488281 13.6792 0 14.0194 0C14.3597 0 14.7486 0.0976562 15.0403 0.341797L27.4556 11.3037C27.8444 11.6455 28.0389 12.0361 27.9903 12.4756Z" fill="white"/>
                                      </g>
                                      <defs>
                                      <clipPath id="clip0_199794_1245">
                                      <rect width="28" height="25" fill="white"/>
                                      </clipPath>
                                      </defs>
                                  </svg>
                              </div>
                              <div>Dashboard</div>
                          </NavLink>
                          <NavLink to='/all-users' className={`sidebar_selection ${activeSidebar===2?'sidebar_bg':''}`} onClick={()=>{handleChange(2)}} >
                              <div className='sidebar_svg_con' >
                                  <svg width="24" height="18" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g clip-path="url(#clip0_199794_1240)">
                                      <path d="M6.75 0C7.74456 0 8.69839 0.395088 9.40165 1.09835C10.1049 1.80161 10.5 2.75544 10.5 3.75C10.5 4.74456 10.1049 5.69839 9.40165 6.40165C8.69839 7.10491 7.74456 7.5 6.75 7.5C5.75544 7.5 4.80161 7.10491 4.09835 6.40165C3.39509 5.69839 3 4.74456 3 3.75C3 2.75544 3.39509 1.80161 4.09835 1.09835C4.80161 0.395088 5.75544 0 6.75 0ZM24 0C24.9946 0 25.9484 0.395088 26.6516 1.09835C27.3549 1.80161 27.75 2.75544 27.75 3.75C27.75 4.74456 27.3549 5.69839 26.6516 6.40165C25.9484 7.10491 24.9946 7.5 24 7.5C23.0054 7.5 22.0516 7.10491 21.3484 6.40165C20.6451 5.69839 20.25 4.74456 20.25 3.75C20.25 2.75544 20.6451 1.80161 21.3484 1.09835C22.0516 0.395088 23.0054 0 24 0ZM0 14.0016C0 11.2406 2.24062 9 5.00156 9H7.00312C7.74844 9 8.45625 9.16406 9.09375 9.45469C9.03281 9.79219 9.00469 10.1438 9.00469 10.5C9.00469 12.2906 9.79219 13.8984 11.0344 15C11.025 15 11.0156 15 11.0016 15H0.998437C0.45 15 0 14.55 0 14.0016ZM18.9984 15C18.9891 15 18.9797 15 18.9656 15C20.2125 13.8984 20.9953 12.2906 20.9953 10.5C20.9953 10.1438 20.9625 9.79688 20.9062 9.45469C21.5438 9.15938 22.2516 9 22.9969 9H24.9984C27.7594 9 30 11.2406 30 14.0016C30 14.5547 29.55 15 29.0016 15H18.9984ZM10.5 10.5C10.5 9.30653 10.9741 8.16193 11.818 7.31802C12.6619 6.47411 13.8065 6 15 6C16.1935 6 17.3381 6.47411 18.182 7.31802C19.0259 8.16193 19.5 9.30653 19.5 10.5C19.5 11.6935 19.0259 12.8381 18.182 13.682C17.3381 14.5259 16.1935 15 15 15C13.8065 15 12.6619 14.5259 11.818 13.682C10.9741 12.8381 10.5 11.6935 10.5 10.5ZM6 22.7484C6 19.2984 8.79844 16.5 12.2484 16.5H17.7516C21.2016 16.5 24 19.2984 24 22.7484C24 23.4375 23.4422 24 22.7484 24H7.25156C6.5625 24 6 23.4422 6 22.7484Z" fill="white"/>
                                      </g>
                                      <defs>
                                      <clipPath id="clip0_199794_1240">
                                      <rect width="30" height="24" fill="white"/>
                                      </clipPath>
                                      </defs>
                                  </svg>
                              </div>
                              <div>All Users</div>
                          </NavLink>
                          <NavLink to='/add-user' className={`sidebar_selection ${activeSidebar===3?'sidebar_bg':''}`} onClick={()=>{handleChange(3)}} >
                              <div className='sidebar_svg_con' >
                                  <svg width="24" height="18" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g clip-path="url(#clip0_199794_1235)">
                                      <path d="M4.5 6C4.5 4.4087 5.13214 2.88258 6.25736 1.75736C7.38258 0.632141 8.9087 0 10.5 0C12.0913 0 13.6174 0.632141 14.7426 1.75736C15.8679 2.88258 16.5 4.4087 16.5 6C16.5 7.5913 15.8679 9.11742 14.7426 10.2426C13.6174 11.3679 12.0913 12 10.5 12C8.9087 12 7.38258 11.3679 6.25736 10.2426C5.13214 9.11742 4.5 7.5913 4.5 6ZM0 22.6078C0 17.9906 3.74063 14.25 8.35781 14.25H12.6422C17.2594 14.25 21 17.9906 21 22.6078C21 23.3766 20.3766 24 19.6078 24H1.39219C0.623438 24 0 23.3766 0 22.6078ZM23.625 14.625V11.625H20.625C20.0016 11.625 19.5 11.1234 19.5 10.5C19.5 9.87656 20.0016 9.375 20.625 9.375H23.625V6.375C23.625 5.75156 24.1266 5.25 24.75 5.25C25.3734 5.25 25.875 5.75156 25.875 6.375V9.375H28.875C29.4984 9.375 30 9.87656 30 10.5C30 11.1234 29.4984 11.625 28.875 11.625H25.875V14.625C25.875 15.2484 25.3734 15.75 24.75 15.75C24.1266 15.75 23.625 15.2484 23.625 14.625Z" fill="white"/>
                                      </g>
                                      <defs>
                                      <clipPath id="clip0_199794_1235">
                                      <rect width="30" height="24" fill="white"/>
                                      </clipPath>
                                      </defs>
                                  </svg>
                              </div>
                              <div>Add User</div>
                          </NavLink>
                          <NavLink to='/password' className={`sidebar_selection ${activeSidebar===4?'sidebar_bg':''}`} onClick={()=>{handleChange(4)}} >
                              <div className='sidebar_svg_con' >
                                  <svg width="20" height="18" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g clip-path="url(#clip0_199794_1228)">
                                      <path d="M15.6071 7.3125V9.75H7.39286V7.3125C7.39286 5.06797 9.2308 3.25 11.5 3.25C13.7692 3.25 15.6071 5.06797 15.6071 7.3125ZM18.8929 9.75V7.3125C18.8929 3.27539 15.5815 0 11.5 0C7.41853 0 4.10714 3.27539 4.10714 7.3125V9.75H3.28571C1.47344 9.75 0 11.2074 0 13V22.75C0 24.5426 1.47344 26 3.28571 26H19.7143C21.5266 26 23 24.5426 23 22.75V13C23 11.2074 21.5266 9.75 19.7143 9.75H18.8929Z" fill="white"/>
                                      </g>
                                      <defs>
                                      <clipPath id="clip0_199794_1228">
                                      <rect width="23" height="26" fill="white" transform="matrix(-1 0 0 1 23 0)"/>
                                      </clipPath>
                                      </defs>
                                  </svg>
                              </div>
                              <div>Password</div>
                          </NavLink>
                          <NavLink to='/history' className={`sidebar_selection ${activeSidebar===5?'sidebar_bg':''}`} onClick={()=>{handleChange(5)}} >
                              <div className='sidebar_svg_con' >
                                  <svg width="22" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g clip-path="url(#clip0_199801_1439)">
                                      <path d="M3.51562 3.51562L1.92188 1.92188C1.21406 1.21406 0 1.71563 0 2.71406V7.875C0 8.49844 0.501562 9 1.125 9H6.28594C7.28906 9 7.79063 7.78594 7.08281 7.07812L5.63906 5.63437C7.26562 4.00781 9.51562 3 12 3C16.9688 3 21 7.03125 21 12C21 16.9688 16.9688 21 12 21C10.0875 21 8.31563 20.4047 6.85781 19.3875C6.17813 18.9141 5.24531 19.0781 4.76719 19.7578C4.28906 20.4375 4.45781 21.3703 5.1375 21.8484C7.0875 23.2031 9.45469 24 12 24C18.6281 24 24 18.6281 24 12C24 5.37188 18.6281 0 12 0C8.68594 0 5.68594 1.34531 3.51562 3.51562ZM12 6C11.3766 6 10.875 6.50156 10.875 7.125V12C10.875 12.3 10.9922 12.5859 11.2031 12.7969L14.5781 16.1719C15.0188 16.6125 15.7312 16.6125 16.1672 16.1719C16.6031 15.7312 16.6078 15.0188 16.1672 14.5828L13.1203 11.5359V7.125C13.1203 6.50156 12.6187 6 11.9953 6H12Z" fill="white"/>
                                      </g>
                                      <defs>
                                      <clipPath id="clip0_199801_1439">
                                      <rect width="24" height="24" fill="white"/>
                                      </clipPath>
                                      </defs>
                                  </svg>
                              </div>
                              <div>History</div>
                          </NavLink>
                          <NavLink to='/clubs' className={`sidebar_selection ${activeSidebar===6?'sidebar_bg':''}`} onClick={()=>{handleChange(6)}} >
                              <div className='sidebar_svg_con' >
                                  <svg width="22" height="21" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M5.11875 8.63516C3.18828 8.18125 1.75 6.44766 1.75 4.375C1.75 1.95781 3.70781 0 6.125 0C8.49844 0 10.4289 1.88672 10.5 4.23828L20.5516 8.26328C21.3445 7.48125 22.4273 7 23.625 7C26.0422 7 28 8.95781 28 11.375C28 13.7922 26.0422 15.75 23.625 15.75C22.9578 15.75 22.3234 15.6023 21.7602 15.3344L13.7977 22.3016C13.9289 22.7172 14 23.1656 14 23.625C14 26.0422 12.0422 28 9.625 28C7.20781 28 5.25 26.0422 5.25 23.625C5.25 22.1102 6.01562 20.7758 7.18594 19.9937L5.11875 8.63516ZM19.4523 12.6984C19.332 12.3211 19.2609 11.9219 19.25 11.5117L9.19844 7.49219C9.00156 7.68359 8.79375 7.85859 8.56406 8.01172L10.6313 19.3648C10.932 19.4359 11.2219 19.5344 11.4953 19.6656L19.4523 12.6984Z" fill="white"/>
                                  </svg>
                              </div>
                              <div>Clubs</div>
                          </NavLink>
                      </div>
                  </div>
                  <div className='a_body_right'>
                  <Routes>
                    <Route path='/' element={<Dashboard getAllDataforAdmin={getAllDataforAdmin} numberOfUsers={numberOfUsers} 
                      userSignedNotify={userSignedNotify} numberOfClubs={numberOfClubs} />} />
                    <Route path='/all-users' element={<AllUsers/>} />
                    <Route path='/add-user' element={<AddUser/>} />
                    <Route path='/password' element={<Password/>} />
                    <Route path='/history' element={<History notifications={notifications} historyNotifications={historyNotifications} />} />
                    <Route path='/clubs' element={<Clubs/>} />
                    <Route path='/admin/club/:id' element={<ClubDetailAdmin />} />
                    <Route path='*' element={<Dashboard/>} />
                  </Routes>
                  </div>
              </div>
            </div>
          </>):(<>
            <Routes>
              <Route path='/signup' element={<SignUp/>} />
              <Route path='/login' element={<LoginForm/>} />
              <Route path='*' element={<LoginForm/>} />
            </Routes>
          </>)
          }
      </div>
    </userData.Provider>
    </userloggedIn.Provider>
  );
}

export default App;
