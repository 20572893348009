import React, { useEffect, useState } from 'react'
import SessionDrillsPopup from '../SessionDrillsPopup/SessionDrillsPopup';

function SessionComp({session,index,user}) {
  const [openSessionDrillsPopup,setopenSessionDrillsPopup] = useState(false);

  const [numberOfDrills,setnumberOfDrills] = useState(0);
  
  useEffect(()=>{
    if(session.drills && session.drills.length>0){
      setnumberOfDrills(session.drills.length);
    }
  },[])
  
  return (
    <>
        <div className='session_comp' onClick={()=>{setopenSessionDrillsPopup(true)}} >
            <div className='session_comp_left' >
                <div className='normal_text' > <span className='g_mini_text fs_20' >Name : </span> {session.name} </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Team : </span> {session.team} </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Duration : </span> {session.duration} </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Intensity : </span> {session.intensity} </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Date : </span> {new Date(session.date).toLocaleString('en-US', { day: 'numeric', month: 'short',year: 'numeric' })} </div>
            </div>
            <div className='session_comp_right' >
                <div className='normal_text' > <span className='g_mini_text fs_20' >Drills added : </span> {numberOfDrills} </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Note : </span></div>
                <div className='normal_text scroll_admin_section height_3r' > {session.notes} </div>
            </div>
        </div>
        { openSessionDrillsPopup ? (
          <>
            <SessionDrillsPopup drills={session.drills} setopenSessionDrillsPopup={setopenSessionDrillsPopup} user={user} />
          </>
        ):<></>
        }
    </>
  )
}
export default SessionComp