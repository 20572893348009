import React, { useState,useContext } from 'react'
import './NewSession.css'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { userloggedIn } from './Context/Context'
import { userData } from './Context/Context'
import Navbar from './Navbar'

// import {  } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function NewSession() {
    const location = useLocation();
    const club = new URLSearchParams(location.search).get('club');
    // console.log(club);
    const { userlogged,setuserLogged } = useContext(userloggedIn);
    const { userdata,setuserdata,url,homeNav,sethomeNav,getLoggedin } = useContext(userData);
    const Navigate = useNavigate();
    const [name,setName] = useState('');
    const [notes,setNotes] = useState('');
    const [team,setTeam] = useState('');
    const [duration,setDuration] = useState('');
    const [intensity,setIntensity] = useState('0');
    const [date,setDate] = useState('');

    const [submitting,setSubmitting] = useState(false);

    const [open1,setopen1] = useState(false);
    const [open2,setopen2] = useState(false);

    const submitform = async (event)=>{
        event.preventDefault();
        setSubmitting(true);
        if(club){
            // Club Session
            const res = await fetch(`${url}newSessionofClub`,{
              method: "POST",
              headers: {
                "Content-Type" : "application/json"
              },
              body: JSON.stringify({
                id: userdata._id,
                clubId: club,
                name, notes, team, duration, intensity, date
              }),
            });
            const data = await res.json();
            setSubmitting(false);
            if(data.msg === "success"){
                console.log('success');
                getLoggedin();
                // setuserdata(data.data);
                setName('');
                setNotes('');
                setTeam('');
                setIntensity('0');
                setDuration('');
                setDate('');
                setopen1(true);
                setTimeout(() => {
                    setopen1(false);
                }, 2000);
                Navigate(-1);
            }else{
                setopen2(true);
                setTimeout(() => {
                    setopen2(false);
                }, 2000);
                console.log('failed');
            }
        }else{
            // Normal User
            const res = await fetch(`${url}newSession`,{
              method: "POST",
              headers: {
                "Content-Type" : "application/json"
              },
              body: JSON.stringify({
                id: userdata._id, name, notes, team, duration, intensity, date
              }),
            });
            const data = await res.json();
            setSubmitting(false);
            if(data.msg === "success"){
                console.log('success');
                getLoggedin();
                setuserdata(data.data);
                setName('');
                setNotes('');
                setTeam('');
                setIntensity('0');
                setDuration('');
                setDate('');
                Navigate('/library');
            }else{
                console.log('failed');
            }
        }
      }
  return (
    <>
    <Snackbar open={open1} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
        <Alert severity="success" sx={{ width: '100%' }}>
            Session for club created successfully!
        </Alert>
    </Snackbar>
    <Snackbar open={open2} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
        <Alert severity="error" sx={{ width: '100%' }}>
            Creating session failed!
        </Alert>
    </Snackbar>
    <Navbar />
    <form onSubmit={submitform}>
        <div className='new-session-main'>
            <div className='overlay' ></div>
            <div className='ns-con'>
                <div className='ns-title' > NEW SESSION </div>
                <div className='ns-elem' >
                    <div>Name</div>
                    <input type='text' placeholder='Name' value={name} onChange={(e)=>{setName(e.target.value)}} />
                </div>
                <div className='ns-elem' >
                    <div>Notes</div>
                    <textarea type='text' placeholder='Notes' value={notes} onChange={(e)=>{setNotes(e.target.value)}} />
                </div>
                <div className='ns-elem' >
                    <div>Team</div>
                    <input type='text' placeholder='Team name' value={team} onChange={(e)=>{setTeam(e.target.value)}} />
                </div>
                <div className='ns-elem' >
                    <div>Duration</div>
                    <input type='text' placeholder='Duration' value={duration} onChange={(e)=>{setDuration(e.target.value)}} />
                </div>
                <div className='ns-elem' >
                    <div>Intensity</div>
                    <select value={intensity} onChange={(e)=>{setIntensity(e.target.value)}} >
                        <option>0</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                    </select>
                </div>
                <div className='ns-elem'>
                    <div>Date</div>
                    <input type='date' placeholder='Date' value={date} onChange={(e)=>{setDate(e.target.value)}} />
                </div>
                <div className='sn-btn' >
                    <button className='sn-btn1' disabled={submitting} > { submitting?'Saving':'Save' } </button>
                    <NavLink to='/library' ><button className='sn-btn2' > Cancel </button></NavLink>
                </div>
            </div>
        </div>
        </form>
    </>
  )
}

export default NewSession
