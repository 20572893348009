import React from 'react'

function ConfirmPopup({open,setOpen,message,handleconfirm,loading}) {
    const handleclose = () =>{
        setOpen(false);
    }
    return (
    <>
        <div className='popup_full_page' >
            <div className='update_user_inner'>
                <div className='c_dialog_con' >
                    <div className='dialog_title mb_10' >{message}</div>
                    <div className='confirm_dialog_buttons' >
                        <div>
                            <button className='search_btn mr_10' onClick={()=>{setOpen(false)}} >Cancel</button>
                            <button className='search_btn_red' disabled={loading} onClick={handleconfirm} >Confirm</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='x_mark' onClick={handleclose} >
            <svg width="22" height="32" viewBox="0 0 30 40" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.7656 11.7656C27.7422 10.7891 27.7422 9.20312 26.7656 8.22656C25.7891 7.25 24.2031 7.25 23.2266 8.22656L15 16.4609L6.76562 8.23437C5.78906 7.25781 4.20312 7.25781 3.22656 8.23437C2.25 9.21094 2.25 10.7969 3.22656 11.7734L11.4609 20L3.23438 28.2344C2.25781 29.2109 2.25781 30.7969 3.23438 31.7734C4.21094 32.75 5.79687 32.75 6.77344 31.7734L15 23.5391L23.2344 31.7656C24.2109 32.7422 25.7969 32.7422 26.7734 31.7656C27.75 30.7891 27.75 29.2031 26.7734 28.2266L18.5391 20L26.7656 11.7656Z" fill="white"/>
            </svg>
        </div>
    </>
  )
}
export default ConfirmPopup