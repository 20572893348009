import React, { useContext, useState, useEffect } from 'react';
import { useParams,useNavigate,useLocation } from 'react-router-dom';
import Navbar from '../../../Navbar';
import { userloggedIn } from '../../../Context/Context'
import { userData } from '../../../Context/Context'
import '../../../Library/PrintSession.css'
import PrintClubSessionComp from './PrintClubSessionComp';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function PrintSession() { 
    const { userlogged,setuserLogged } = useContext(userloggedIn);
    const { userdata,setuserdata,url,homeNav,sethomeNav,getLoggedin } = useContext(userData);
    // sethomeNav(false);
    const location = useLocation();
    const clubId = new URLSearchParams(location.search).get('club');
    const sessionId = new URLSearchParams(location.search).get('session');

    const Navigate = useNavigate();
    const [club,setclub] = useState({});
    const [session,setsession] = useState({});
    const [drills,setdrills] = useState([]);
    const [printReady,setprintReady] = useState(false);
    const [isMember,setisMember] = useState(false);
    const [isOwner,setisOwner] = useState(false);
    const [openError,setopenError] = useState(false);
    
    const handlePrint = () => {        
        if(!isMember && !isOwner){
            setopenError(true);
            setTimeout(() => {
                setopenError(false);
            }, 3000);
            return;
        }
        setprintReady(true);
        setTimeout(() => {
            window.print();
        }, 500);
        setTimeout(() => {
            setprintReady(false);
        }, 2000);
    };
    const cancelPrint = ()=>{
        Navigate(-1);
    }
    const getSessionInfo = async ()=>{
        // setLoading(true);
        const res = await fetch(`${url}getClubDataById`,{
          method: "POST",
          headers: {
            "Content-Type" : "application/json"
          },
          body: JSON.stringify({
            clubId,
            userId: userdata._id,
          }),
        });
        const data = await res.json();
        // console.log(data);
        // setLoading(false);
        if(data.msg === "success"){
            // console.log(data.club,data.isMember,data.isOwner);
            setisMember(data.isMember);
            setisOwner(data.isOwner);
            setclub(data.club);
            const matchedSession = data.club.session.filter( (session) =>{ return session._id === sessionId });
            setsession(matchedSession[0]);
            setdrills(matchedSession[0].drills);
        }else{

        }
    }
    useEffect(()=>{
        getSessionInfo();
    },[]);
    
    useEffect(() => {
        const handleAfterPrint = () => {
        //   console.log('Print dialog closed');
          setprintReady(false);
    
          // Check if the user canceled the print
          const isPrintCancelled = !document.hidden;
    
          if (isPrintCancelled) {
            // console.log('Print was canceled');
          } else {
            console.log('Print was successful');
          }
    
          // Remove the event listener
          window.removeEventListener('afterprint', handleAfterPrint);
        };
    
        // Add an event listener for the afterprint event
        window.addEventListener('afterprint', handleAfterPrint);
    
        return () => {
          // Remove the event listener when the component unmounts
          window.removeEventListener('afterprint', handleAfterPrint);
        };
      }, []);
      
  return (
    <>
        { !printReady && <Navbar />}
        <Snackbar open={openError} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="error" sx={{ width: '100%' }}>
                You are no longer associated with this Club!
            </Alert>
        </Snackbar>
        <div className='library-main' style={{display:'flex',flexDirection:'column',alignItems: 'center',gap:'1rem',paddingBottom:'2rem'}} >
            <div className='overlay' ></div>
            <div className='print-mini-head' style={{display: printReady?'none':'block' }}>
                <button className='navbar-save-btn' onClick={handlePrint} >PRINT </button>
                <button className='navbar-save-btn' onClick={cancelPrint} >CANCEL </button>
            </div>
            <div className='print-mini-head alert-warning' style={{display: printReady?'none':'block' }} >
                Use<span style={{fontWeight:'500'}} >&nbsp;A4&nbsp;</span>papar size for best print output.
            </div>
            <div className='print-content'>
                <div className='print-s-header'>
                    <div className='ps-title' > { session?.name } </div>
                    <div className='ps-txt-con' >
                        <div>Date <span style={{color:'black',fontWeight:'500'}} > {session?.date} </span> </div>
                        <div>Duration <span style={{color:'black',fontWeight:'500'}} > { session?.duration } </span> </div>
                        <div>Team <span style={{color:'black',fontWeight:'500'}} > { session?.team } </span> </div>
                    </div>
                </div>
                <hr style={{width:'90%',margin:'1rem auto',marginBottom: '0.2rem'}} />
                {  drills.length>0 && drills.map((drill,index)=>{
                    return(
                        <>
                            <PrintClubSessionComp drill={drill} club={club} index={index} />
                        </>
                    )
                })
                }
                <div className='print-footer'>
                    <hr style={{width:'10rem'}} />
                    <div>Generated by <span style={{color:'black',fontWeight:'500'}} > www.tacticalcentre.com.au </span></div>
                    <img src='/img/soccer-specific-logo-square.png' style={{height:'4rem'}} />
                    <hr style={{width:'10rem'}} />
                </div>
            </div>
        </div>
    </>
  );
}

export default PrintSession;