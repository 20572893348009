import React, { useState,useContext } from 'react'
import { userData } from '../../Context/Context'

function AddClubPopup({setopenAddClubPopup,getAllDataforAdmin}) {
    const { userdata,setuserdata,url,ClubRequests} = useContext(userData);
    const [search,setsearch] = useState('');
    const handleSearch = (e) => {
        e.preventDefault();
        // if (search === '') {
        //     setfilteredDrills(matchedDrills);
        // } else {
        //   const filtered = matchedDrills.filter((drillData) =>
        //     drillData.name.toLowerCase().includes(search.toLowerCase())
        //   );
        //   setfilteredDrills(filtered);
        // }
    };
    // const handleChange = (e) =>{
    //     // if(e.target.value === '' ){
    //     //     setfilteredDrills(matchedDrills);
    //     // }
    //     // setsearch(e.target.value);
    // }
    const [formData, setFormData] = useState({
        title: '',
        members: [],
        owner: '',
        link: '',
        description: '',
    });
    const [newMember, setNewMember] = useState('');
    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };
    const handleAddMember = (e) => {
      e.preventDefault();
      if (newMember.trim() !== '') {
        setFormData({
          ...formData,
          members: [...formData.members, newMember.trim()],
        });
        setNewMember('');
      }
    };
    const handleRemoveMember = (index) => {
      const updatedMembers = [...formData.members];
      updatedMembers.splice(index, 1);
      setFormData({
        ...formData,
        members: updatedMembers,
      });
    };
    const [activeIndex,setactiveIndex] = useState(-1);
    const [activeRequestId,setactiveRequestId] = useState('');
    const handleChangeInputs = (val,index)=>{
        const reversedMembers = val.members.map(obj => obj.email);
        setFormData({
            title: val.title,
            members: reversedMembers,
            owner: val.owner[0]._id,
            description: val.description,
        })
        setactiveIndex(index);
        setactiveRequestId(val._id);
    }
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const res = await fetch(`${url}createClub`,{
            method: "POST",
            headers: {
              "Content-Type" : "application/json",
              "authToken": localStorage.getItem("authToken"),
            },
            body: JSON.stringify({
              title: formData.title,
              owner: formData.owner,
              description: formData.description,
              members: formData.members,
              id: activeRequestId,
              activeIndex,
            }),
        });
        const data = await res.json();

        if(data.msg === "success"){
            getAllDataforAdmin();
            setopenAddClubPopup(false);
        }else{
            console.log('failed');
        }
    };
    const handleDeleteClubRequest = async (val) => {
        const res = await fetch(`${url}DeleteClubRequest`,{
            method: "POST",
            headers: {
              "Content-Type" : "application/json",
              "authToken": localStorage.getItem("authToken"),
            },
            body: JSON.stringify({
              id: val._id,
              userEmail: val.owner,
              title: val.title,
              description: val.description,
              members: val.members,
            }),
        });
        const data = await res.json();

        if(data.msg === "success"){
            getAllDataforAdmin();
            
        }else{
            console.log('failed');
        }
    };

    return (
    <>
        <div className='popup_full_page' >
            <div className='grid5522' >
                <div className='grid_5522_left' >
                    <div className='g_mini_text width_rt fw_700 ' >Requests</div>
                    <div className='club_requests_con' >
                        { ClubRequests.length>0 ? ClubRequests.map((val,index)=>{
                            return(
                                <>
                                    <div className='club_requests_elem' style={{border: activeIndex === index ? '1px solid #59fff9de' : ''}} >
                                        <div onClick={()=>{handleChangeInputs(val,index)}} >
                                            <div className='normal_text' > <span className='g_mini_text fs_20' >Title : </span> {val.title} </div>
                                            <div className='normal_text' > <span className='g_mini_text fs_20' >By : </span> {val.owner[0].email} </div>
                                            <div className='normal_text' > <span className='g_mini_text fs_20' >Members : </span> {val.members ? val.members.length : 0 } </div>
                                            <div>
                                                <div className='flex_end' ><span className='g_mini_text fs_20' >
                                                {new Date(val.created_at).toLocaleString('en-US', { 
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                    day: 'numeric', 
                                                    month: 'short',
                                                    year: 'numeric',
                                                })}
                                                </span></div>
                                            </div>
                                        </div>
                                        <svg onClick={()=>{handleDeleteClubRequest(val)}} className='cross-filled' xmlns="http://www.w3.org/2000/svg" height="22" width="22" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"/></svg>
                                    </div>
                                </>
                            )
                        }):
                        <>
                            <div className='empty_page2' >
                                No request submitted yet
                            </div>
                        </>
                        }
                    </div>
                </div>
                <div className='add_club_con' >
                    <div className='add_club_con_left' >
                        <div className='adduser_body_inner m0'>
                            <form onSubmit={handleFormSubmit}>
                                <div className='g_mini_text width_rt fw_700 ' >Add a Club</div>
                                <div className='title_input_con' >
                                    <div className='register-mini-bold-title' >Title</div>
                                    <input 
                                        className='register_input'
                                        name='title'
                                        placeholder='Enter Title'
                                        type='text'
                                        onChange={handleChange}
                                        value={formData.title}
                                        required
                                    />
                                </div>
                                <div className='title_input_con' >
                                    <div className='register-mini-bold-title' >Owner</div>
                                    <input 
                                        className='register_input'
                                        name='owner'
                                        placeholder='Enter Owner Id'
                                        type={activeIndex >= 0 ? 'email':'text'}
                                        onChange={handleChange}
                                        value={formData.owner}
                                        required
                                        disabled={activeIndex >= 0}
                                    />
                                </div>
                                <div className='title_input_con' >
                                    <div className='register-mini-bold-title' >Description</div>
                                    <input 
                                        className='register_input'
                                        name='description'
                                        placeholder='Enter description'
                                        type='text'
                                        onChange={handleChange}
                                        value={formData.description}
                                        required
                                    />
                                </div>
                                <button type='submit' className='register_btn' >SUBMIT</button>
                            </form>
                        </div>
                    </div>
                    <div className='add_club_con_right' >
                    <div className='adduser_body_inner m0'>
                        <div>
                                <div className='g_mini_text width_rt fw_700 ' >Add Members</div>
                                <div className='title_input_con' >
                                <form onSubmit={handleAddMember} >
                                    <div className='flex_between2 mb_10' >
                                        <div className='register-mini-bold-title' >Member</div>
                                        <button type='submit' className='reload hw_25 b_none pointer' onClick={handleAddMember} >
                                            <svg fill='#49D0DC' xmlns="http://www.w3.org/2000/svg" height="14" width="12" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
                                        </button>
                                    </div>
                                    <input 
                                        className='register_input'
                                        name='member'
                                        placeholder='Enter email'
                                        type='email'
                                        value={newMember}
                                        onChange={(e) => setNewMember(e.target.value)}
                                        required
                                    />
                                </form>
                                <div className='added_cuc' >
                                    { formData.members.length > 0 ? formData.members.map((member, index) => (
                                        <>
                                            <div className='user_member_line' >
                                                <div> {index+1}) {member} </div>
                                                <button className='reload hw_25 b_none pointer' onClick={() => handleRemoveMember(index)} >
                                                    <svg fill='#49D0DC' style={{transform: 'rotate(45deg)'}} xmlns="http://www.w3.org/2000/svg" height="14" width="12" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
                                                </button>
                                            </div>
                                        </>
                                    ))
                                    :
                                        <div className='added_cuc_no_data' >
                                            <div className='empty_page2' >
                                                No member added yet
                                            </div>
                                        </div>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='x_mark' onClick={()=>{setopenAddClubPopup(false)}} >
            <svg width="22" height="32" viewBox="0 0 30 40" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.7656 11.7656C27.7422 10.7891 27.7422 9.20312 26.7656 8.22656C25.7891 7.25 24.2031 7.25 23.2266 8.22656L15 16.4609L6.76562 8.23437C5.78906 7.25781 4.20312 7.25781 3.22656 8.23437C2.25 9.21094 2.25 10.7969 3.22656 11.7734L11.4609 20L3.23438 28.2344C2.25781 29.2109 2.25781 30.7969 3.23438 31.7734C4.21094 32.75 5.79687 32.75 6.77344 31.7734L15 23.5391L23.2344 31.7656C24.2109 32.7422 25.7969 32.7422 26.7734 31.7656C27.75 30.7891 27.75 29.2031 26.7734 28.2266L18.5391 20L26.7656 11.7656Z" fill="white"/>
            </svg>
        </div>
    </>
  )
}

export default AddClubPopup
