import React,{useContext,useState} from 'react'
import { NavLink , useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { userData } from '../Context/Context';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AddUser() {
    const {userdata,setuserdata,url} = useContext(userData);
    const Navigate = useNavigate();
    const [loading,setLoading] = useState(false);
    const SignUpSchema = Yup.object({
        name: Yup.string().min(2).max(20).required("Please enter your name"),
        email: Yup.string().required("Please enter your email"),
        password: Yup.string().min(8).max(50).required("Please enter your Password").matches(/[A-Z]/, 'Must contain one uppercase').matches(/(\d)/, 'Must contain one number'),
        confirmpassword: Yup.string().oneOf([Yup.ref("password"),null],"Passwords not matching").required("Please enter your Password"),
        birthday: Yup.date().required("Please select Date of Birth").test("age", "You must be 18 years or older to sign up", (date) => {
          const now = new Date();
          const age = now.getFullYear() - date.getFullYear();
          if (age < 18) {
            return false;
          }
          if (age === 18) {
            if (now.getMonth() < date.getMonth()) {
              return false;
            }
            if (now.getMonth() === date.getMonth() && now.getDate() < date.getDate()) {
              return false;
            }
          }
          return true;
        }),
    });
    const initialValue = {
        name: "",
        email: "",
        password: "",
        confirmpassword: "",
        birthday: "",
    }
    const [alreadyRegistered,setAlreadyRegistered] = useState(false);
    const [registeredSuccessfully,setRegisteredSuccessfully] = useState(false);

    const {values, errors, touched, handleChange, handleBlur, handleSubmit} = useFormik({
        initialValues: initialValue,
        validationSchema: SignUpSchema,
        onSubmit: (values)=>{
          setLoading(true);
            const submitform = async () => {
                const res = await fetch(`${url}signup`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(values),
                });
                const data = await res.json();
                console.log(data);
                setLoading(false);
                if(data.msg === 'User Already Registered'){
                    setAlreadyRegistered(true);
                    setRegisteredSuccessfully(false);
                    
                    setTimeout(() => {
                        setAlreadyRegistered(false);
                        setRegisteredSuccessfully(false);
                    }, 5000);
                }
                if(data.msg==='User Registered Successfuly.'){
                    setAlreadyRegistered(false);
                    setRegisteredSuccessfully(true);
                    setTimeout(() => {
                      setAlreadyRegistered(false);
                      setRegisteredSuccessfully(false);
                  }, 5000);
                }
              };
              submitform();
        },
    });
  return (
    <>
        <Snackbar open={registeredSuccessfully} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="success" sx={{ width: '100%' }}>
                User created Successfully!
            </Alert>
        </Snackbar>
        <Snackbar open={alreadyRegistered} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="error" sx={{ width: '100%' }}>
                User already Registered!
            </Alert>
        </Snackbar>
        <div className='page-title'>Add Users</div>
        <div className='adduser_body_inner'>
            <form onSubmit={handleSubmit} className='register_con' >
                <div className='g_mini_text width_rt fw_700 ' >REGISTER</div>
                <div className='title_input_con' >
                    <div className='register-mini-bold-title' >Username</div>
                    <input 
                        className='register_input'
                        placeholder='Enter username'
                        type='text'
                        autoCorrect="false"
                        id="name"
                        name="name"
                        autoComplete="off"
                        spellCheck='false'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                    />
                    <div className="error_para">{ errors.name && touched.name ? errors.name : null}</div>
                </div>
                <div className='title_input_con' >
                    <div className='register-mini-bold-title' >Email</div>
                    <input 
                        className='register_input'
                        placeholder='Enter email'
                        type='email'
                        id="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                        value={values.email}
                        spellCheck='false'
                    />
                    <div className="error_para">{errors.email && touched.email ?errors.email:null}</div>
                </div>
                <div className='title_input_con' >
                    <div className='register-mini-bold-title' >Password</div>
                    <input 
                        className='register_input'
                        placeholder='Enter password'
                        type='password'
                        id="password"
                        name="password"
                        autoComplete="off"
                        spellCheck='false'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                    />
                </div>
                <div className='title_input_con' >
                    <div className='register-mini-bold-title' >Confirm Password</div>
                    <input 
                        className='register_input'
                        type='password'
                        placeholder="Confirm Password"
                        id="confirmpassword"
                        autoComplete="off"
                        spellCheck='false'
                        name="confirmpassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmpassword}
                    />
                    <div className="error_para">{ errors.confirmpassword && touched.confirmpassword? errors.confirmpassword:null}</div>
                </div>
                <div className='title_input_con' >
                    <div className='register-mini-bold-title' >Birthday</div>
                    <input 
                        className='register_input'
                        placeholder='Select your birthday'
                        type="date"
                        id="Birthday"
                        name="birthday"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                        spellCheck='false'
                        value={values.birthday}
                    />
                    <div className="error_para">{errors.birthday && touched.birthday ? errors.birthday:null}</div>
                </div>
                <button type='submit' disabled={loading} className='register_btn' >SUBMIT</button>
            </form>
        </div>
    </>
  )
}

export default AddUser
