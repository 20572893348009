import React,{useContext,useState} from 'react'
import { NavLink,useNavigate } from 'react-router-dom'
import { userloggedIn } from './Context/Context'
import { userData } from './Context/Context'
import './Navbar.css';

function Navbar() {
    const Navigate = useNavigate();
    const { userlogged,setuserLogged } = useContext(userloggedIn);
    const { userdata,setuserdata,url,homeNav,sethomeNav,logout } = useContext(userData);
  return (
    <>
        { homeNav &&
            <></>
        }
        { !homeNav &&
            <>
            <div className='navbar2'>
                <div className='navbar2-left' >
                    <img src='/img/soccer-specific-logo-square.png' style={{cursor:'pointer'}} onClick={()=>{Navigate('/')}} />
                    <div className='nav-left-title-con' >
                        <div className='nav-left-title1' > Tactical Centre </div>
                        <div className='nav-left-title2' > Interactive Session Planner </div>
                    </div>
                </div>
                { userlogged &&
                    <div className='navbar2-right' >
                        <NavLink to='/library' className='nav-reg-btn' >LIBRARY</NavLink>
                        <NavLink to='/clubs' className='nav-reg-btn' >CLUBS</NavLink>
                        <NavLink to='/new-drill' className='nav-reg-btn' >NEW DRILL</NavLink>
                        <NavLink to='/new-session' className='nav-reg-btn' >NEW SESSION</NavLink>
                        {/* <div className='nav-reg-btn' >
                            <img className='nav-flag' src='/img/flag.png'/>
                        </div> */}
                        <div className='nav-v-line' ></div>
                        <NavLink to='/profile' className='nav-reg-btn' >PROFILE</NavLink>
                        <div className='nav-reg-btn' onClick={()=>{logout()}} >LOGOUT</div>
                    </div>
                }
            </div> 
            </>
        }
    </>
  )
}

export default Navbar