import React,{useState,useContext} from 'react'
import { userData } from '../../Context/Context'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function JoinClubComp({val}) {
    const { userdata,setuserdata,url,ClubJoinRequests,getAllDataforAdmin} = useContext(userData);
    const [openSuccess,setopenSuccess] = useState(false);
    const [openFailed,setopenFailed] = useState(false);

    const [openApproved,setopenApproved] = useState(false);
    const [openApprovedFailed,setopenApprovedFailed] = useState(false);

    const [cancelLoading,setcancelLoading] = useState(false);
    const [approveLoading,setapproveLoading] = useState(false);
    const handleDeleteJoinClubRequest = async () => {
        if(cancelLoading){
            return;
        }
        setcancelLoading(true);
        const res = await fetch(`${url}DeleteClubJoinRequest`,{
            method: "POST",
            headers: {
              "Content-Type" : "application/json",
              "authToken": localStorage.getItem("authToken"),
            },
            body: JSON.stringify({
              id: val._id,
              clubId: val.clubId,
              requestedById: val._id,
              userEmail: val.userEmail,
              title: val.title,
            }),
        });
        const data = await res.json();
        setcancelLoading(false);
        if(data.msg === "success"){
            getAllDataforAdmin();
            setopenSuccess(true);
            setTimeout(() => {
                setopenSuccess(false);
            }, 3000);
        }else{
            setopenFailed(true);
            setTimeout(() => {
                setopenFailed(false);
            }, 3000);
            console.log('failed');
        }
    };
    const handleApproveJoinClubRequest = async () => {
        if(approveLoading){
            return;
        }
        setapproveLoading(true);
        const res = await fetch(`${url}ApproveClubJoinRequest`,{
            method: "POST",
            headers: {
              "Content-Type" : "application/json",
              "authToken": localStorage.getItem("authToken"),
            },
            body: JSON.stringify({
              id: val._id,
              clubId: val.clubId,
              requestedById: val.requestedBy,
              userEmail: val.userEmail,
              title: val.title,
            }),
        });
        const data = await res.json();
        setapproveLoading(false);
        if(data.msg === "success"){
            getAllDataforAdmin();
            setopenApproved(true);
            setTimeout(() => {
                setopenApproved(false);
            }, 3000);
        }else{
            setopenApprovedFailed(true);
            setTimeout(() => {
                setopenApprovedFailed(false);
            }, 3000);
            console.log('failed');
        }
    };
    return (
    <>
        <Snackbar open={openApproved} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="success" sx={{ width: '100%' }}>
                Request Approved Successfully!
            </Alert>
        </Snackbar>
        <Snackbar open={openApprovedFailed} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="error" sx={{ width: '100%' }}>
                Approve Failed! Please try later.
            </Alert>
        </Snackbar>
        <Snackbar open={openSuccess} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="success" sx={{ width: '100%' }}>
                Request Cancelled Successfully!
            </Alert>
        </Snackbar>
        <Snackbar open={openFailed} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="error" sx={{ width: '100%' }}>
                Cancellation Failed! Please try later.
            </Alert>
        </Snackbar>
        <div className='club_requests_elem cursor_default' >
            <div >
                <div className='flex_between2' >
                    <div>
                        <div className='normal_text' > <span className='g_mini_text fs_20' >Requested By : </span> {val.userEmail} </div>
                    </div>
                    <div className='flex_align3' >
                        <div className='normal_text pointer' onClick={handleDeleteJoinClubRequest} > <span className='g_mini_text fs_20' ></span> <span className='rejected_text' > { cancelLoading? 'Cancelling':'Cancel' }  </span> </div>
                        <div className='normal_text pointer' onClick={handleApproveJoinClubRequest} > <span className='g_mini_text fs_20' ></span> <span className='approved_text' > { approveLoading? 'Approving': 'Approve' } </span> </div>
                        {/* <svg onClick={()=>{handleDeleteJoinClubRequest(val)}} className='cross-filled' xmlns="http://www.w3.org/2000/svg" height="22" width="22" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"/></svg> */}
                    </div>
                </div>
                <div className='normal_text mt_10' > <span className='g_mini_text fs_20' >Club Info </span></div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Title : </span> {val.title} </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Owner : </span> {val.owner} </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Description : </span> {val.description} </div>
                {/* <div className='normal_text' > <span className='g_mini_text fs_20' >Members : </span> {val.members ? val.members.length : 0 } </div> */}
                <div>
                    <div className='flex_end' ><span className='g_mini_text fs_20' >
                    {new Date(val.created_at).toLocaleString('en-US', { 
                        hour: 'numeric',
                        minute: 'numeric',
                        day: 'numeric', 
                        month: 'short',
                        year: 'numeric',
                    })}
                    </span></div>
                </div>
            </div>
        </div>
    </>
  )
}

export default JoinClubComp
