import React,{useState,useContext} from 'react'
import { userData } from '../Context/Context';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Password() {
    const {userdata,setuserdata,url} = useContext(userData);
    const [updatesuccess,setupdateSuccess] = useState(false);
    const [openIncorrect,setopenIncorrect] = useState(false);
    const [openIncomplete,setopenIncomplete] = useState(false);
    const [openNotMatching,setopenNotMatching] = useState(false);
    const [openLengthError,setopenLengthError] = useState(false);
    const [loading,setLoading] = useState(false);
    const [formData, setformData] = useState({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
    });

  const handleSubmit =async (e)=> {
    e.preventDefault();
    if(!formData.oldPassword || !formData.newPassword || !formData.confirmNewPassword){
        setopenIncomplete(true);
        setTimeout(() => {
            setopenIncomplete(false);
        }, 3000);
        return;
    }
    if(formData.newPassword!=formData.confirmNewPassword){
        setopenNotMatching(true);
        setTimeout(() => {
            setopenNotMatching(true);
        }, 3000);
        return;
    }
    if(formData.newPassword.length<8){
        setopenLengthError(true);
        setTimeout(() => {
            setopenLengthError(false);
        }, 3000);
        return;
    }
    setLoading(true);
    const res = await fetch(`${url}resetAdminPassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "authToken": localStorage.getItem("authToken")
        },
        body: JSON.stringify(formData),
      });
      setLoading(false);
      const data = await res.json();
      if(data.msg==='incorrect Password'){
        setopenIncorrect(true);
        setTimeout(() => {
            setopenIncorrect(false);
        }, 3000);
      }else if(data.msg==='success'){
        setupdateSuccess(true);
        setformData({
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
        });
        setTimeout(() => {
            setupdateSuccess(false);
        }, 2000);
      }
      else{

        };
  }
  function handleChange(e) {
    const { value, name } = e.target;
    setformData({
      ...formData,
      [name]: value,
    });
  }
  return (
    <>
        <Snackbar open={updatesuccess} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="success" sx={{ width: '100%' }}>
                Password changed Successfully!
            </Alert>
        </Snackbar>
        <Snackbar open={openIncomplete} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="error" sx={{ width: '100%' }}>
                Please fill all fields!
            </Alert>
        </Snackbar>
        <Snackbar open={openIncorrect} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="error" sx={{ width: '100%' }}>
                Incorrect Password!
            </Alert>
        </Snackbar>
        <Snackbar open={openLengthError} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="error" sx={{ width: '100%' }}>
                New Password should contain atleast 8 characters!
            </Alert>
        </Snackbar>
        <Snackbar open={openNotMatching} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="error" sx={{ width: '100%' }}>
                Passwords are not matching!
            </Alert>
        </Snackbar>
        <div className='page-title'>Admin Password</div>
        <div className='adduser_body_inner'>
            <form onSubmit={handleSubmit} className='register_con' >
                <div className='g_mini_text width_rt fw_700 ' >RESET PASSWORD</div>
                <div className='title_input_con' >
                    <div className='register-mini-bold-title' >Password</div>
                    <input 
                        className='register_input'
                        name='oldPassword'
                        placeholder='Enter password'
                        type='password'
                        value={formData.oldPassword}
                        onChange={handleChange}
                    />
                </div>
                <div className='title_input_con' >
                    <div className='register-mini-bold-title' >New Password</div>
                    <input 
                        className='register_input'
                        name='newPassword'
                        placeholder='Enter password'
                        type='password'
                        value={formData.newPassword}
                        onChange={handleChange}
                    />
                </div>
                <div className='title_input_con' >
                    <div className='register-mini-bold-title' >Confirm New Password</div>
                    <input 
                        className='register_input'
                        name='confirmNewPassword'
                        placeholder='Enter password again'
                        type='password'
                        value={formData.confirmNewPassword}
                        onChange={handleChange}
                    />
                </div>
                <button type='submit' disabled={loading} className='register_btn' >SUBMIT</button>
            </form>
        </div>
    </>
  )
}

export default Password
