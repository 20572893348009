import React, { useState,useContext } from 'react'
import DrillsPopup from './DrillsPopup';
import PrintClubSession from './Print/Session/PrintClubSession';
import { Link, NavLink } from 'react-router-dom';
import UpdateSessionPopup from './UpdateSessionPopup';
import ConfirmPopup from './ConfirmPopup';
import { userData } from '../Context/Context';
// import ClubSessionDrillsPopup from './ClubSessionDrillsPopup';
// import SessionDrillsPopup from '../SessionDrillsPopup/SessionDrillsPopup';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ClubSessionComp({club,session,userSession,isMember,isOwner,getClubDataById,userSessions}) {
  const {url,userdata} = useContext(userData);
  const [openDrillsPopup,setopenDrillsPopup] = useState(false);
  const [openUpdateSessionPopup,setopenUpdateSessionPopup] = useState(false);
  const [openDeleteSessionPopup,setopenDeleteSessionPopup] = useState(false);
  const [openPrintSession,setopenPrintSession] = useState(false);
  const [showSetting,setshowSetting] = useState(false);
  const [showDropdown,setshowDropdown] = useState(false);
  const handleEditClick = ()=>{
    setshowDropdown(false);
    setopenDrillsPopup(true);
  }
  const handleUpdateSessionClick = ()=>{
    setshowDropdown(false);
    setopenUpdateSessionPopup(true);
  }
  const handleDeleteSessionClick = ()=>{
    setshowDropdown(false);
    setopenDeleteSessionPopup(true);
  }
  const handleMouseLeave = ()=>{
    setshowDropdown(false);
  }

  // console.log(session);
  const [loading,setloading] = useState(false);
  const handleConfirmDeletePopup = async () =>{
        // console.log(session);
        setloading(true);
        const res = await fetch(`${url}deleteClubSession`,{
            method: "POST",
            headers: {
              "Content-Type" : "application/json"
            },
            body: JSON.stringify({
              clubId: club._id,
              sessionId: session._id,
              userId: userdata._id,
            }),
        });
        const data = await res.json();
        setloading(false);
        // console.log(data.data);
        if(data.msg === "success"){
            getClubDataById();
            setopenDeleteSessionPopup(false);
            setopenDrillsPopup(false);
        }
  }
  const [copySuccessOpen,setcopySuccessOpen] = useState(false);
  const [copyFailedOpen,setcopyFailedOpen] = useState(false);
  const handleCopySession = async () =>{
        // console.log(session);
        setshowDropdown(false);
        setloading(true);
        const res = await fetch(`${url}CopyClubSession`,{
            method: "POST",
            headers: {
              "Content-Type" : "application/json"
            },
            body: JSON.stringify({
              clubId: club._id,
              sessionId: session._id,
              userId: userdata._id,
            }),
        });
        const data = await res.json();
        setloading(false);
        // console.log(data.data);
        getClubDataById();
        if(data.msg === "success"){
          setcopySuccessOpen(true);
          setTimeout(() => {
            setcopySuccessOpen(false);
            setcopyFailedOpen(false);
          }, 3000);
        }else{
          setcopyFailedOpen(true);
          setTimeout(() => {
            setcopySuccessOpen(false);
            setcopyFailedOpen(false);
          }, 3000);
        }
  }
  const CopyClubSessionToPrivateSession = async () =>{
        // console.log(session);
        setshowDropdown(false);
        setloading(true);
        const res = await fetch(`${url}CopyClubSessionToPrivateSession`,{
            method: "POST",
            headers: {
              "Content-Type" : "application/json"
            },
            body: JSON.stringify({
              clubId: club._id,
              sessionId: session._id,
              userId: userdata._id,
            }),
        });
        const data = await res.json();
        setloading(false);
        // console.log(data.data);
        getClubDataById();
        if(data.msg === "success"){
          setcopySuccessOpen(true);
          setTimeout(() => {
            setcopySuccessOpen(false);
            setcopyFailedOpen(false);
          }, 3000);
        }else{
          setcopyFailedOpen(true);
          setTimeout(() => {
            setcopySuccessOpen(false);
            setcopyFailedOpen(false);
          }, 3000);
        }
  }

  return (
    // onClick={()=>{setopenDrillsPopup(true)}}
    <>
      <Snackbar open={copySuccessOpen} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
            <Alert severity="success" sx={{ width: '100%' }}>
                Session Copied successfully!
            </Alert>
        </Snackbar>
        <Snackbar open={copyFailedOpen} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
            <Alert severity="error" sx={{ width: '100%' }}>
                Copying failed, refresh and try again!
            </Alert>
        </Snackbar>
        <div className='session_comp no-pointer' style={{border:userSession?'1px solid #57cdd4a6':''}} onMouseLeave={handleMouseLeave} >
            <div className='session_comp_left' >
              <div className='normal_text' > <span className='g_mini_text fs_20' >Name : </span> {session.name} </div>
              <div className='normal_text' > <span className='g_mini_text fs_20' >Creator : </span> {session.created_by.email} </div>
              <div className='normal_text' > <span className='g_mini_text fs_20' >Team : </span> {session.team} </div>
              <div className='normal_text' > <span className='g_mini_text fs_20' >Duration : </span> {session.duration} </div>
              <div className='normal_text' > <span className='g_mini_text fs_20' >Intensity : </span> {session.intensity} </div>
              <div className='normal_text' > <span className='g_mini_text fs_20' >Date : </span> {new Date(session.date).toLocaleString('en-US', { day: 'numeric', month: 'short',year: 'numeric' })} </div>
            </div>
            <div className='session_comp_right' >
              <div className='flex_between2' >
                <div>
                  <div className='normal_text' > <span className='g_mini_text fs_20' >Drills added : </span> {session.drills?session.drills.length:'0'} </div>
                </div>
                <div>
                  { <div className='relative-dropdown' style={{position:'relative'}} >
                        { (isOwner || isMember) &&
                          <div className='three-dots' onClick={()=>{setshowDropdown(!showDropdown)}} >
                            <svg xmlns="http://www.w3.org/2000/svg" fill='#49d0dc' height="20" width="6" viewBox="0 0 128 512"><path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"/></svg>
                          </div>
                        }
                      { showDropdown &&
                        <>
                          {
                            userSession ?
                            <>
                              <div className='club-setting-dropdown' >
                                <div className='club-setting-dropdown-div b-t-lr p-top-54' onClick={handleEditClick} >View Drills</div>
                                <div className='club-setting-dropdown-link' >
                                  <Link className='bsjdvb' to={`/new-drill?add-club-drill=true&club=${club._id}&session=${session._id}`} target='_blank' >Add Drill</Link>
                                </div>
                                <div className='club-setting-dropdown-div' onClick={handleUpdateSessionClick} >Edit Session</div>
                                <div className='club-setting-dropdown-link' >
                                  <Link className='bsjdvb' to={`/print-club-session?club=${club._id}&session=${session._id}`} target='_blank' >Print Session</Link>
                                </div>
                                <div className='club-setting-dropdown-div' onClick={handleCopySession} >Copy Session to club</div>
                                <div className='club-setting-dropdown-div' onClick={CopyClubSessionToPrivateSession} >Copy Session to private</div>
                                <div className='b-b-lr p-bot-54 club-setting-dropdown-div' onClick={handleDeleteSessionClick} >Delete</div>
                              </div>
                              </>
                              :
                              <>
                              <div className='club-setting-dropdown' >
                                {/* <div className='club-setting-dropdown-link b-t-lr p-top-54' >
                                  <Link className='bsjdvb' to={`/new-drill?club=${club._id}&session=${session._id}`} target='_blank' >Add Drill</Link>
                                </div> */}
                                <div className='club-setting-dropdown-div b-t-lr p-top-54' onClick={handleEditClick} >View Drills</div>
                                {/* <div className='club-setting-dropdown-div' onClick={handleUpdateSessionClick} >Edit Session</div> */}
                                <div className='club-setting-dropdown-div' onClick={handleCopySession} >Copy Session</div>
                                <div className='club-setting-dropdown-link b-b-lr p-bot-54' >
                                  <Link className='bsjdvb' to={`/print-club-session?club=${club._id}&session=${session._id}`} target='_blank' >Print Session</Link>
                                </div>
                                {/* <div className='b-b-lr p-bot-54 club-setting-dropdown-div' onClick={handleDeleteSessionClick} >Delete</div> */}
                              </div>
                            </>
                          }
                        </>
                      }
                    </div>
                  }
                </div>
              </div>
              <div className='normal_text' > <span className='g_mini_text fs_20' >Note : </span></div>
              <div className='normal_text scroll_admin_section height_3r' > {session.notes} </div>
            </div>
        </div>
        {   openDrillsPopup ? (
            <>
                <DrillsPopup setopenDrillsPopup={setopenDrillsPopup} club={club} session={session} drills={session.drills} 
                  userSession={userSession} isMember={isMember} isOwner={isOwner} getClubDataById={getClubDataById}
                  setopenDeleteSessionPopup={setopenDeleteSessionPopup} setopenUpdateSessionPopup={setopenUpdateSessionPopup}
                  handleConfirmDeletePopup={handleConfirmDeletePopup} setloading={setloading} userSessions={userSessions}
                />
            </>
        ):<></>
        }
        { openUpdateSessionPopup?
            <>
                <UpdateSessionPopup club={club} session={session} setopenUpdateSessionPopup={setopenUpdateSessionPopup} 
                    getClubDataById={getClubDataById} setopenDrillsPopup={setopenDrillsPopup} />
            </>:<></>
        }
        { openDeleteSessionPopup?
            <>
                <ConfirmPopup open={openDeleteSessionPopup} setOpen={setopenDeleteSessionPopup} handleconfirm={handleConfirmDeletePopup}
                    message={`Are you sure you want to delete your session ${session.name}?`} loading={loading} />
            </>:<></>
        }
    </>
  )
}
export default ClubSessionComp