import React, { useState,useContext } from 'react'
import { userData } from '../../Context/Context'
import ClubsComp from './ClubsComp';
import InviteClubComp from './InviteClubComp';

function AddClubPopup({setopenAddClubPopup,getAllDataforAdmin}) {
    const { userdata,setuserdata,url,getLoggedin} = useContext(userData);
    const [search,setsearch] = useState('');
    const [clubs,setClubs] = useState({});
    const [loading,setloading] = useState(false);
    
    // const matchedDrills = user.drills.filter((userDrill) =>
    //     drills.some((drill) => drill.id === userDrill._id)
    // );
    // const [filteredDrills, setfilteredDrills] = useState(matchedDrills);

    const handleSearch = (e) => {
        e.preventDefault();
        // if (search === '') {
        //     setfilteredDrills(matchedDrills);
        // } else {
        //   const filtered = matchedDrills.filter((drillData) =>
        //     drillData.name.toLowerCase().includes(search.toLowerCase())
        //   );
        //   setfilteredDrills(filtered);
        // }
    };
    // const handleChange = (e) =>{
    //     // if(e.target.value === '' ){
    //     //     setfilteredDrills(matchedDrills);
    //     // }
    //     // setsearch(e.target.value);
    // }
    const [formData, setFormData] = useState({
        title: '',
        members: [],
        owner: '',
        link: '',
        description: '',
      });
    
    const [newMember, setNewMember] = useState('');
    const [activeIndex,setactiveIndex] = useState(-1);

    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };

    const handleAddMember = (e) => {
      e.preventDefault();
      if (newMember.trim() !== '') {
        setFormData({
          ...formData,
          members: [...formData.members, newMember.trim()],
        });
        setNewMember('');
      }
    };

    const handleRemoveMember = (index) => {
      const updatedMembers = [...formData.members];
      updatedMembers.splice(index, 1);
      setFormData({
        ...formData,
        members: updatedMembers,
      });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const res = await fetch(`${url}requestClub`,{
            method: "POST",
            headers: {
              "Content-Type" : "application/json",
              "authToken": localStorage.getItem("authToken"),
            },
            body: JSON.stringify({
              userId: userdata._id,
              owner: userdata.email,
              title: formData.title,
              description: formData.description,
              members: formData.members,
            }),
        });
        const data = await res.json();

        if(data.msg === "success"){
            getLoggedin();
            setopenAddClubPopup(false);
        }else{
            console.log('failed');
        }
    };
    const handleGetClubByLink = async (e) => {
        e.preventDefault();
        if(search === ''){
            return;
        }
        setloading(true);
        const res = await fetch(`${url}searchClubByLink`,{
            method: "POST",
            headers: {
              "Content-Type" : "application/json",
              "authToken": localStorage.getItem("authToken"),
            },
            body: JSON.stringify({
              link: search,
            }),
        });
        const data = await res.json();
        setloading(false);
        if(data.msg === "success"){
            setClubs(data.club);
            console.log(data.club);
        }else{
            console.log('failed');
        }
    };

    return (
    <>
        <div className='popup_full_page' >
            <div className='grid5522' >
                <div className='grid_5522_left' >
                    <div className='g_mini_text width_rt fw_700 ' >Requests</div>
                    <div className='club_requests_con' >
                        { userdata.clubJoinRequests && userdata.clubJoinRequests.length > 0 ? 
                            userdata.clubJoinRequests.map((request,ind)=>{
                            return(
                                <>
                                <div className='club_requests_elem' >
                                    <div>
                                        <div className='normal_text' > <span className='g_mini_text fs_20' >Title : </span> {request.title} </div>
                                        <div className='normal_text' > <span className='g_mini_text fs_20' >Owner : </span> {request.owner} </div>
                                        <div className='normal_text' > <span className='g_mini_text fs_20' >Status : </span> <span className={ request.status === 'approved' ? 'approved_text' : request.status === 'rejected' ? 'rejected_text' : 'pending_text' } > {request.status} </span> </div>
                                        {/* <div className='normal_text' > <span className='g_mini_text fs_20' >Members : </span> {request.members ? request.members.length : 0 } </div> */}
                                        <div>
                                            <div className='flex_end' ><span className='g_mini_text date_bot' >
                                                {new Date(request.created_at).toLocaleString('en-US', { 
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                    day: 'numeric', 
                                                    month: 'short',
                                                    year: 'numeric',
                                                })}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <svg className='cross-filled' xmlns="http://www.w3.org/2000/svg" height="22" width="22" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"/></svg>
                                </div>
                                </>
                            )
                        })
                        :
                        <>
                            <div className='empty_page2' >
                                No request submitted yet
                            </div>
                        </>
                        }
                    </div>
                </div>
                <div className='add_club_con' >
                    <div>
                        <div className='page_head_left' >
                            <div className='g_mini_text width_rt fw_700 ' >Search a club</div>
                            <input
                                className='search_input'
                                name='search'
                                placeholder='Enter club link'
                                value={search}
                                onChange={(e)=>{setsearch(e.target.value)}}
                            />
                            <button className='search_btn' onClick={handleGetClubByLink} >Search</button>
                        </div>
                        <div>
                            {   Object.keys(clubs).length > 0 ?(
                                    <>
                                        <InviteClubComp club={clubs} />
                                    </>
                                ):(<></>)
                            }
                            { loading &&
                                <div className='empty_page flex' >
                                    Loading...
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='x_mark' onClick={()=>{setopenAddClubPopup(false)}} >
            <svg width="22" height="32" viewBox="0 0 30 40" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.7656 11.7656C27.7422 10.7891 27.7422 9.20312 26.7656 8.22656C25.7891 7.25 24.2031 7.25 23.2266 8.22656L15 16.4609L6.76562 8.23437C5.78906 7.25781 4.20312 7.25781 3.22656 8.23437C2.25 9.21094 2.25 10.7969 3.22656 11.7734L11.4609 20L3.23438 28.2344C2.25781 29.2109 2.25781 30.7969 3.23438 31.7734C4.21094 32.75 5.79687 32.75 6.77344 31.7734L15 23.5391L23.2344 31.7656C24.2109 32.7422 25.7969 32.7422 26.7734 31.7656C27.75 30.7891 27.75 29.2031 26.7734 28.2266L18.5391 20L26.7656 11.7656Z" fill="white"/>
            </svg>
        </div>
    </>
  )
}

export default AddClubPopup
