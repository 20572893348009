import React,{useState} from 'react'
import { NavLink } from 'react-router-dom';
import DrillCompAdd from './DrillCompAdd';

function ChooseDrill({drills,setdrills,getSessionDrills,alradyErrorMsg,setalradyErrorMsg,addSuccessMsg,setaddSuccessMsg,deleteSuccessMsg,setdeleteSuccessMsg,setselectedDrillId,setsessionPopup,id, userdata, setuserdata, url, getLoggedin}) {
    
    const [sortedByname,setsortedByname] = useState(true);
    const [sortedDrillsByname,setsortedDrillsByname] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredSessions, setFilteredSessions] = useState([]);
    const [showFiltered,setshowFiltered] = useState(false);
    const [searchQueryDrills, setSearchQueryDrills] = useState('');
    const [filteredSessionsDrills, setFilteredSessionsDrills] = useState([]);
    const [showFilteredDrills,setshowFilteredDrills] = useState(false);

    const sortSessionsByName = () => {
        setsortedByname(false);
        const sortedSessions = [...userdata.session];
        sortedSessions.sort((a, b) => {
            return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
        });
        setuserdata({ ...userdata, session: sortedSessions });
    };
    const reverseSortSessionsByName = () => {
        setsortedByname(true);
        const sortedSessions = [...userdata.session];
        sortedSessions.sort((a, b) => {            
            return b.name.localeCompare(a.name, undefined, { sensitivity: 'base' });
        });
        setuserdata({ ...userdata, session: sortedSessions });
    };
    const sortDrillsByName = () => {
        setsortedDrillsByname(false);
        const sortedSessions = [...drills];
        sortedSessions.sort((a, b) => {
            return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
        });
        setdrills({ sortedSessions });
    };
    const reverseSortDrillsByName = () => {
        setsortedDrillsByname(true);
        const sortedSessions = [...drills];
        sortedSessions.sort((a, b) => {            
            return b.name.localeCompare(a.name, undefined, { sensitivity: 'base' });
        });
        setdrills({sortedSessions });
    };
    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        if (query.trim() === '') {
            // If the search query is empty, reset the filteredSessions            
            setFilteredSessions([]);
            setshowFiltered(false);
        } else {
            setshowFiltered(true);

            // Filter sessions based on the search query
            const filtered = userdata.drills.filter((session) =>
                session.name.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredSessions(filtered);
        }
    };
    return (
    <>
        <div className='session-title' onClick={()=>{console.log(showFiltered)}} >CHOOSE A DRILL </div>
            <hr className='session-line' />
            <div className='flex-gap' >
                <NavLink className='no-underline' to='/new-drill'>
                    <div className='session-btn1'>
                        <div className='flex' ><svg xmlns="http://www.w3.org/2000/svg" fill='#2f8c2f' height="1em" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg></div>
                        <div className='session-title2' style={{color:'#2f8c2f'}} >NEW DRILL</div>
                    </div>
                </NavLink>
                <div className='search-btn' >
                    <input 
                        className='search-input'
                        placeholder='Search Drill'
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                </div>                        
            </div>
            <hr className='session-line' />
            <div className='flex-align2' style={{justifyContent: 'space-between'}} >
                <div className='flex-align' >
                    <div>Name</div>
                    <div className='border-bot' onClick={reverseSortSessionsByName}  style={{ cursor:'pointer',borderBottom: sortedByname?'2px solid #45454580':'none'}} >
                        <svg xmlns="http://www.w3.org/2000/svg" fill='#45454590' height="1em" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                    </div>
                    <div className='border-bot' onClick={sortSessionsByName} style={{ cursor:'pointer',borderBottom: sortedByname?'none':'2px solid #45454580'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill='#45454590' height="1em" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                    </div>
                </div>
                <div className='es-buttons' style={{marginTop: '0'}} >
                    <button onClick={()=>{setsessionPopup(false)}} >Cancel</button>
                </div>
                {/* <div className='flex-align' >
                    <div>Date:</div>
                    <div className='border-bot' onClick={getLoggedin} style={{borderBottom: '2px solid #45454580'}} >
                        <svg xmlns="http://www.w3.org/2000/svg" fill='#45454590' height="1em" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                    </div>
                    <div className='border-bot' >
                        <svg xmlns="http://www.w3.org/2000/svg" fill='#45454590' height="1em" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                    </div>
                </div> */}
            </div>
        <div className='session-title-con' style={{maxHeight:'40rem',overflowY: userdata.drills.length>3?'scroll':'' }} >
            {userdata.drills.length > 0 && !showFiltered && userdata.drills.map((drill, index) => {
                return (
                    <DrillCompAdd getSessionDrills={getSessionDrills} alradyErrorMsg={alradyErrorMsg} setalradyErrorMsg={setalradyErrorMsg} addSuccessMsg={addSuccessMsg} setaddSuccessMsg={setaddSuccessMsg} deleteSuccessMsg={deleteSuccessMsg} setdeleteSuccessMsg={setdeleteSuccessMsg} setselectedDrillId={setselectedDrillId} setsessionPopup={setsessionPopup} drill={drill} id={id} index={index} userdata={userdata} setuserdata={setuserdata} url={url} getLoggedin={getLoggedin} />
                );
            })}
            {userdata.drills.length > 0 && showFiltered && filteredSessions.map((drill, index) => {
                return (
                    <DrillCompAdd getSessionDrills={getSessionDrills} alradyErrorMsg={alradyErrorMsg} setalradyErrorMsg={setalradyErrorMsg} addSuccessMsg={addSuccessMsg} setaddSuccessMsg={setaddSuccessMsg} deleteSuccessMsg={deleteSuccessMsg} setdeleteSuccessMsg={setdeleteSuccessMsg} setselectedDrillId={setselectedDrillId} setsessionPopup={setsessionPopup} drill={drill} id={id} index={index} userdata={userdata} setuserdata={setuserdata} url={url} getLoggedin={getLoggedin} />
                );
            })}
            {userdata.drills.length === 0 &&
                <div className='flex' style={{height:'8rem'}} >
                    No Data to Show
                </div>
            }
            {showFiltered && filteredSessions.length === 0 &&
                <div className='flex' style={{height:'8rem'}} >
                    No Matched Results
                </div>
            }
        </div> 
    </>
  )
}

export default ChooseDrill
