import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import ClubSessionDrillsPopup from './ClubSessionDrillsPopup';
// import SessionDrillsPopup from '../SessionDrillsPopup/SessionDrillsPopup';

function ClubSessionComp({session}) {
  const [openclubSessionDrillsPopup,setopenclubSessionDrillsPopup] = useState(false);
  
  return (
    <>
        <div className='session_comp' onClick={()=>{setopenclubSessionDrillsPopup(true)}} >
            <div className='session_comp_left' >
                <div className='normal_text' > <span className='g_mini_text fs_20' >Name : </span> {session.name} </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Team : </span> {session.team} </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Duration : </span> {session.duration} </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Intensity : </span> {session.intensity} </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Date : </span> 
                    {new Date(session.date).toLocaleString('en-US', { day: 'numeric', month: 'short',year: 'numeric' })}
                </div>
            </div>
            <div className='session_comp_right' >
                <div className='normal_text' > <span className='g_mini_text fs_20' >Drills added : </span> { session.drills?session.drills.length:'0' } </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Note : </span></div>
                <div className='normal_text scroll_admin_section height_3r' > { session.notes } </div>
            </div>
        </div>
        {   openclubSessionDrillsPopup ? (
            <>
                <ClubSessionDrillsPopup setopenclubSessionDrillsPopup={setopenclubSessionDrillsPopup} />
            </>
        ):<></>
        }
    </>
  )
}
export default ClubSessionComp