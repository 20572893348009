import React, { useEffect, useState } from 'react'

function DrillsComp({drill,index,user}) {
    // const [drill,setdrill] = useState({});
    // useEffect(()=>{
    //     const data = user.drills.filter((drillData)=>drillData._id === drill.id);
    //     setdrill(data[0]);
    // },[]);
  return (
    <>
        <div className='drills_comp' >
            <div className='drills_comp_left' >
                <img src='/img/svgexport-6.png' />
            </div>
            <div className='drills_comp_right' >
                <div className='grid_2_col' >
                    <div>
                        <div className='normal_text' > <span className='g_mini_text fs_20' >Drill Name : </span> {drill.name} </div>
                    </div>
                    <div>
                        <div className='normal_text' > <span className='g_mini_text fs_20' >Category : </span> {drill.category} </div>
                    </div>
                </div>
                <div className='flex_align' >
                    <div>
                        <div className='normal_text' > <span className='g_mini_text fs_20' >Field Size : </span> {drill.fieldSize} </div>
                    </div>
                    <div>
                        <div className='normal_text' > <span className='g_mini_text fs_20' >Duration : </span> {drill.duration} </div>
                    </div>
                </div>
                <div className='grid_3_col' >
                    <div>
                        <div className='normal_text' > <span className='g_mini_text fs_20' >Setup : </span></div>
                        <div className='normal_text scroll_admin_section height_3r' > {drill.setup} </div>
                    </div>
                    <div>
                        <div className='normal_text' > <span className='g_mini_text fs_20' >Instructions : </span></div>
                        <div className='normal_text scroll_admin_section height_3r' > {drill.instructions} </div>
                    </div>
                    <div>
                        <div className='normal_text' > <span className='g_mini_text fs_20' >Coaching Points : </span></div>
                        <div className='normal_text scroll_admin_section height_3r' > {drill.points} </div>
                    </div>
                </div>
            </div>
        </div> 
    </>
  )
}

export default DrillsComp
