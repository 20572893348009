import React, { useState } from 'react'
import ClubSessionComp from './ClubSessionComp'
import AllMembersComp from './AllMembersComp';

function ClubDetailPage({setopenClubDetailPage}) {
    const handleClose = () =>{
        setopenClubDetailPage(false);
        console.log("Detail page closed");
    }
    const [showMembers,setshowMembers] = useState(false);
  return (
    <>
        <div className='popup_full_page' >
            <div className='search_nav flex'>
                <div className='page_head width_40rem' >
                    <div>
                        <div className='back flex' onClick={handleClose} >
                            <svg width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M26.9238 25.8164C27.4805 26.2793 28.2598 26.3848 28.9219 26.0742C29.584 25.7637 30 25.1015 30 24.375V5.62499C30 4.89842 29.5781 4.23632 28.9219 3.92577C28.2656 3.61522 27.4863 3.71483 26.9238 4.18358L16.875 12.5566V15V17.4433L26.9238 25.8164ZM15 20.625V15V7.49999V5.62499C15 4.89842 14.5781 4.23632 13.9219 3.92577C13.2656 3.61522 12.4863 3.71483 11.9238 4.18358L0.673828 13.5586C0.246094 13.916 0 14.4433 0 15C0 15.5566 0.246094 16.084 0.673828 16.4414L11.9238 25.8164C12.4805 26.2793 13.2598 26.3848 13.9219 26.0742C14.584 25.7637 15 25.1015 15 24.375V20.625Z" fill="#49D0DC"/>
                            </svg>
                        </div>
                    </div>
                    <div className='page_head_left' >                        
                        <div className='page-title m0 mr_15'>Club Details</div>
                        <input
                            className='search_input'
                            name='searchDrill'
                            placeholder='Search Drill'
                            // value={searchDrill}
                            // onChange={(e)=>{setsearchDrill(e.target.value)}}
                        />
                        <button className='search_btn' >Search</button>
                    </div>
                    <div className='page_head_right' >
                        <div className='reload'>
                            <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.52031 8.52031C12.0839 4.95676 17.8417 4.93958 21.4281 8.46301L19.0677 10.8177C18.6724 11.213 18.5578 11.8031 18.7698 12.3187C18.9818 12.8344 19.4859 13.1667 20.0417 13.1667H26.888H27.375C28.137 13.1667 28.75 12.5536 28.75 11.7917V4.45833C28.75 3.9026 28.4177 3.39843 27.9021 3.18645C27.3865 2.97447 26.7964 3.08906 26.401 3.48437L24.0177 5.8677C18.999 0.911973 10.9151 0.92916 5.925 5.92499C4.52708 7.32291 3.51875 8.96718 2.9 10.726C2.56198 11.6828 3.06615 12.7255 4.01719 13.0635C4.96823 13.4016 6.01667 12.8974 6.35469 11.9463C6.79583 10.6974 7.51198 9.52291 8.52031 8.52031ZM1.25 18.2083V18.6437V18.6838V25.5417C1.25 26.0974 1.58229 26.6016 2.09792 26.8135C2.61354 27.0255 3.20365 26.9109 3.59896 26.5156L5.98229 24.1323C11.001 29.088 19.0849 29.0708 24.075 24.075C25.4729 22.6771 26.487 21.0328 27.1057 19.2797C27.4438 18.3229 26.9396 17.2802 25.9885 16.9422C25.0375 16.6042 23.9891 17.1083 23.651 18.0594C23.2099 19.3083 22.4938 20.4828 21.4854 21.4854C17.9219 25.049 12.1641 25.0661 8.5776 21.5427L10.9323 19.1823C11.3276 18.787 11.4422 18.1969 11.2302 17.6812C11.0182 17.1656 10.5141 16.8333 9.95833 16.8333H3.10625H3.06615H2.625C1.86302 16.8333 1.25 17.4463 1.25 18.2083Z" fill="#49D0DC"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className='admin_body_main'>
                <div className='a_body_left mr_1rem'>
                    <div className='flex_between2' >
                        <div>
                            <div className='g_mini_text' >Club Info</div>
                        </div>
                        <div>
                        {   showMembers?(
                                <>
                                    <div className='back flex' onClick={()=>{setshowMembers(false)}} >
                                        <svg width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M26.9238 25.8164C27.4805 26.2793 28.2598 26.3848 28.9219 26.0742C29.584 25.7637 30 25.1015 30 24.375V5.62499C30 4.89842 29.5781 4.23632 28.9219 3.92577C28.2656 3.61522 27.4863 3.71483 26.9238 4.18358L16.875 12.5566V15V17.4433L26.9238 25.8164ZM15 20.625V15V7.49999V5.62499C15 4.89842 14.5781 4.23632 13.9219 3.92577C13.2656 3.61522 12.4863 3.71483 11.9238 4.18358L0.673828 13.5586C0.246094 13.916 0 14.4433 0 15C0 15.5566 0.246094 16.084 0.673828 16.4414L11.9238 25.8164C12.4805 26.2793 13.2598 26.3848 13.9219 26.0742C14.584 25.7637 15 25.1015 15 24.375V20.625Z" fill="#49D0DC"/>
                                        </svg>
                                    </div>
                                </>
                            ):(
                                <>
                                    <button className='search_btn' onClick={()=>{setshowMembers(true)}} >Members</button>
                                </>
                            )
                        }
                        </div>
                    </div>
                    <div className='sidebar_comp'>
                        <div className='normal_text mb_5' > <span className='g_mini_text fs_20' >Title : </span> BlackCubes </div>
                        <div className='normal_text mb_5' > <span className='g_mini_text fs_20' >Members : </span> 150 </div>
                        <div className='normal_text mb_5' > <span className='g_mini_text fs_20' >Owner : </span> afaqwebdev@gmail.com </div>
                        <div className='m_7878' > 
                            <span className='g_mini_text fs_20' >Invite Link : </span> 
                            <span className='link_copy_field normal_text' > ds45ds5dxcsd5464d 
                                <span>
                                    <svg className='m_546' width="12" height="14" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_199820_1817)">
                                        <path d="M13.7143 11.8125H6.85714C6.54286 11.8125 6.28571 11.5594 6.28571 11.25V2.25C6.28571 1.94062 6.54286 1.6875 6.85714 1.6875H11.8607L14.2857 4.07461V11.25C14.2857 11.5594 14.0286 11.8125 13.7143 11.8125ZM6.85714 13.5H13.7143C14.975 13.5 16 12.491 16 11.25V4.07461C16 3.62812 15.8179 3.19922 15.4964 2.88281L13.075 0.495703C12.7536 0.179297 12.3179 0 11.8643 0H6.85714C5.59643 0 4.57143 1.00898 4.57143 2.25V11.25C4.57143 12.491 5.59643 13.5 6.85714 13.5ZM2.28571 4.5C1.025 4.5 0 5.50898 0 6.75V15.75C0 16.991 1.025 18 2.28571 18H9.14286C10.4036 18 11.4286 16.991 11.4286 15.75V14.625H9.71429V15.75C9.71429 16.0594 9.45714 16.3125 9.14286 16.3125H2.28571C1.97143 16.3125 1.71429 16.0594 1.71429 15.75V6.75C1.71429 6.44063 1.97143 6.1875 2.28571 6.1875H3.42857V4.5H2.28571Z" fill="#1E1A26"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_199820_1817">
                                        <rect width="16" height="18" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                            </span>
                        </div>
                        <div className='normal_text mb_5'> <span className='g_mini_text fs_20' >Description : </span></div>
                        <div className='normal_text scroll_admin_section height_3r mb_10' >Lorem ipsum dolor sit Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt utamet, consectetur adipiscing elit, sed do eiusmod tempor incididunt utLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna eiusmod tempor. </div>
                        <div className='normal_text flex_end'> <span className='g_mini_text fs_20' >15 May, 2023 </span> </div>
                        <div className='normal_text mb_5'> <span className='g_mini_text fs_20' >Total Sessions : </span> 150 </div>
                        <div className='normal_text mb_5'> <span className='g_mini_text fs_20' >Total Drills : </span> 6550 </div>
                    </div>
                </div>
                <div className='a_body_right'>
                    { showMembers?(                        
                        <>
                            <div className='page-title m0 mr_15'>Club Members</div>
                            <div className='grid1_title' >
                                <div className='g_mini_text fs_20'>Name</div>
                                <div className='g_mini_text fs_20'>Email</div>
                                <div className='g_mini_text fs_20'>Club Joined</div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className='scroll_admin_section scroll2_height' >
                                <AllMembersComp />
                                <AllMembersComp />
                                <AllMembersComp />
                                <AllMembersComp />
                                <AllMembersComp />
                                <AllMembersComp />
                                <AllMembersComp />
                                <AllMembersComp />
                                <AllMembersComp />
                                <AllMembersComp />
                                <AllMembersComp />
                                <AllMembersComp />
                                <AllMembersComp />
                                <AllMembersComp />
                                <AllMembersComp />
                                <AllMembersComp />
                                <AllMembersComp />
                            </div>
                        </>
                    ):(
                        <>
                            <div className='page-title m0 mr_15'>Club's Sessions</div>
                            <div className='clubs_body scroll_admin_section scroll6_height' >
                                <ClubSessionComp />
                                <ClubSessionComp />
                                <ClubSessionComp />
                                <ClubSessionComp />
                                <ClubSessionComp />
                                <ClubSessionComp />
                                <ClubSessionComp />
                                <ClubSessionComp />
                                <ClubSessionComp />
                                <ClubSessionComp />
                            </div>
                        </>
                    )
                }
                </div>
            </div>
        </div>
    </>
  )
}

export default ClubDetailPage
