import React, { useState } from 'react'
import DrillsPopup from './DrillsPopup';
// import ClubSessionDrillsPopup from './ClubSessionDrillsPopup';
// import SessionDrillsPopup from '../SessionDrillsPopup/SessionDrillsPopup';

function ClubSessionComp({club,session,userSession,getClubDataById}) {
  const [openDrillsPopup,setopenDrillsPopup] = useState(false);
// console.log(session);
  return (
    <>
        <div className='session_comp' style={{border:userSession?'1px solid #57cdd4a6':''}} onClick={()=>{setopenDrillsPopup(true)}} >
            <div className='session_comp_left' >
                <div className='normal_text' > <span className='g_mini_text fs_20' >Name : </span> {session.name} </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Creator : </span> {session.created_by.email} </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Team : </span> {session.team} </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Duration : </span> {session.duration} </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Intensity : </span> {session.intensity} </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Date : </span> {new Date(session.date).toLocaleString('en-US', { day: 'numeric', month: 'short',year: 'numeric' })} </div>
            </div>
            <div className='session_comp_right' >
                <div className='normal_text' > <span className='g_mini_text fs_20' >Drills added : </span> {session.drills?session.drills.length:'0'} </div>
                <div className='normal_text' > <span className='g_mini_text fs_20' >Note : </span></div>
                <div className='normal_text scroll_admin_section height_3r' > {session.notes} </div>
            </div>
        </div>
        {   openDrillsPopup ? (
            <>
                <DrillsPopup setopenDrillsPopup={setopenDrillsPopup} club={club} session={session} drills={session.drills} 
                  getClubDataById={getClubDataById}
                />
            </>
        ):<></>
        }
    </>
  )
}
export default ClubSessionComp