import React, { useState,useEffect,useContext } from 'react'
import { userData } from '../Context/Context';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function UpdateSessionPopup({club,session,setopenDrillsPopup,setopenUpdateSessionPopup,getClubDataById}) {
    const {url} = useContext(userData);
    const [emailUpdatesuccess,setemailUpdatesuccess] = useState(false);
    const [emailUpdateFail,setemailUpdateFail] = useState(false);
    const [loading,setloading] = useState(false);

    const [name,setName] = useState(session.name);
    const [notes,setNotes] = useState(session.notes);
    const [team,setTeam] = useState(session.team);
    const [duration,setDuration] = useState(session.duration);
    const [intensity,setIntensity] = useState(session.intensity);
    const handleClose = () =>{
        setopenUpdateSessionPopup(false);
    }

    const handleSubmit =async (e)=> {
        e.preventDefault();
        setloading(true);
        const res = await fetch(`${url}updateClubSession`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "authToken": localStorage.getItem("authToken")
              },
              body: JSON.stringify({
                clubId: club._id,
                sessionId: session._id,
                name, notes, team, duration, intensity
              }),
            });
            setloading(false);
            const data = await res.json();
            if(data.msg==='success'){
                getClubDataById();
              setemailUpdatesuccess(true);
              setTimeout(() => {
                  setemailUpdatesuccess(false);
                  handleClose();
                  setopenDrillsPopup(false);
              }, 2000);
            }
            else{
                setemailUpdateFail(true);
                setTimeout(() => {
                    setemailUpdateFail(false);
                }, 2000);
            };
    }

  return (
    <>
        <Snackbar open={emailUpdatesuccess} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="success" sx={{ width: '100%' }}>
                Session Updated Successfully!
            </Alert>
        </Snackbar>
        <Snackbar open={emailUpdateFail} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="error" sx={{ width: '100%' }}>
                Update failed! Please try later.
            </Alert>
        </Snackbar>
        <div className='popup_full_page' >
            <div className='update_user_inner'>
                <div className='register_con' >
                    <div>
                        <div className='flex_between2 width_65' >
                            <div className='g_mini_text width_rt fw_700 font_19 ' >
                                <div>
                                    Update Session
                                </div>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} >
                        <div className='title_input_con' >
                            <div className='register-mini-bold-title' >Name</div>
                            <input 
                                className='register_input'
                                type='text' placeholder='Name' value={name} onChange={(e)=>{setName(e.target.value)}}
                            />
                        </div>
                        <div className='title_input_con' >
                            <div className='register-mini-bold-title' >Notes</div>
                            <textarea 
                                className='register_input'
                                type='text' placeholder='Notes' value={notes} onChange={(e)=>{setNotes(e.target.value)}}
                            />
                        </div>
                        <div className='title_input_con' >
                            <div className='register-mini-bold-title' >Team</div>
                            <textarea 
                                className='register_input'
                                type='text' placeholder='Team name' value={team} onChange={(e)=>{setTeam(e.target.value)}}
                            />
                        </div>
                        <div className='title_input_con' >
                            <div className='register-mini-bold-title' >Duration</div>
                            <input 
                                className='register_input'
                                type='text' placeholder='Duration' value={duration} onChange={(e)=>{setDuration(e.target.value)}}
                            />
                        </div>
                        <div className='title_input_con' >
                            <div className='register-mini-bold-title' >Intensity</div>
                            <select className='register_input' value={intensity} onChange={(e)=>{setIntensity(e.target.value)}} >
                                <option>0</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                                <option>7</option>
                                <option>8</option>
                                <option>9</option>
                            </select>
                        </div>
                        <button type='submit' disabled={loading} className='register_btn' >Update Session</button>
                    </form>
                </div>
            </div>
        </div>
        <div className='x_mark' onClick={handleClose} >
            <svg width="22" height="32" viewBox="0 0 30 40" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.7656 11.7656C27.7422 10.7891 27.7422 9.20312 26.7656 8.22656C25.7891 7.25 24.2031 7.25 23.2266 8.22656L15 16.4609L6.76562 8.23437C5.78906 7.25781 4.20312 7.25781 3.22656 8.23437C2.25 9.21094 2.25 10.7969 3.22656 11.7734L11.4609 20L3.23438 28.2344C2.25781 29.2109 2.25781 30.7969 3.23438 31.7734C4.21094 32.75 5.79687 32.75 6.77344 31.7734L15 23.5391L23.2344 31.7656C24.2109 32.7422 25.7969 32.7422 26.7734 31.7656C27.75 30.7891 27.75 29.2031 26.7734 28.2266L18.5391 20L26.7656 11.7656Z" fill="white"/>
            </svg>
        </div>
    </>
  )
}

export default UpdateSessionPopup
