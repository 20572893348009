import React,{useContext,useState} from 'react'
import { userData } from '../../Context/Context';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function ClubsComp({index,club,handleClosePopup,session}) {
    const {url,userdata} = useContext(userData);
    const [copySuccessOpen,setcopySuccessOpen] = useState(false);
    const [copyFailedOpen,setcopyFailedOpen] = useState(false);
    const [loading,setloading] = useState(false);

    const CopyPrivateSessionToClubSession = async () =>{
        if(loading){
          return;
        }
        setloading(true);
        const res = await fetch(`${url}CopyPrivateSessionToClubSession`,{
            method: "POST",
            headers: {
              "Content-Type" : "application/json"
            },
            body: JSON.stringify({
              clubId: club._id,
              sessionId: session._id,
              userId: userdata._id,
            }),
        });
        const data = await res.json();
        setloading(false);
        if(data.msg === "success"){
          setcopySuccessOpen(true);
          setTimeout(() => {
            setcopySuccessOpen(false);
            setcopyFailedOpen(false);
            handleClosePopup();
          }, 3000);
        }else{
          setcopyFailedOpen(true);
          setTimeout(() => {
            setcopySuccessOpen(false);
            setcopyFailedOpen(false);
          }, 3000);
        }
    }
  return (
    <>  
      <div style={{zIndex:50000}} >
        <Snackbar open={copySuccessOpen} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} >
            <Alert severity="success" sx={{ width: '100%' }}>
                Drill Copied to selected Session successfully!
            </Alert>
        </Snackbar>
        <Snackbar open={copyFailedOpen} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} >
            <Alert severity="error" sx={{ width: '100%' }}>
                Copying failed, refresh and try again!
            </Alert>
        </Snackbar>
      </div>
        <div className='ss-line' >
            <div>
                <div className='normal_text white' > <span className='g_mini_text fs_20' >Club : </span> {club?.title} </div>
                <div className='normal_text white' > <span className='g_mini_text fs_20' >Members : </span> {club.members?club.members.length:'0'} </div>
                <div className='normal_text white' > <span className='g_mini_text fs_20' >Date : </span> 
                    {new Date(club?.created_at).toLocaleString('en-US', { day: 'numeric', month: 'short',year: 'numeric' })}
                </div>
            </div>
            <div className='flex' >
                <button className='search_btn' type='submit' disabled={loading} onClick={CopyPrivateSessionToClubSession} >{ loading?'SAVING':'SELECT' }</button>
            </div>
        </div>
    </>
  )
}

export default ClubsComp
