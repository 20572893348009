import React, { useState,useRef,useContext, useEffect } from 'react'
import domtoimage from 'dom-to-image';
import { NavLink,useNavigate,useLocation } from 'react-router-dom';
import * as htmlToImage from 'html-to-image';
import './Home.css'
import html2canvas from "html2canvas";
import * as canvg from 'canvg';
import { saveAs } from "file-saver";
import DraggableIcon from './DraggableIcon';
import Navbar from './Navbar';
import { userloggedIn } from './Context/Context'
import { userData } from './Context/Context'
import CanvasApp from './CanvasApp'
import { Stage, Layer, Line, Rect, Circle, Transformer, Group, Text, RegularPolygon } from 'react-konva';
import Toolbar from './Toolbar'

function Home() {
    const { userlogged,setuserLogged } = useContext(userloggedIn);
    const { userdata,setuserdata,url,homeNav,sethomeNav, getLoggedin } = useContext(userData);
    sethomeNav(true);
    const Navigate = useNavigate();
    const location = useLocation();
    const isClubNewDrill = new URLSearchParams(location.search).get('add-club-drill'); //Add drill to a club requested from club's session's drill
    // console.log(isClubNewDrill);
    const isUpdateClubDrill = new URLSearchParams(location.search).get('update-club-drill'); //Add drill to a club requested from club's session's drill
    // console.log(isUpdateClubDrill);
    const clubId = new URLSearchParams(location.search).get('club');
    const sessionId = new URLSearchParams(location.search).get('session');
    const drillId = new URLSearchParams(location.search).get('drill');
    const [name,setName] = useState('insert name of drill here');
    const [setup,setSetup] = useState('');
    const [instructions,setinstructions] = useState('');
    const [points,setPoints] = useState('');
    const [fieldSize,setfieldSize] = useState('');
    const [duration,setduration] = useState('');
    const [category,setcategory] = useState('');
    const [previousImageUrl,setpreviousImageUrl] = useState('');
    const [iconsWidthTobeSet,seticonsWidthTobeSet] = useState('5px');
    const [iconsBeingDragged,seticonsBeingDragged] = useState(false);
    const [goalsBeingDragged,setgoalsBeingDragged] = useState(false);
    const [goalsBeingDragged2,setgoalsBeingDragged2] = useState(false);
    
    const [activebody2,setactivebody2] = useState('players');
    const [activeColor,setactiveColor] = useState('black');
    const [activeGroundPath,setactiveGroundPath] = useState('/img/svgexport-2.png');
    const [selectedIconPath,setselectedIconPath] = useState('/img/svgexport-11.png');
    const [cursorSelection,setcursorSelection] = useState(1);
    const [rotation,setRotation] = useState(0);
    const [iconHeight,seticonHeight] = useState(16);
    const [selectedIconId,setselectedIconId] = useState('');
    const [isDragging,setisDragging] = useState(false);
    const [isGroundEntered,setisGroundEntered] = useState(false);
    const [TextValue,setTextValue] = useState('');
    const [arrowEditable,setarrowEditable] = useState(false);
    const [loading,setloading] = useState(true);
    const [zIndexTrigger,setzIndexTrigger] = useState('0');
    setTimeout(() => {
        setloading(false);
    }, 3000);

    const handleRotateArrow = (value) => {
        // console.log(selectedIconId);
        if(arrowEditable){
            if (selectedIconId) {
              const updatedIcons = icons.map((icon) => {
                if (icon.id === selectedIconId) {
                  return { ...icon, rotation: value };
                } else {
                  return icon;
                }
              });
              setRotation(value);
              setIcons(updatedIcons);
            }
        }
    };
    const handleIconHeight = (value) => {
        if(arrowEditable){
            if (selectedIconId) {
              const updatedIcons = icons.map((icon) => {
                if (icon.id === selectedIconId) {
                  return { ...icon, arrowHeight: value };
                } else {
                  return icon;
                }
              });
              seticonHeight(value);
              setIcons(updatedIcons);
        }
        }
    };
    // Field Icons
    const [icons,setIcons] = useState([]);
    const [arrows,setArrows] = useState('');
    const [blackIcons,setblackIcons] = useState([
        {
            path:'/img/black/Player1-01.png',
        },
        {
            path:'/img/black/Player2-01.png',
        },
        {
            path:'/img/black/Player3-01.png',
        },
        {
            path:'/img/black/Player4-01.png',
        },
        {
            path:'/img/black/Player5-01.png',
        },
        {
            path:'/img/black/Player6-01.png',
        },
        {
            path:'/img/black/Player7-01.png',
        },
        {
            path:'/img/black/Player8-01.png',
        },
        {
            path:'/img/black/Player9-01.png',
        },
        {
            path:'/img/black/Player10-01.png',
        },
        {
            path:'/img/black/Player11-01.png',
        },
        {
            path:'/img/black/Player12-01.png',
        },
        {
            path:'/img/black/Player13-01.png',
        },
        {
            path:'/img/black/Player14-01.png',
        },
        {
            path:'/img/black/Player15-01.png',
        },
    ]);
    const [blackIconsShapes,setblackIconsShapes] = useState([
        {
            path:'/img/black/Black-01.png',
        },
        {
            path:'/img/black/Black-02.png',
        },
        {
            path:'/img/black/Black-03.png',
        },
        {
            path:'/img/black/Black-04.png',
        },
        {
            path:'/img/black/Black-05.png',
        },
        {
            path:'/img/black/Black-06.png',
        },
        {
            path:'/img/black/Black-07.png',
        },
        {
            path:'/img/black/Black-08.png',
        },
        {
            path:'/img/black/Black-09.png',
        },
    ]);
    const [yellowIcons,setyellowIcons] = useState([
        {
            path:'/img/yellow/PlayerY1-01.png',
        },
        {
            path:'/img/yellow/PlayerY2-01.png',
        },
        {
            path:'/img/yellow/PlayerY3-01.png',
        },
        {
            path:'/img/yellow/PlayerY4-01.png',
        },
        {
            path:'/img/yellow/PlayerY5-01.png',
        },
        {
            path:'/img/yellow/PlayerY6-01.png',
        },
        {
            path:'/img/yellow/PlayerY7-01.png',
        },
        {
            path:'/img/yellow/PlayerY8-01.png',
        },
        {
            path:'/img/yellow/PlayerY9-01.png',
        },
        {
            path:'/img/yellow/PlayerY10-01.png',
        },
        {
            path:'/img/yellow/PlayerY11-01.png',
        },
        {
            path:'/img/yellow/PlayerY12-01.png',
        },
        {
            path:'/img/yellow/PlayerY13-01.png',
        },
        {
            path:'/img/yellow/PlayerY14-01.png',
        },
        {
            path:'/img/yellow/PlayerY15-01.png',
        },
    ]);
    const [yellowIconsShapes,setyellowIconsShapes] = useState([
        {
            path:'/img/yellow/Yellow-01.png',
        },
        {
            path:'/img/yellow/Yellow-02.png',
        },
        {
            path:'/img/yellow/Yellow-03.png',
        },
        {
            path:'/img/yellow/Yellow-04.png',
        },
        {
            path:'/img/yellow/Yellow-05.png',
        },
        {
            path:'/img/yellow/Yellow-06.png',
        },
        {
            path:'/img/yellow/Yellow-07.png',
        },
        {
            path:'/img/yellow/Yellow-08.png',
        },
        {
            path:'/img/yellow/Yellow-09.png',
        },
    ]);
    const [greenIcons,setgreenIcons] = useState([
        {
            path:'/img/green/PlayerG1-01.png',
        },
        {
            path:'/img/green/PlayerG2-01.png',
        },
        {
            path:'/img/green/PlayerG3-01.png',
        },
        {
            path:'/img/green/PlayerG4-01.png',
        },
        {
            path:'/img/green/PlayerG5-01.png',
        },
        {
            path:'/img/green/PlayerG6-01.png',
        },
        {
            path:'/img/green/PlayerG7-01.png',
        },
        {
            path:'/img/green/PlayerG8-01.png',
        },
        {
            path:'/img/green/PlayerG9-01.png',
        },
        {
            path:'/img/green/PlayerG10-01.png',
        },
        {
            path:'/img/green/PlayerG11-01.png',
        },
        {
            path:'/img/green/PlayerG12-01.png',
        },
        {
            path:'/img/green/PlayerG13-01.png',
        },
        {
            path:'/img/green/PlayerG14-01.png',
        },
        {
            path:'/img/green/PlayerG15-01.png',
        },
    ]);
    const [greenIconsShapes,setgreenIconsShapes] = useState([
        {
            path:'/img/green/Green-01.png',
        },
        {
            path:'/img/green/Green-02.png',
        },
        {
            path:'/img/green/Green-03.png',
        },
        {
            path:'/img/green/Green-04.png',
        },
        {
            path:'/img/green/Green-05.png',
        },
        {
            path:'/img/green/Green-06.png',
        },
        {
            path:'/img/green/Green-07.png',
        },
        {
            path:'/img/green/Green-08.png',
        },
        {
            path:'/img/green/Green-09.png',
        },
    ]);
    const [redIcons,setredIcons] = useState([
        {
            path:'/img/red/PlayerR1-01.png',
        },
        {
            path:'/img/red/PlayerR2-01.png',
        },
        {
            path:'/img/red/PlayerR3-01.png',
        },
        {
            path:'/img/red/PlayerR4-01.png',
        },
        {
            path:'/img/red/PlayerR5-01.png',
        },
        {
            path:'/img/red/PlayerR6-01.png',
        },
        {
            path:'/img/red/PlayerR7-01.png',
        },
        {
            path:'/img/red/PlayerR8-01.png',
        },
        {
            path:'/img/red/PlayerR9-01.png',
        },
        {
            path:'/img/red/PlayerR10-01.png',
        },
        {
            path:'/img/red/PlayerR11-01.png',
        },
        {
            path:'/img/red/PlayerR12-01.png',
        },
        {
            path:'/img/red/PlayerR13-01.png',
        },
        {
            path:'/img/red/PlayerR14-01.png',
        },
        {
            path:'/img/red/PlayerR15-01.png',
        },
    ]);
    const [redIconsShapes,setredIconsShapes] = useState([
        {
            path:'/img/red/Red-01.png',
        },
        {
            path:'/img/red/Red-02.png',
        },
        {
            path:'/img/red/Red-03.png',
        },
        {
            path:'/img/red/Red-04.png',
        },
        {
            path:'/img/red/Red-05.png',
        },
        {
            path:'/img/red/Red-06.png',
        },
        {
            path:'/img/red/Red-07.png',
        },
        {
            path:'/img/red/Red-08.png',
        },
        {
            path:'/img/red/Red-09.png',
        },
    ]);
    const [whiteIcons,setwhiteIcons] = useState([
        {
            path:'/img/white/PlayerW1-01.png',
        },
        {
            path:'/img/white/PlayerW2-01.png',
        },
        {
            path:'/img/white/PlayerW3-01.png',
        },
        {
            path:'/img/white/PlayerW4-01.png',
        },
        {
            path:'/img/white/PlayerW5-01.png',
        },
        {
            path:'/img/white/PlayerW6-01.png',
        },
        {
            path:'/img/white/PlayerW7-01.png',
        },
        {
            path:'/img/white/PlayerW8-01.png',
        },
        {
            path:'/img/white/PlayerW9-01.png',
        },
        {
            path:'/img/white/PlayerW10-01.png',
        },
        {
            path:'/img/white/PlayerW11-01.png',
        },
        {
            path:'/img/white/PlayerW12-01.png',
        },
        {
            path:'/img/white/PlayerW13-01.png',
        },
        {
            path:'/img/white/PlayerW14-01.png',
        },
        {
            path:'/img/white/PlayerW15-01.png',
        },
    ]);
    const [whiteIconsShapes,setwhiteIconsShapes] = useState([
        {
            path:'/img/white/White-01.png',
        },
        {
            path:'/img/white/White-02.png',
        },
        {
            path:'/img/white/White-03.png',
        },
        {
            path:'/img/white/White-04.png',
        },
        {
            path:'/img/white/White-05.png',
        },
        {
            path:'/img/white/White-06.png',
        },
        {
            path:'/img/white/White-07.png',
        },
        {
            path:'/img/white/White-08.png',
        },
        {
            path:'/img/white/White-09.png',
        },
    ]);
    const [nextIconId, setNextIconId] = useState(4);
    const [selectedDraggedIcon, setSelectedDraggedIcon] = useState(null);
    const handleIconDrop = (e) => {
        e.preventDefault();
        setzIndexTrigger('0');
        if (selectedDraggedIcon !== null) {
            // Determine the drop position relative to the `ground-field` div
            const groundField = document.querySelector('.ground-field');
            const rect = groundField.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;

            console.log(selectedDraggedIcon);
            let newIcon;
            if(selectedDraggedIcon==='left'){
                newIcon = {
                    id: nextIconId,
                    iconType: 'arrow-left',
                    color: shapeColor,
                    top: y,
                    left: x,
                    arrowHeight: iconsWidthTobeSet,
                    width: iconsWidthTobeSet,
                    rotation: rotation,
                };
            } else if(selectedDraggedIcon==='text'){
                newIcon = {
                    id: nextIconId,
                    iconType: 'text',
                    textValue: TextValue,
                    color: shapeColor,
                    top: y,
                    left: x,
                    height: iconsWidthTobeSet,
                    width: iconsWidthTobeSet,
                    // rotation: rotation,
                };
            }else{
                newIcon = {
                    id: nextIconId,
                    iconType: 'icon',
                    path: selectedDraggedIcon,
                    top: y,
                    left: x,
                    height: iconsWidthTobeSet,
                    width: iconsWidthTobeSet,
                    highlight: false,                
                };
            }
            
            setIcons([...icons, newIcon]);
            setNextIconId(nextIconId + 1);
            
            // Clear the selectedDraggedIcon state
            setSelectedDraggedIcon(null);
            setselectedIconPath('');
            seticonsBeingDragged(false);
            setgoalsBeingDragged(false);
            setisDragging(false);
            setisGroundEntered(false);
        }
    };
    const handleCursorSelection1 = ()=>{
        setSwitchCanvas(false);
        setzIndexTrigger('0');
        setcursorSelection(1);
        setDrawingMode('');
    }
    const handleCursorSelection2 = ()=>{
        setzIndexTrigger('3');
        setDrawingMode('line');
        setcursorSelection(2);
    }
    const handleCursorSelection3 = ()=>{
        setzIndexTrigger('3');
        setDashed(0);
        setDrawingMode('straightLine');
        setcursorSelection(3);
    }
    const handleCursorSelection4 = ()=>{
        setzIndexTrigger('3');
        setDrawingMode('curveLine');
        setcursorSelection(4);
    }
    const handleCursorSelection5 = ()=>{
        setzIndexTrigger('3');
        setDrawingMode('straightLine');
        setDashed(1);
        setcursorSelection(5);
    }
    const handleCursorSelection6 = ()=>{
        setzIndexTrigger('3');
        setDashed(2);
        setDrawingMode('straightLine');
        setcursorSelection(6);
    }
    const handleCursorSelection7 = ()=>{
        setzIndexTrigger('0');
        setDrawingMode(null);
        setcursorSelection(7);
    }
    const handleCursorSelection8 = ()=>{
        setzIndexTrigger('3');
        setDrawingMode('rectangle');
        setcursorSelection(8);
    }
    const handleCursorSelection9 = ()=>{
        setzIndexTrigger('3');
        setDrawingMode('triangle');
        setcursorSelection(9);
    }
    const handleCursorSelection10 = ()=>{
        setzIndexTrigger('3');
        setDrawingMode('circle');
        setcursorSelection(10);
    }
    const handleCursorSelection11 = ()=>{
        setzIndexTrigger('3');
        setDrawingMode('rectangleFill');
        setcursorSelection(11);
    }
    const [acceptGroundClick,setacceptGroundClick] = useState(false);
    const groundFieldRef = useRef(null);
    const handleGroundClick = (e) => {
        console.log("Ground CLicked");
        // setselectedIconId('');
        // setRotation(0);
        // setActiveDeleteIconIndex(null);
        // if(acceptGroundClick){
        //     const { clientX, clientY } = e;
        //     const icon = {
        //         path: selectedIconPath,
        //         top: clientY - e.currentTarget.getBoundingClientRect().top, // Calculate top position relative to ground-field
        //         left: clientX - e.currentTarget.getBoundingClientRect().left, // Calculate left position relative to ground-field
        //     };
        //     setIcons([...icons, icon]); // Add the new icon to the icons array
        // }        
        if (e.target === groundFieldRef.current) {
            // console.log('Ground Clicked');
            // setActiveDeleteIconIndex(null);
            // setShapeActiveIndex(-1);
            // This means the click occurred on the ground-field background
            // Perform your action here
        }
    };
    // Save positions of dragged elements in Ground Field
    const handleDrag = (e, data, index) => {
        // Update the position of the dragged icon in the icons array
        const updatedIcons = [...icons];
        updatedIcons[index] = {
            ...updatedIcons[index],
            top: data.y,
            left: data.x,
        };
        setIcons(updatedIcons);
        setselectedIconPath('');
    };
    const handleIconClick = (iconPath) => {
        // setSelectedDraggedIcon(iconPath.path);
        setActiveDeleteIconIndex(null);
        setselectedIconPath(iconPath.path);
        setacceptGroundClick(true);
        setcursorSelection(0);
    };
    const handleIconDrag = (iconPath,dragValue) => {
        // console.log(iconPath);
        setisDragging(true);
        setRotation(0);
        seticonHeight(0);
        seticonsWidthTobeSet(dragValue);
        seticonsBeingDragged(true);
        setActiveDeleteIconIndex(null);
        setSelectedDraggedIcon(iconPath.path);
        setacceptGroundClick(false);
        setcursorSelection(1);        
        // setselectedIconPath(iconPath.path);
    };
    const handleTextDrag = (iconPath,dragValue,text) => {
        setisDragging(true);
        setRotation(0);
        seticonHeight(0);
        seticonsWidthTobeSet(dragValue);
        seticonsBeingDragged(true);
        setActiveDeleteIconIndex(null);
        setSelectedDraggedIcon(iconPath.path);
        setacceptGroundClick(false);
        setcursorSelection(1);
        setTextValue(text);
        console.log(text);
    };
    const handlegoalsDrag = (iconPath) => {
        setgoalsBeingDragged(true);
        setActiveDeleteIconIndex(null);
        setSelectedDraggedIcon(iconPath.path);
        setacceptGroundClick(false);
        setcursorSelection(1);
        // setselectedIconPath(iconPath.path);
    };

    const [activeDeleteIconIndex, setActiveDeleteIconIndex] = useState(null);
    
    let iconActiveBG = (path) =>({
        border: selectedIconPath === path ? '1px solid black':'',
    })
    const handleNavCol = ()=>{
        console.log(icons);
        console.log(shapes);
        // console.log(lines);
        // console.log(circles);
        // console.log(shapeActiveIndex);
    }
    const clearDeleteIcons = ()=>{
        setActiveDeleteIconIndex(null);
        setShapeActiveIndex(-1);
    }
    const [shapes, setShapes] = useState([]);
    const [shapeActiveIndex,setShapeActiveIndex] = useState(-1);
    const [shapeColor,setshapeColor] = useState('black');
    const [strokeWidth,setstrokeWidth] = useState(2);
    const [drawingMode, setDrawingMode] = useState(null);
    const [arrow,setArrow] = useState('none');
    const [selectedShape, setSelectedShape] = useState(null);
    const [lines, setLines] = useState([]);
    const [rectangles, setRectangles] = useState([]);
    const [circles, setCircles] = useState([]);
    const [isDrawing, setIsDrawing] = useState(false);
    const [linePoints, setLinePoints] = useState([]);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [endX, setEndX] = useState(0);
    const [endY, setEndY] = useState(0);
    const [dashed,setDashed] = useState(1);
    
    const [hoveredShape, setHoveredShape] = useState(null);
    const handleShapeMouseEnter = (index) => {
      setHoveredShape(index);
    };
    const handleShapeMouseLeave = () => {
      setHoveredShape(null);
    };

    // Storage events
    const handleMouseDown = (e) => {
        setActiveDeleteIconIndex(null);
        // setDrawingMode(null);
        // setShapeActiveIndex(-1);        
        const stage = e.target.getStage();
        const clickedShape = e.target;
        const position = stage.getPointerPosition();
        // console.log(clickedShape);

        if (drawingMode) {
            // If drawing mode is active
            setIsDrawing(true);
            setStartX(position.x);
            setStartY(position.y);
            setEndX(position.x);
            setEndY(position.y);
            setLinePoints([position.x, position.y]);
        } else if (clickedShape) {
          // If a shape is clicked, select it
        //   console.log(clickedShape);
          setSelectedShape(clickedShape);
        //   setShapeActiveIndex(clickedShape.index);
        }
    };
    const handleMouseMove = (e) => {
        if (!isDrawing || !drawingMode) {
          return;
        }

        const stage = e.target.getStage();
        const position = stage.getPointerPosition();
        setEndX(position.x);
        setEndY(position.y);
        const newPoints = linePoints.concat([position.x, position.y]);
        setLinePoints(newPoints);
    };
    const handleMouseUp = () => {
        if (!isDrawing || !drawingMode) {
          return;
        }

        setIsDrawing(false);
        if (drawingMode === 'line') {
            const newLine = {
            iconType: 'line',
            points: linePoints,
            stroke: shapeColor,
            strokeWidth: strokeWidth,
          };
          setShapes([...shapes, newLine]);
          setLines([...lines, newLine]);
        } else if (drawingMode === 'rectangle') {
          const newRect = {
            iconType: 'rectangle',
            x: Math.min(startX, endX),
            y: Math.min(startY, endY),
            width: Math.abs(endX - startX),
            height: Math.abs(endY - startY),
            stroke: shapeColor,
            strokeWidth: strokeWidth,
            // fill: '#00000020',
          };
          setShapes([...shapes, newRect]);
          setRectangles([...rectangles, newRect]); 
        } else if (drawingMode === 'circle') {
          const radius = Math.sqrt(
            Math.pow(endX - startX, 2) + Math.pow(endY - startY, 2)
          );
          const newCircle = {
            iconType: 'circle',
            x: startX + radius,
            y: startY + radius,
            radius,
            stroke: shapeColor,
            strokeWidth: strokeWidth,
          };
          setShapes([...shapes, newCircle]);
          setCircles([...circles, newCircle]);
        } else if (drawingMode === 'straightLine') {
            // Calculate the points for the straight line
            const newStraightLine = {
              iconType: 'straightLine',
              points: [startX, startY, endX, endY],
              stroke: shapeColor,
              dash: dashed===1?[10,10]:dashed===2?[5,5]:false,
              strokeWidth: strokeWidth,
            };
            setShapes([...shapes, newStraightLine]);
        } else if (drawingMode === 'triangle') {
            // Calculate the middle X-coordinate between start and end
            const middleX = (startX + endX) / 2;
            
            // Calculate the height (vertical distance) of the triangle
            const height = endY - startY;
            
            // Define the points for the triangle with a horizontal top edge
            const trianglePoints = [
              middleX - height / 2, startY,  // Left bottom point
              middleX + height / 2, startY,  // Right bottom point
              middleX, startY + height      // Top point
            ];
            
            // Create a new triangle shape with a fill color (e.g., 'blue')
            const newTriangle = {
              iconType: 'triangle',
              points: trianglePoints,
            //   stroke: '#00000020', // Outline color
            };
            
            // Update the shapes state with the new triangle
            setShapes([...shapes, newTriangle]);
        } else{}

        setLinePoints([]);
        // setDrawingMode(null);
    };
    const handleClearShapes = () => {
        setShapes([]);
        setLines([]);
        setRectangles([]);
        setCircles([]);
        setIcons([]);
    };
    const handleShapeClick = (shape,index) => {
        setSelectedShape(shape);
        setShapeActiveIndex(index);
        setDrawingMode(null);
        handleCursorSelection1();
        // console.log('shape clicked');
    };
    const handleRemoveShape = (index) => {
    const newShapes = [...shapes];
    newShapes.splice(index, 1); // Remove the shape at the specified index
    setShapes(newShapes);
    setSelectedShape(null); // Deselect the shape
    setShapeActiveIndex(-1);
    };
    const handleShapeDragEnd = (e, shape, index) => {
    };
    // const handleShapeDragMove = (e, shape, index) => {
//     // Handle drag move if needed (e.g., for real-time updates while dragging)
//     const newX = e.target.x();
//     const newY = e.target.y();
    
//     const updatedShapes = [...shapes];
//     updatedShapes[index] = {
//       ...shape,
//       x: newX,
//       y: newY,
//     };
    
//     setShapes(updatedShapes);
//   };
// const handleShapeDragMove = (e, shape, index) => {
//     if (shape.iconType === 'line') {
//       const stage = e.target.getStage();
//       const position = stage.getPointerPosition();
//       const diffX = position.x - shape.points[0]; // Calculate the difference in X
//       const diffY = position.y - shape.points[1]; // Calculate the difference in Y
  
//       // Update the points of the line by adding the difference
//       const updatedPoints = shape.points.map((point, i) =>
//         i % 2 === 0 ? point + diffX : point + diffY
//       );
  
//       // Update the shapes state with the updated line
//       const updatedShapes = [...shapes];
//       updatedShapes[index] = {
//         ...shape,
//         points: updatedPoints,
//       };
  
//       setShapes(updatedShapes);
//     } else {
//       // Handle dragging for other shapes (rectangles, circles, etc.) as before
//       const newX = e.target.x();
//       const newY = e.target.y();
  
//       const updatedShapes = [...shapes];
//       updatedShapes[index] = {
//         ...shape,
//         x: newX,
//         y: newY,
//       };
  
//       setShapes(updatedShapes);
//     }
//   };
    const handleShapeDragMove = (e, shape, index) => {
    if (shape.iconType === 'line') {
      const stage = e.target.getStage();
      const position = stage.getPointerPosition();
      const diffX = position.x - shape.points[0]; // Calculate the difference in X
      const diffY = position.y - shape.points[1]; // Calculate the difference in Y
  
      // Update the points of the line by adding the difference
      const updatedPoints = shape.points.map((point, i) =>
        i % 2 === 0 ? point + diffX : point + diffY
      );
  
      // Update the shapes state with the updated line
      const updatedShapes = [...shapes];
      updatedShapes[index] = {
        ...shape,
        points: updatedPoints,
      };
  
      setShapes(updatedShapes);
    } else if (shape.iconType === 'straightLine') {
      const stage = e.target.getStage();
      const position = stage.getPointerPosition();
      const diffX = position.x - shape.points[0]; // Calculate the difference in X
      const diffY = position.y - shape.points[1]; // Calculate the difference in Y
  
      // Update the points of the straight line
      const updatedPoints = [
        shape.points[0] + diffX,
        shape.points[1] + diffY,
        shape.points[2] + diffX,
        shape.points[3] + diffY,
      ];
  
      // Update the shapes state with the updated straight line
      const updatedShapes = [...shapes];
      updatedShapes[index] = {
        ...shape,
        points: updatedPoints,
      };
  
      setShapes(updatedShapes);
    } else if (shape.iconType === 'triangle') {
        const stage = e.target.getStage();
        const position = stage.getPointerPosition();
        const diffX = position.x - shape.points[2]; // We use the X-coordinate of the top point
      
        // Update the points of the triangle, maintaining a horizontal top edge
        const updatedPoints = [
          shape.points[0] + diffX, shape.points[1],
          shape.points[2] + diffX, shape.points[3],
          shape.points[4] + diffX, shape.points[5]
        ];
      
        // Update the shapes state with the updated triangle
        const updatedShapes = [...shapes];
        updatedShapes[index] = {
          ...shape,
          points: updatedPoints,
        };
      
        setShapes(updatedShapes);
    } else {
      // Handle dragging for other shapes (rectangles, circles, etc.) as before
      const newX = e.target.x();
      const newY = e.target.y();
  
      const updatedShapes = [...shapes];
      updatedShapes[index] = {
        ...shape,
        x: newX,
        y: newY,
      };
  
      setShapes(updatedShapes);
    }
    };
    const [submitting,setSubmitting] = useState(false);

    const newDrill = async () => {
        setSubmitting(true);
        setzIndexTrigger('0');
        // setloading(true);
        if (groundFieldRef.current) {
            const container = groundFieldRef.current;
            const options = {
                style: {
                  'transform': 'scale(1)', // Adjust as needed
                  'transform-origin': 'top left',
                },
                width: container.offsetWidth,
                height: container.offsetHeight,
            };

            // Use dom-to-image to capture the container
            const dataUrl = await domtoimage.toPng(container, options);
            
            // Convert data URL to Blob
            const blob = await fetch(dataUrl).then((res) => res.blob());
            const formData = new FormData();
      
            // Append the image file to the FormData object
            formData.append('imageFile', blob, 'captured-image.png'); // Set the desired filename
      
            // Append other data to the FormData object if needed
            formData.append('id', userdata._id);
            formData.append('name', name);
            formData.append('setup', setup);
            formData.append('instructions', instructions);
            formData.append('points', points);
            formData.append('fieldSize', fieldSize);
            formData.append('duration', duration);
            formData.append('category', category);
            formData.append('icons', JSON.stringify(icons));
            formData.append('shapes', JSON.stringify(shapes));
            formData.append('activeGroundPath', activeGroundPath);
            console.log(clubId);
            if(isClubNewDrill){
                console.log('New club Drill');
                formData.append('clubId', clubId);
                formData.append('sessionId', sessionId);
    
                const res = await fetch(`${url}newClubDrill`, {
                    method: 'POST',
                  body: formData,
                });
                const data = await res.json();
                setSubmitting(false);
                if (data.msg === 'success') {
                  // setloading(false);
                  console.log('success');
                  getLoggedin();
                  Navigate('/library');
                } else {
                  console.log('failed');
                }
            }else if(isUpdateClubDrill){
                console.log('Update club Drill');
                formData.append('clubId', clubId);
                formData.append('drillId', drillId);
                formData.append('previousImageUrl', previousImageUrl);
    
                const res = await fetch(`${url}updateClubDrill`, {
                    method: 'POST',
                  body: formData,
                });
                const data = await res.json();
                setSubmitting(false);
                if (data.msg === 'success') {
                  // setloading(false);
                  console.log('success');
                  getLoggedin();
                  Navigate('/library');
                } else {
                  console.log('failed');
                }
            }else{
                console.log('saving simple drill');
                const res = await fetch(`${url}newDrill`, {
                  method: 'POST',
                  body: formData,
                });
                const data = await res.json();
                setSubmitting(false);
                if (data.msg === 'success') {
                  // setloading(false);
                  console.log('success');
                  getLoggedin();
                  Navigate('/library');
                } else {
                  console.log('failed');
                }
            }
            // Send the FormData to the backend
          
        }
    };
    // const newDrill = async ()=>{
    //     setSubmitting(true);
    //     const res = await fetch(`${url}newDrill`,{
    //       method: "POST",
    //       headers: {
    //         "Content-Type" : "application/json"
    //       },
    //       body: JSON.stringify({
    //         id: userdata._id,
    //         icons,name,setup,instructions,points,shapes,activeGroundPath
    //       }),
    //     });
    //     setSubmitting(false);
    //     const data = await res.json();

    //     if(data.msg === "success"){
    //         console.log('success');
    //         getLoggedin();
    //         Navigate('/library');
    //     }else{
    //         console.log('failed');
    //     }
    // }
    const [selectedTool, setSelectedTool] = useState('line');
    const [switchCanvas,setSwitchCanvas] = useState(false);

    const handleDownload = () => {
        setzIndexTrigger('0');
        if (groundFieldRef.current) {
      const container = groundFieldRef.current;
  
      // Set the desired options for capturing high-quality images
      const options = {
        scale: 2,     // Increase the scale for higher resolution
        dpi: 300,     // Increase DPI for better quality
        quality: 1,  // Set quality to maximum (for PNG)
        allowTaint: true, // Allow capturing external images (if needed)
      };
  
      // Use html2canvas with the specified options
      html2canvas(container, options).then((canvas) => {
        // Convert the canvas to a Blob
        canvas.toBlob((blob) => {
          // Create a download link and trigger the download
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "downloaded-image.png";
          a.click();
          window.URL.revokeObjectURL(url);
        });
      });
    }
    };
    const handleDownload2 = async () => {
        setzIndexTrigger('0');
      
        if (groundFieldRef.current) {
          const container = groundFieldRef.current;
      
          try {
            // Use html-to-image to capture the container
            const dataUrl = await htmlToImage.toPng(container, { pixelRatio: 2 });
      
            // Convert data URL to Blob
            const blob = await fetch(dataUrl).then((res) => res.blob());
      
            // Create a download link and trigger the download
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "downloaded-image.png";
            a.click();
            window.URL.revokeObjectURL(url);
          } catch (error) {
            console.error('Error capturing image:', error);
          }
        }
    };
    const GoToPrintPage = async () => {
        setSubmitting(true);
        setzIndexTrigger('0');
        setloading(true);
      
        if (groundFieldRef.current) {
          const container = groundFieldRef.current;
      
          try {
            // Use html-to-image to capture the container
            const dataUrl = await htmlToImage.toPng(container, { pixelRatio: 2 });
      
            // Convert data URL to Blob
            const blob = await fetch(dataUrl).then((res) => res.blob());
      
            // Create FormData and append the image file
            const formData = new FormData();
            // Append other data to the FormData object if needed
            formData.append('imageFile', blob, 'captured-image.png'); // Set the desired filename
            // Append other data to the FormData object if needed
            formData.append('id', userdata._id);
            formData.append('name', name);
            formData.append('setup', setup);
            formData.append('instructions', instructions);
            formData.append('points', points);
            formData.append('fieldSize', fieldSize);
            formData.append('duration', duration);
            formData.append('category', category);
            formData.append('icons', JSON.stringify(icons));
            formData.append('shapes', JSON.stringify(shapes));
            formData.append('activeGroundPath', activeGroundPath);
      
            // Send FormData to the backend
            const res = await fetch(`${url}newDrill`, {
              method: 'POST',
              body: formData,
            });
      
            const data = await res.json();
            setSubmitting(false);
            setloading(false);
      
            if (data.msg === 'success') {
              getLoggedin();
              Navigate(`/print-drill/${data.data._id}`);
            } else {
              // Handle failure
            }
          } catch (error) {
            console.error('Error capturing image:', error);
            setSubmitting(false);
            setloading(false);
          }
        }
    };
    const GoToPrintPage2 = async ()=>{
        setSubmitting(true);
        setzIndexTrigger('0');
        setloading(true);
        // setloading(true);
        if (groundFieldRef.current) {
          const container = groundFieldRef.current;
          // Set the desired options for capturing high-quality images
          const options = {
            scale: 2,
            dpi: 300,
            quality: 1,
            allowTaint: true,
          };
          // Use html2canvas with the specified options
          const canvas = await html2canvas(container, options);
          // Convert the canvas to a Blob (file)
          canvas.toBlob(async (blob) => {
            // Create a FormData object to send the image as a file
            const formData = new FormData();
            // Append the image file to the FormData object
            formData.append('imageFile', blob, 'captured-image.png'); // Set the desired filename
            // Append other data to the FormData object if needed
            formData.append('id', userdata._id);
            formData.append('name', name);
            formData.append('setup', setup);
            formData.append('instructions', instructions);
            formData.append('points', points);
            formData.append('fieldSize', fieldSize);
            formData.append('duration', duration);
            formData.append('category', category);
            formData.append('icons', JSON.stringify(icons));
            formData.append('shapes', JSON.stringify(shapes));
            formData.append('activeGroundPath', activeGroundPath);
            // Send the FormData to the backend
            const res = await fetch(`${url}newDrill`, {
              method: 'POST',
              body: formData,
            });
            const data = await res.json();
            setSubmitting(false);
            setloading(false);
            if (data.msg === 'success') {
              // setloading(false);
            //   console.log('success');
              getLoggedin();
            //   console.log(data.data);
              Navigate(`/print-drill/${data.data._id}`);
            } else {
            //   console.log('failed');
            }
          }, 'image/png'); // Specify the desired image format (e.g., 'image/jpeg' for JPEG)
        }
    };
    const GoToPrintPage3 = async () => {
        setSubmitting(true);
        setzIndexTrigger('0');
        setloading(true);
            
        if (groundFieldRef.current) {
          const container = groundFieldRef.current;
          const options = {
            style: {
              'transform': 'scale(1)', // Adjust as needed
              'transform-origin': 'top left',
            },
            width: container.offsetWidth,
            height: container.offsetHeight,
          };

          try {
            // Use dom-to-image to capture the container
            const dataUrl = await domtoimage.toPng(container, options);
            
            // Convert data URL to Blob
            const blob = await fetch(dataUrl).then((res) => res.blob());
            
            // Create FormData and append the image file
            const formData = new FormData();
            // Append other data to the FormData object if needed
            formData.append('imageFile', blob, 'captured-image.png'); // Set the desired filename
            // Append other data to the FormData object if needed
            formData.append('id', userdata._id);
            formData.append('name', name);
            formData.append('setup', setup);
            formData.append('instructions', instructions);
            formData.append('points', points);
            formData.append('fieldSize', fieldSize);
            formData.append('duration', duration);
            formData.append('category', category);
            formData.append('icons', JSON.stringify(icons));
            formData.append('shapes', JSON.stringify(shapes));
            formData.append('activeGroundPath', activeGroundPath);
            if(isClubNewDrill){
                console.log('New club Drill');
                formData.append('clubId', clubId);
                formData.append('sessionId', sessionId);
    
                const res = await fetch(`${url}newClubDrill`, {
                    method: 'POST',
                  body: formData,
                });
                const data = await res.json();
                setSubmitting(false);
                if (data.msg === 'success') {
                  // setloading(false);
                  console.log('success');
                  getLoggedin();
                  Navigate(`/print-club-drill?club=${clubId}&drill=${data.data._id}`);
                } else {
                  console.log('failed');
                }
            }else if(isUpdateClubDrill){
                console.log('Update club Drill');
                formData.append('clubId', clubId);
                formData.append('drillId', drillId);
                formData.append('previousImageUrl', previousImageUrl);
    
                const res = await fetch(`${url}updateClubDrill`, {
                    method: 'POST',
                  body: formData,
                });
                const data = await res.json();
                setSubmitting(false);
                if (data.msg === 'success') {
                  // setloading(false);
                  console.log('success');
                  getLoggedin();
                  Navigate(`/print-club-drill?club=${clubId}&drill=${drillId}`);
                } else {
                  console.log('failed');
                }
            }else{
                console.log('saving simple drill');
                const res = await fetch(`${url}newDrill`, {
                  method: 'POST',
                  body: formData,
                });
                const data = await res.json();
                setSubmitting(false);
                if (data.msg === 'success') {
                    getLoggedin();
                    Navigate(`/print-drill/${data.data._id}`);
                } else {
                  console.log('failed');
                }
            }
          } catch (error) {
            console.error('Error capturing image:', error);
            setSubmitting(false);
            setloading(false);
          }
        }
    };
    const getclubDrillData = async ()=>{
        // setSubmitting(true);
        const res = await fetch(`${url}getClubDrillData`,{
          method: "POST",
          headers: {
            "Content-Type" : "application/json"
          },
          body: JSON.stringify({
            clubId, sessionId, drillId
          }),
        });
        // setSubmitting(false);
        setloading(false);
        const data = await res.json();
        if(data.msg === "success"){
            console.log('success');
            // console.log(data.data);
            setName(data.data.name);
            setinstructions(data.data.instructions);
            setPoints(data.data.points);
            setfieldSize(data.data.fieldSize);
            setduration(data.data.duration);
            setcategory(data.data.category);
            setSetup(data.data.setup);
            setactiveGroundPath(data.data.activeGroundPath);
            setIcons(data.data.icons);
            setShapes(data.data.shapes);
            setpreviousImageUrl(data.data.imageUrl);
        }else{
            console.log('failed');
        }
    }
    useEffect(()=>{
        if(isUpdateClubDrill){
            console.log('Get Update');
            getclubDrillData();
        }else{
            console.log('Dont Get Update');
        }
    },[])

  return (
    <>
        <div className='navbar' onClick={handleNavCol} >
            <div className='navbar-left' >
                <img src='/img/soccer-specific-logo-square.png'/>
                <input
                    type='text'
                    value={name}
                    onChange={(e)=>{setName(e.target.value)}}
                />
            </div>
            <div className='navbar-right' >
                <NavLink to='/library' className='nav-reg-btn' >LIBRARY</NavLink>
                {/* <div className='nav-reg-btn' >
                    <img className='nav-flag' src='/img/flag.png'/>
                </div> */}
                <div className='nav-v-line' ></div>
                <NavLink to='/profile' className='nav-reg-btn' >PROFILE</NavLink>
                <NavLink to='/library' className='nav-reg-btn' >BACK</NavLink>
                <div className='nav-reg-btn' onClick={handleDownload2} >DOWNLOAD</div>
                <div className='nav-reg-btn' onClick={handleClearShapes} >CLEAR</div>
                <div className='nav-reg-btn print-btn' onClick={GoToPrintPage3} >PRINT</div>
                <button className='navbar-save-btn' onClick={newDrill} disabled={submitting} > { submitting?'Saving':'Save' } </button>
            </div>
        </div>
        <div className='home'>
            <div className='device-error' >The browser does not have enough width, should have atleast 1024px.</div>
            <div class="overlay" style={{zIndex: loading?'1':'-1', backgroundColor: loading?'rgb(0 0 0 / 0.6);':'rgba(0, 0, 0, 0.5)'}} ></div>
            <div id="loader" class="nfLoader" style={{ display: loading?'':'none' }} ></div>
            <div className='left-con'>
                <div className='left-con-box left-con-box-mob' style={{marginBottom:'1rem'}}  >
                    <div className='left-con-box-head'>
                        <div className='circle-number'>1</div>
                        <div className='box-title-1'>CHOOSE A FIELD</div>
                    </div>
                    <div className='left-con-box-body'>
                        <div style={{backgroundColor: activeGroundPath==='/img/svgexport-2.png'?'#1bbc1b60':'' }}  ><img className='img-ground' onClick={()=>{setactiveGroundPath('/img/svgexport-2.png')}} src='/img/svgexport-2.png'/></div>
                        <div style={{backgroundColor: activeGroundPath==='/img/svgexport-3.png'?'#1bbc1b60':'' }}  ><img className='img-ground' onClick={()=>{setactiveGroundPath('/img/svgexport-3.png')}} src='/img/svgexport-3.png'/></div>
                        <div style={{backgroundColor: activeGroundPath==='/img/svgexport-4.png'?'#1bbc1b60':'' }}  ><img className='img-ground' onClick={()=>{setactiveGroundPath('/img/svgexport-4.png')}} src='/img/svgexport-4.png'/></div>
                        <div style={{backgroundColor: activeGroundPath==='/img/svgexport-5.png'?'#1bbc1b60':'' }}  ><img className='img-ground' onClick={()=>{setactiveGroundPath('/img/svgexport-5.png')}} src='/img/svgexport-5.png'/></div>
                        <div style={{backgroundColor: activeGroundPath==='/img/svgexport-6.png'?'#1bbc1b60':'' }}  ><img className='img-ground' onClick={()=>{setactiveGroundPath('/img/svgexport-6.png')}} src='/img/svgexport-6.png'/></div>                        
                        <div style={{backgroundColor: activeGroundPath==='/img/svgexport-7.png'?'#1bbc1b60':'' }}  ><img className='img-ground' onClick={()=>{setactiveGroundPath('/img/svgexport-7.png')}} src='/img/svgexport-7.png'/></div>                        
                        <div style={{backgroundColor: activeGroundPath==='/img/svgexport-8.png'?'#1bbc1b60':'' }}  ><img className='img-ground' onClick={()=>{setactiveGroundPath('/img/svgexport-8.png')}} src='/img/svgexport-8.png'/></div>                        
                        <div style={{backgroundColor: activeGroundPath==='/img/svgexport-9.png'?'#1bbc1b60':'' }}  ><img className='img-ground' onClick={()=>{setactiveGroundPath('/img/svgexport-9.png')}} src='/img/svgexport-9.png'/></div>                        
                        <div style={{backgroundColor: activeGroundPath==='/img/svgexport-10.png'?'#1bbc1b60':'' }} ><img className='img-ground' onClick={()=>{setactiveGroundPath('/img/svgexport-10.png')}} src='/img/svgexport-10.png'/></div>                        
                    </div>
                </div>
                <div className='left-con-box left-con-box-mob' style={{paddingBottom: '1rem'}} >
                    <div className='left-con-box-head'>
                        <div className='circle-number'>2</div>
                        <div className='box-title-1'> ADD TO FIELD</div>
                    </div>
                    <div className='left-con-box-body2'>
                        <div className='l-box-body2-con' >
                            <div className='b2-header'>
                                <div onClick={()=>{setactivebody2('players')}} style={{color:activebody2==='players'?'red':''}} >PLAYERS</div>
                                <div onClick={()=>{setactivebody2('goals')}} style={{color:activebody2==='goals'?'red':''}} >GOALS</div>
                                <div onClick={()=>{setactivebody2('equipments')}} style={{color:activebody2==='equipments'?'red':''}} >EQUIPMENT</div>
                                <div onClick={()=>{setactivebody2('text')}} style={{color:activebody2==='text'?'red':''}} >TEXT</div>
                            </div>
                            { activebody2 === 'players' &&
                                <div className='b2-body'>
                                    <div className='color-tab'>
                                        <div>COLORS</div>
                                        <div className='color-box'>
                                            <div style={{backgroundColor: 'black'}} onClick={()=>{setactiveColor('black')}} ></div>
                                            <div style={{backgroundColor: '#ffeb3b'}} onClick={()=>{setactiveColor('yellow')}} ></div>
                                            <div style={{backgroundColor: '#22ee11'}} onClick={()=>{setactiveColor('green')}} ></div>
                                            <div style={{backgroundColor: '#ee2211'}} onClick={()=>{setactiveColor('red')}} ></div>
                                            <div style={{backgroundColor: 'white'}} onClick={()=>{setactiveColor('white')}} ></div>
                                        </div>
                                    </div>
                                        <div className='colored-icons-con' style={{display: activeColor==='black'?'block':'none'}} >
                                            <div className='icons-container'>
                                                {blackIcons.map((iconPath, index) => (
                                                    <div
                                                        className='icons-img-con'
                                                        style={iconActiveBG(iconPath.path)}
                                                        onClick={() => handleIconClick(iconPath)}
                                                        onDragStart={() => handleIconDrag(iconPath,'32px')}
                                                        key={index}
                                                        draggable="true"
                                                    >
                                                        <img src={iconPath.path} alt="Icon" />
                                                    </div>
                                                ))}
                                            </div>
                                            <hr className='box-line' />
                                            <div className='color-tab'>
                                                <div>PLAYER SYMBOLS</div>
                                            </div>
                                            <div className='symbols-icons'>
                                                {blackIconsShapes.map((iconPath, index) => (
                                                    <div
                                                        className='icons-img-con'
                                                        style={iconActiveBG(iconPath.path)}
                                                        onClick={() => handleIconClick(iconPath)}
                                                        onDragStart={() => handleIconDrag(iconPath,'30px')}
                                                        key={index}
                                                        draggable="true"
                                                    >
                                                        <img src={iconPath.path} alt="Icon" />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    
                                        <div className='colored-icons-con' style={{display: activeColor==='yellow'?'block':'none'}}>
                                            <div className='icons-container'>
                                                {yellowIcons.map((iconPath, index) => (
                                                    <div
                                                        className='icons-img-con'
                                                        style={iconActiveBG(iconPath.path)}
                                                        onClick={() => handleIconClick(iconPath)}
                                                        onDragStart={() => handleIconDrag(iconPath,'32px')}
                                                        key={index}
                                                        draggable="true"
                                                    >
                                                        <img src={iconPath.path} alt="Icon" />
                                                    </div>
                                                ))}
                                            </div>
                                            <hr className='box-line' />
                                            <div className='color-tab'>
                                                <div>PLAYER SYMBOLS</div>
                                            </div>
                                            <div className='symbols-icons'>
                                                {yellowIconsShapes.map((iconPath, index) => (
                                                    <div
                                                        className='icons-img-con'
                                                        style={iconActiveBG(iconPath.path)}
                                                        onClick={() => handleIconClick(iconPath)}
                                                        onDragStart={() => handleIconDrag(iconPath,'30px')}
                                                        key={index}
                                                        draggable="true"
                                                    >
                                                        <img src={iconPath.path} alt="Icon" />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>                                        
                                    
                                        <div className='colored-icons-con' style={{display: activeColor==='green'?'block':'none'}}>
                                            <div className='icons-container'>
                                                {greenIcons.map((iconPath, index) => (
                                                    <div
                                                        className='icons-img-con'
                                                        style={iconActiveBG(iconPath.path)}
                                                        onClick={() => handleIconClick(iconPath)}
                                                        onDragStart={() => handleIconDrag(iconPath,'32px')}
                                                        key={index}
                                                        draggable="true"
                                                    >
                                                        <img src={iconPath.path} alt="Icon" />
                                                    </div>
                                                ))}
                                            </div>
                                            <hr className='box-line' />
                                            <div className='color-tab'>
                                                <div>PLAYER SYMBOLS</div>
                                            </div>
                                            <div className='symbols-icons'>
                                                {greenIconsShapes.map((iconPath, index) => (
                                                    <div
                                                        className='icons-img-con'
                                                        style={iconActiveBG(iconPath.path)}
                                                        onClick={() => handleIconClick(iconPath)}
                                                        onDragStart={() => handleIconDrag(iconPath,'30px')}
                                                        key={index}
                                                        draggable="true"
                                                    >
                                                        <img src={iconPath.path} alt="Icon" />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>                                        
                                    
                                        <div className='colored-icons-con' style={{display: activeColor==='red'?'block':'none'}}>
                                            <div className='icons-container'>
                                                {redIcons.map((iconPath, index) => (
                                                    <div
                                                        className='icons-img-con'
                                                        style={iconActiveBG(iconPath.path)}
                                                        onClick={() => handleIconClick(iconPath)}
                                                        onDragStart={() => handleIconDrag(iconPath,'32px')}
                                                        key={index}
                                                        draggable="true"
                                                    >
                                                        <img src={iconPath.path} alt="Icon" />
                                                    </div>
                                                ))}
                                            </div>
                                            <hr className='box-line' />
                                            <div className='color-tab'>
                                                <div>PLAYER SYMBOLS</div>
                                            </div>
                                            <div className='symbols-icons'>
                                                {redIconsShapes.map((iconPath, index) => (
                                                    <div
                                                        className='icons-img-con'
                                                        style={iconActiveBG(iconPath.path)}
                                                        onClick={() => handleIconClick(iconPath)}
                                                        onDragStart={() => handleIconDrag(iconPath,'30px')}
                                                        key={index}
                                                        draggable="true"
                                                    >
                                                        <img src={iconPath.path} alt="Icon" />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>                                        

                                        <div className='colored-icons-con' style={{display: activeColor==='white'?'block':'none'}}>
                                            <div className='icons-container'>
                                                {whiteIcons.map((iconPath, index) => (
                                                    <div
                                                        className='icons-img-con'
                                                        style={iconActiveBG(iconPath.path)}
                                                        onClick={() => handleIconClick(iconPath)}
                                                        onDragStart={() => handleIconDrag(iconPath,'32px')}
                                                        key={index}
                                                        draggable="true"
                                                    >
                                                        <img src={iconPath.path} alt="Icon" />
                                                    </div>
                                                ))}
                                            </div>
                                            <hr className='box-line' />
                                            <div className='color-tab'>
                                                <div>PLAYER SYMBOLS</div>
                                            </div>
                                            <div className='symbols-icons'>
                                                {whiteIconsShapes.map((iconPath, index) => (
                                                    <div
                                                        className='icons-img-con'
                                                        style={iconActiveBG(iconPath.path)}
                                                        onClick={() => handleIconClick(iconPath)}
                                                        onDragStart={() => handleIconDrag(iconPath,'30px')}
                                                        key={index}
                                                        draggable="true"
                                                    >
                                                        <img style={{backgroundColor:'#c8c8c8'}} src={iconPath.path} alt="Icon" />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>                                        
                                    
                                </div>
                            }
                            { activebody2 === 'goals' &&
                                <div className='b2-body'>
                                    <div className='goals-con'>
                                        <div
                                            className='goals-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '10px',
                                                left: '5.3rem'
                                            }}
                                            onClick={() => handleIconClick('/img/goals/goal-1.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/goals/goal-1.png'} ,'29px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/goals/goal-1.png'} alt="Icon" style={{height:'29px'}} />
                                        </div>
                                        <div
                                            className='goals-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '29px',
                                                left: '9rem'
                                            }}
                                            onClick={() => handleIconClick('/img/goals/goal-2.png')}
                                            onDragStart={() => handleIconDrag({path: '/img/goals/goal-2.png'},'3rem')}
                                            // key={index}
                                            draggable="true"                                            
                                        >
                                            <img src={'/img/goals/goal-2.png'} alt="Icon" style={{height:'3rem'}} />
                                        </div>
                                        <div
                                            className='goals-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '87px',
                                                left: '11rem'
                                            }}
                                            onClick={() => handleIconClick('/img/goals/goal-3.png')}
                                            onDragStart={() => handleIconDrag({path: '/img/goals/goal-3.png'},'3rem')}
                                            // key={index}
                                            draggable="true"                                            
                                        >
                                            <img src={'/img/goals/goal-3.png'} alt="Icon" style={{height:'3rem'}} />
                                        </div>
                                        <div
                                            className='goals-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '143px',
                                                left: '9rem'
                                            }}
                                            onClick={() => handleIconClick('/img/goals/goal-4.png')}
                                            onDragStart={() => handleIconDrag({path: '/img/goals/goal-4.png'},'3rem')}
                                            // key={index}
                                            draggable="true"                                            
                                        >
                                            <img src={'/img/goals/goal-4.png'} alt="Icon" style={{height:'3rem'}} />
                                        </div>
                                        <div
                                            className='goals-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '171px',
                                                left: '5.4rem'
                                            }}
                                            onClick={() => handleIconClick('/img/goals/goal-5.png')}
                                            onDragStart={() => handleIconDrag({path: '/img/goals/goal-5.png'},'29px')}
                                            // key={index}
                                            draggable="true"                                            
                                        >
                                            <img src={'/img/goals/goal-5.png'} alt="Icon" style={{height:'29px'}} />
                                        </div>
                                        <div
                                            className='goals-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '143px',
                                                left: '1.9rem'
                                            }}
                                            onClick={() => handleIconClick('/img/goals/goal-6.png')}
                                            onDragStart={() => handleIconDrag({path: '/img/goals/goal-6.png'},'3rem')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/goals/goal-6.png'} alt="Icon" style={{height:'3rem'}} />
                                        </div>

                                        <div
                                            className='goals-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '87px',
                                                left: '1rem'
                                            }}
                                            onClick={() => handleIconClick('/img/goals/goal-7.png')}
                                            onDragStart={() => handleIconDrag({path: '/img/goals/goal-7.png'},'3rem')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/goals/goal-7.png'} alt="Icon" style={{height:'3rem'}} />
                                        </div>
                                        <div
                                            className='goals-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '29px',
                                                left: '1.5rem'
                                            }}
                                            onClick={() => handleIconClick('/img/goals/goal-8.png')}
                                            onDragStart={() => handleIconDrag({path: '/img/goals/goal-8.png'},'3rem')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/goals/goal-8.png'} alt="Icon" style={{height:'3rem'}} />
                                        </div>
                                        <div
                                            className='goals-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '220px',
                                                left: '1rem'
                                            }}
                                            onClick={() => handleIconClick('/img/goals/goal-9.png')}
                                            onDragStart={() => handleIconDrag({path: '/img/goals/goal-9.png'},'80px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/goals/goal-9.png'} alt="Icon" style={{height:'80px'}} />
                                        </div>
                                        <div
                                            className='goals-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '220px',
                                                left: '3.7rem'
                                            }}
                                            onClick={() => handleIconClick('/img/goals/goal-10.png')}
                                            onDragStart={() => handleIconDrag({path: '/img/goals/goal-10.png'},'80px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/goals/goal-10.png'} alt="Icon" style={{height:'80px'}} />
                                        </div>
                                        <div
                                            className='goals-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '220px',
                                                left: '6.5rem'
                                            }}
                                            onClick={() => handleIconClick('/img/goals/goal-11.png')}
                                            onDragStart={() => handleIconDrag({path: '/img/goals/goal-11.png'},'45px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/goals/goal-11.png'} alt="Icon" style={{height:'45px'}} />
                                        </div>
                                        <div
                                            className='goals-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '270px',
                                                left: '6.5rem'
                                            }}
                                            onClick={() => handleIconClick('/img/goals/goal-12.png')}
                                            onDragStart={() => handleIconDrag({path: '/img/goals/goal-12.png'},'52px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/goals/goal-12.png'} alt="Icon" style={{height:'52px'}} />
                                        </div>
                                    </div>
                                </div>
                            }
                            { activebody2 === 'equipments' &&
                                <div className='b2-body' style={{padding:'10px 8px'}} >
                                    <div className='equipment-con' >                                        
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '0px',
                                                left: '0px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/1.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/1.png'} ,'29px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/1.png'} alt="Icon" style={{height:'35px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '0px',
                                                left: '22px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/2.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/2.png'} ,'29px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/2.png'} alt="Icon" style={{height:'35px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '42px',
                                                left: '5px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/3.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/3.png'} ,'21px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/3.png'} alt="Icon" style={{height:'21px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '44px',
                                                left: '27px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/4.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/4.png'} ,'21px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/4.png'} alt="Icon" style={{height:'21px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '10px',
                                                left: '60px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/5.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/5.png'} ,'22px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/5.png'} alt="Icon" style={{height:'22px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '44px',
                                                left: '55px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/6.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/6.png'} ,'47px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/6.png'} alt="Icon" style={{height:'47px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '48px',
                                                left: '112px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/43.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/43.png'} ,'30px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/43.png'} alt="Icon" style={{height:'30px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '48px',
                                                left: '83px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/44.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/44.png'} ,'30px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/44.png'} alt="Icon" style={{height:'30px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '100px',
                                                left: '0px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/7.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/7.png'} ,'45px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/7.png'} alt="Icon" style={{height:'45px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '100px',
                                                left: '26px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/8.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/8.png'} ,'45px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/8.png'} alt="Icon" style={{height:'45px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '100px',
                                                left: '52px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/9.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/9.png'} ,'45px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/9.png'} alt="Icon" style={{height:'45px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '100px',
                                                left: '79px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/10.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/10.png'} ,'45px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/10.png'} alt="Icon" style={{height:'45px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '100px',
                                                left: '105px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/11.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/11.png'} ,'45px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/11.png'} alt="Icon" style={{height:'45px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '157px',
                                                left: '0px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/12.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/12.png'} ,'36px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/12.png'} alt="Icon" style={{height:'36px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '203px',
                                                left: '0px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/13.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/13.png'} ,'46px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/13.png'} alt="Icon" style={{height:'46px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '203px',
                                                left: '39px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/14.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/14.png'} ,'46px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/14.png'} alt="Icon" style={{height:'46px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '203px',
                                                left: '85px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/15.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/15.png'} ,'46px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/15.png'} alt="Icon" style={{height:'46px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '0px',
                                                left: '240px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/16.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/16.png'} ,'18px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/16.png'} alt="Icon" style={{height:'18px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '23px',
                                                left: '240px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/17.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/17.png'} ,'18px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/17.png'} alt="Icon" style={{height:'18px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '47px',
                                                left: '240px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/18.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/18.png'} ,'18px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/18.png'} alt="Icon" style={{height:'18px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '70px',
                                                left: '240px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/19.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/19.png'} ,'18px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/19.png'} alt="Icon" style={{height:'18px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '95px',
                                                left: '240px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/20.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/20.png'} ,'18px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/20.png'} alt="Icon" style={{height:'18px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '120px',
                                                left: '240px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/21.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/21.png'} ,'18px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/21.png'} alt="Icon" style={{height:'18px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '0px',
                                                left: '220px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/22.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/22.png'} ,'11px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/22.png'} alt="Icon" style={{height:'11px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '20px',
                                                left: '220px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/23.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/23.png'} ,'11px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/23.png'} alt="Icon" style={{height:'11px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '40px',
                                                left: '220px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/24.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/24.png'} ,'11px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/24.png'} alt="Icon" style={{height:'11px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '60px',
                                                left: '220px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/25.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/25.png'} ,'11px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/25.png'} alt="Icon" style={{height:'11px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '80px',
                                                left: '220px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/26.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/26.png'} ,'11px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/26.png'} alt="Icon" style={{height:'11px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '100px',
                                                left: '220px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/27.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/27.png'} ,'11px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/27.png'} alt="Icon" style={{height:'11px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '120px',
                                                left: '220px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/28.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/28.png'} ,'11px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/28.png'} alt="Icon" style={{height:'11px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '140px',
                                                left: '220px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/29.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/29.png'} ,'11px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/29.png'} alt="Icon" style={{height:'11px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '0px',
                                                left: '180px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/30.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/30.png'} ,'12px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/30.png'} alt="Icon" style={{height:'12px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '22px',
                                                left: '182px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/31.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/31.png'} ,'24px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/31.png'} alt="Icon" style={{height:'24px'}} />
                                        </div>                                        
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '50px',
                                                left: '175px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/32.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/32.png'} ,'11px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/32.png'} alt="Icon" style={{height:'11px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '70px',
                                                left: '182px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/33.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/33.png'} ,'24px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/33.png'} alt="Icon" style={{height:'24px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '100px',
                                                left: '150px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/34.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/34.png'} ,'15px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/34.png'} alt="Icon" style={{height:'15px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '124px',
                                                left: '165px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/35.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/35.png'} ,'40px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/35.png'} alt="Icon" style={{height:'40px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '170px',
                                                left: '163px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/36.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/36.png'} ,'40px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/36.png'} alt="Icon" style={{height:'40px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '165px',
                                                left: '220px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/37.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/37.png'} ,'14px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/37.png'} alt="Icon" style={{height:'14px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '190px',
                                                left: '220px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/38.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/38.png'} ,'40px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/38.png'} alt="Icon" style={{height:'40px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '236px',
                                                left: '220px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/39.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/39.png'} ,'40px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/39.png'} alt="Icon" style={{height:'40px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '165px',
                                                left: '95px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/40.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/40.png'} ,'16px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/40.png'} alt="Icon" style={{height:'16px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '207px',
                                                left: '137px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/41.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/41.png'} ,'40px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/41.png'} alt="Icon" style={{height:'40px'}} />
                                        </div>
                                        <div
                                            className='equipment-img-con'
                                            // style={iconActiveBG(iconPath.path)}
                                            style={{
                                                top: '207px',
                                                left: '185px'
                                            }}
                                            onClick={() => handleIconClick('/img/equipments/42.png')}
                                            onDragStart={() => handleIconDrag( {path: '/img/equipments/42.png'} ,'40px')}
                                            // key={index}
                                            draggable="true"
                                        >
                                            <img src={'/img/equipments/42.png'} alt="Icon" style={{height:'40px'}} />
                                        </div>

                                    </div>                            
                                </div>
                            }
                            { activebody2 === 'text' &&
                                <div className='b2-body'>
                                    <div className='text-con'>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','1')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >1</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','2')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >2</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','3')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >3</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','4')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >4</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','5')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >5</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','6')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >6</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','7')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >7</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','8')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >8</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','9')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >9</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','A')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >A</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','B')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >B</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','C')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >C</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','D')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >D</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','E')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >E</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','F')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >F</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','G')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >G</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','H')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >H</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','I')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >I</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','J')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >J</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','K')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >K</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','L')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >L</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','M')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >M</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','N')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >N</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','O')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >O</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','P')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >P</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','Q')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >Q</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','R')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >R</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','S')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >S</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','T')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >T</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','U')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >U</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','V')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >V</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','W')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >W</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','X')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >X</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','Y')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >Y</div>
                                        </div>
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','Z')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >Z</div>
                                        </div>
                                    </div>
                                    <div className='flex mt10' >
                                        <div
                                            className='text-img-con'
                                            onDragStart={() => handleTextDrag( {path: 'text'} ,'10px','Text')}
                                            draggable="true"
                                        >
                                            <div style={{color: shapeColor}} >TEXT</div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='mid-con'>
                <div 
                    // className='ground-field'
                    className={`ground-field${isGroundEntered && isDragging ? ' mouse-entered' : ''}`}
                    style={{
                        backgroundImage: `url(${activeGroundPath})`,
                        cursor: cursorSelection === 8 ? 'crosshair': cursorSelection === 11 ? 'crosshair':'default',
                        border: isDragging && isGroundEntered ?'2px solid white':''
                    }}
                    onDrop={handleIconDrop}
                    onDragOver={(e) => e.preventDefault()}
                    ref={groundFieldRef}
                    onClick={(e) => handleGroundClick(e)}
                    onMouseEnter={()=>{setisGroundEntered(true)}}
                >
                    {/* <img src={activeGroundPath} alt="Field" /> */}
                    {icons.map((icon, index) => {
                        return(<>
                            <DraggableIcon
                                key={icon.id}
                                icon={icon}
                                index={index}
                                handleCursorSelection1={handleCursorSelection1}
                                handleDrag={handleDrag}
                                cursorSelection={cursorSelection}
                                showDeleteIcon={activeDeleteIconIndex === index}
                                setActiveDeleteIconIndex={setActiveDeleteIconIndex}
                                icons={icons}
                                setIcons={setIcons}
                                setShapeActiveIndex={setShapeActiveIndex}
                                setselectedIconId={setselectedIconId}
                                setRotation={setRotation}
                                seticonHeight={seticonHeight}
                                arrowEditable={arrowEditable}
                                setarrowEditable={setarrowEditable}
                            />
                        </>)
                    })}
                    <Stage
                      width='600'
                      height='450'
                      onMouseDown={handleMouseDown}
                      onMouseMove={handleMouseMove}
                      onMouseUp={handleMouseUp}
                      style={{position:'absolute',zIndex: zIndexTrigger }}
                        // className='z3'
                    >
                      <Layer>
                        {shapes.map((shape, index) => {
                        const isHovered = hoveredShape === index;
                        const hoverWidthOffset = isHovered ? 3 : 0;
                        if (shape.iconType === 'rectangle') {
                            return (
                               <Group key={index}>
                                    <Rect
                                        className='shape-hover'
                                        key={index}
                                        x={shape.x}
                                        y={shape.y}
                                        width={shape.width}
                                        height={shape.height}
                                        stroke={shape.stroke}
                                        strokeWidth={shape.strokeWidth}
                                        draggable
                                        onMouseDown={() => handleShapeClick(shape,index)}
                                        onDragEnd={(e) => handleShapeDragEnd(e, shape, index)}
                                        onDragMove={(e) => handleShapeDragMove(e, shape, index)}                                         
                                    />
                                    { shapeActiveIndex === index &&
                                        <Text
                                          x={shape.x + shape.width - 12}
                                          y={shape.y}
                                          text="X"
                                          fontSize={16}
                                          fill="white"
                                          onClick={() => handleRemoveShape(index)}
                                        />
                                    }
                                </Group>
                            );
                        } else if (shape.iconType === 'circle') {
                            return (
                            <Group key={index}>
                              <Circle
                                className='shape-hover'
                                key={index}
                                x={shape.x}
                                y={shape.y}
                                radius={shape.radius}
                                stroke={shape.stroke}
                                strokeWidth={shape.strokeWidth}
                                draggable
                                onMouseDown={() => handleShapeClick(shape, index)}
                                onDragEnd={(e) => handleShapeDragEnd(e, shape, index)}
                                onDragMove={(e) => handleShapeDragMove(e, shape, index)}
                              />
                              {shapeActiveIndex === index && (
                                <Text
                                  x={shape.x + shape.radius - 12}
                                  y={shape.y}
                                  text='X'
                                  fontSize={16}
                                  fill='white'
                                  onClick={() => handleRemoveShape(index)}
                                />
                              )}
                            </Group>
                        );
                        } else if (shape.iconType === 'line') {
                          return (
                            <Group key={index}>
                              <Line
                                points={shape.points}
                                stroke={shape.stroke}
                                strokeWidth={shape.strokeWidth + hoverWidthOffset}
                                draggable
                                onMouseDown={() => handleShapeClick(shape, index)}
                                onDragEnd={(e) => handleShapeDragEnd(e, shape, index)}
                                onDragMove={(e) => handleShapeDragMove(e, shape, index)}
                                onMouseEnter={() => handleShapeMouseEnter(index)} // Handle mouse enter
                                onMouseLeave={handleShapeMouseLeave}
                              />
                              {shapeActiveIndex === index && (
                                <Text
                                  x={shape.points[0] } // Adjust the X position of the text
                                  y={shape.points[1] } // Adjust the Y position of the text
                                  text='X'
                                  fontSize={16}
                                  fill='white'
                                  onClick={() => handleRemoveShape(index)}
                                />
                              )}
                            </Group>
                          );
                        } else if (shape.iconType === 'straightLine') {
                          return (
                            <Group key={index}>
                              <Line
                                points={shape.points}
                                stroke={shape.stroke}
                                dash={shape.dash}
                                strokeWidth={shape.strokeWidth + hoverWidthOffset}
                                draggable
                                onMouseDown={() => handleShapeClick(shape, index)}
                                onDragEnd={(e) => handleShapeDragEnd(e, shape, index)}
                                onDragMove={(e) => handleShapeDragMove(e, shape, index)}
                                onMouseEnter={() => handleShapeMouseEnter(index)} // Handle mouse enter
                                onMouseLeave={handleShapeMouseLeave}
                              />
                              {shapeActiveIndex === index && (
                                <Text
                                  x={shape.points[0] + 5}
                                  y={shape.points[1] - 20}
                                  text='X'
                                  fontSize={16}
                                  fill='white'
                                  onClick={() => handleRemoveShape(index)}
                                />
                              )}
                            </Group>
                          );
                        } else if (shape.iconType === 'triangle') {
                          return (
                            <Group key={index}>
                              <Line
                                points={shape.points} // Specify three points to define the triangle
                                closed
                                stroke={shape.stroke}
                                fill = '#00000020'
                                draggable
                                onMouseDown={() => handleShapeClick(shape, index)}
                                onDragEnd={(e) => handleShapeDragEnd(e, shape, index)}
                                onDragMove={(e) => handleShapeDragMove(e, shape, index)}
                              />
                              {shapeActiveIndex === index && (
                                <Text
                                  x={shape.points[0] + 5}
                                  y={shape.points[1] - 20}
                                  text='X'
                                  fontSize={16}
                                  fill='white'
                                  onClick={() => handleRemoveShape(index)}
                                />
                              )}
                            </Group>
                          );
                        }
                          return null;
                        })}
                        {selectedShape && (
                          <Transformer
                            selectedShape={selectedShape}
                            setSelectedShape={setSelectedShape}
                          />
                        )}
                      </Layer>
                    </Stage>
                </div>
                <div className='left-con-box width-5'  >
                    <div className='left-con-box-head'>
                        <div className='circle-number'>4</div>
                        <div className='box-title-1'>TYPE IT UP</div>
                    </div>
                    <div className='mid-body2'>
                        <div className='tiu-grid-2' >
                            <div className='mid-b2-title'>Setup</div>
                            <textarea type='text' value={setup} onChange={(e)=>{setSetup(e.target.value)}} className='mid-b2-input' />
                        </div>
                        <div className='tiu-grid-2' >
                            <div className='mid-b2-title'>Instructions</div>
                            <textarea type='text' value={instructions} onChange={(e)=>{setinstructions(e.target.value)}} className='mid-b2-input' />
                        </div>
                        <div className='tiu-grid-2' >
                            <div className='mid-b2-title'>Coaching Points</div>
                            <textarea type='text' value={points} onChange={(e)=>{setPoints(e.target.value)}} className='mid-b2-input' />
                        </div>
                        <div className='tiu-grid-3' >
                            <div>
                                <div className='mid-b2-title'>Others</div>
                            </div>
                            <div>
                                <div className='mid-b2-title'>Field Size</div>
                                <input type='text' value={fieldSize} onChange={(e)=>{setfieldSize(e.target.value)}} className='mid-b2-input' />
                            </div>
                            <div>
                                <div className='mid-b2-title'>Duration</div>
                                <input type='text' value={duration} onChange={(e)=>{setduration(e.target.value)}} className='mid-b2-input' />
                            </div>
                            <div>
                                <div className='mid-b2-title'>Category</div>
                                <select className='tiu-category-option' value={category} onChange={(e)=>{setcategory(e.target.value)}}>
                                    <option value="">Select</option>
                                    <option value="warm-up">Warm Up</option>
                                    <option value="arrival-activity">Arrival Activity</option>
                                    <option value="recognition-game">Recognition Game</option>
                                    <option value="intervention-skill-breakdown">Intervention: Skill Breakdown</option>
                                    <option value="evaluation-game">Evaluation: Game</option>
                                    <option value="rondo">Rondo</option>
                                    <option value="passing-practice">Passing Practice</option>
                                    <option value="possession-game">Possession Game</option>
                                    <option value="game-training">Game Training</option>
                                    <option value="training-game">Training Game</option>
                                    <option value="tactical-component">Tactical Component</option>
                                </select>
                            </div>
                        </div>
                        <div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='right-con'>
                <div className='left-con-box mob-width' style={{marginBottom:'1rem'}}  >
                    <div className='left-con-box-head'>
                        <div className='circle-number'>3</div>
                        <div className='box-title-1'> SELECT AND DRAW </div>
                    </div>
                    <div className='right-con-box-body'>
                        <div className='selection-elem' onClick={handleCursorSelection1} style={{backgroundColor: cursorSelection===1?'#a2a2a24c':'' }} >
                            <div className='right-con-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M0 55.2V426c0 12.2 9.9 22 22 22c6.3 0 12.4-2.7 16.6-7.5L121.2 346l58.1 116.3c7.9 15.8 27.1 22.2 42.9 14.3s22.2-27.1 14.3-42.9L179.8 320H297.9c12.2 0 22.1-9.9 22.1-22.1c0-6.3-2.7-12.3-7.4-16.5L38.6 37.9C34.3 34.1 28.9 32 23.2 32C10.4 32 0 42.4 0 55.2z"/></svg>
                            </div>
                            <div className='selection-title'>SELECT & DRAG</div>
                        </div>
                        <div className='selection-elem' onClick={handleCursorSelection2} style={{backgroundColor: cursorSelection===2?'#a2a2a24c':'' }} >
                            <div className='right-con-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/></svg>                            
                            </div>
                            <div className='selection-title'>PEN</div>
                        </div>
                        <div className='selection-elem' onClick={handleCursorSelection3} style={{backgroundColor: cursorSelection===3?'#a2a2a24c':'' }} >
                            <div className='right-con-icon'>
                                <div className='line-path-i-con'>
                                    <div></div>
                                </div>                                
                            </div>
                            <div className='selection-title'>PLAYER PATH</div>
                        </div>
                        {/* <div className='selection-elem' onClick={handleCursorSelection4} style={{backgroundColor: cursorSelection===4?'#a2a2a24c':'' }} >
                            <div className='right-con-icon'>
                                <div className='player-path2-i-con'>
                                    <div></div>
                                </div> 
                            </div>
                            <div className='selection-title'>PLAYER PATH</div>
                        </div> */}
                        <div className='selection-elem' onClick={handleCursorSelection5} style={{backgroundColor: cursorSelection===5?'#a2a2a24c':'' }} >
                            <div className='right-con-icon'>
                                <div className='ball-path-i-con'>
                                    <div></div>
                                </div>                               
                            </div>
                            <div className='selection-title'>BALL PATH</div>
                        </div>
                        <div className='selection-elem' onClick={handleCursorSelection6} style={{backgroundColor: cursorSelection===6?'#a2a2a24c':'' }} >
                            <div className='right-con-icon'>
                                <div className='dribble-path-i-con'>
                                    <div></div>
                                </div> 
                            </div>
                            <div className='selection-title'>DRIBBLE</div>
                        </div>
                        <div className='selection-elem' onClick={handleCursorSelection7} style={{backgroundColor: cursorSelection===7?'#a2a2a24c':'' }} >
                            <div className='right-con-icon'>
                                <div className='highlight-i-con'>
                                    <div></div>
                                </div> 
                            </div>
                            <div className='selection-title'>HIGHLIGHT</div>
                        </div>
                        <div className='selection-elem' onClick={handleCursorSelection8} style={{backgroundColor: cursorSelection===8?'#a2a2a24c':'' }} >
                            <div className='right-con-icon'>
                                <div className='rectangle-i-con'>
                                    <div></div>
                                </div> 
                            </div>
                            <div className='selection-title'>RECTANGLE</div>
                        </div>
                        <div className='selection-elem' onClick={handleCursorSelection9} style={{backgroundColor: cursorSelection===9?'#a2a2a24c':'' }} >
                            <div className='right-con-icon'>
                            <svg xmlns="http://www.w3.org/2000/svg" style={{transform:'rotate(30deg)'}} height="1em" viewBox="0 0 384 512"><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>                            
                            </div>
                            <div className='selection-title'>TRIANGLE</div>
                        </div>
                        <div className='selection-elem' onClick={handleCursorSelection10} style={{backgroundColor: cursorSelection===10?'#a2a2a24c':'' }} >
                            <div className='right-con-icon'>
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/></svg>
                            </div>
                            <div className='selection-title'>CIRCLE</div>
                        </div>
                        {/* <div className='selection-elem' onClick={handleCursorSelection11} style={{backgroundColor: cursorSelection===11?'#a2a2a24c':'' }} >
                            <div className='right-con-icon'>
                                <div className='rectangle-fill-i-con'>
                                    <div></div>
                                </div> 
                            </div>
                            <div className='selection-title'>SHAPE</div>
                        </div> */}
                        <div className='selection-elem-thickness' style={{margin:'0.7rem 0', height:'3rem'}} >
                            <div className='color-tab2 color-tab-mob'>
                                <div className='selection-title'>COLORS</div>
                                <div className='color-box color-mob'>
                                    <div style={{backgroundColor: 'black', border: shapeColor==='black'?'2px solid #c6c6c6':'' }} onClick={()=>{setshapeColor('black')}} ></div>
                                    <div style={{backgroundColor: '#ffeb3b', border: shapeColor==='#ffeb3b'?'2px solid #c6c6c6':'' }} onClick={()=>{setshapeColor('#ffeb3b')}} ></div>
                                    <div style={{backgroundColor: '#22ee11', border: shapeColor==='#22ee11'?'2px solid #c6c6c6':'' }} onClick={()=>{setshapeColor('#22ee11')}} ></div>
                                    <div style={{backgroundColor: '#ee2211', border: shapeColor==='#ee2211'?'2px solid #c6c6c6':'' }} onClick={()=>{setshapeColor('#ee2211')}} ></div>
                                    <div style={{backgroundColor: 'white', border: shapeColor==='white'?'2px solid #c6c6c6':'' }} onClick={()=>{setshapeColor('white')}} ></div>
                                </div>
                                <input type='color' className='color-picker' value={shapeColor} onChange={(e)=>{setshapeColor(e.target.value)}} />
                                
                            </div>
                        </div>
                        <div className='selection-elem-thickness' style={{margin:'0.7rem 0', height:'3rem'}} >
                            <div className='color-tab2'>
                                <div className='selection-title'>LINE THICKNESS</div>
                                <div className='thickness-lines'>
                                    <div className='lines-bg' style={{backgroundColor: strokeWidth===1?'#a2a2a24c':''}} onClick={()=>{setstrokeWidth(1)}} ><div className='tl1'></div></div>
                                    <div className='lines-bg' style={{backgroundColor: strokeWidth===2?'#a2a2a24c':''}} onClick={()=>{setstrokeWidth(2)}} ><div className='tl2'></div></div>
                                    <div className='lines-bg' style={{backgroundColor: strokeWidth===4?'#a2a2a24c':''}} onClick={()=>{setstrokeWidth(4)}} ><div className='tl3'></div></div>                                    
                                </div>
                            </div>
                        </div>
                        <div className='selection-elem-ah' >
                            <div className='selection-title'>ARROW HEAD</div>
                            <div className='arrow-head' >
                                <div
                                    // onClick={() => handleIconClick('/img/goals/goal-1.png')}
                                    onDragStart={() => handleIconDrag( {path: 'left'} ,'29px')}
                                    draggable="true"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1.5rem" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg>            
                                </div>
                            </div>
                            <div className='arrow-inputs-con' >
                                <div className='arrow-i-mini' >
                                    <div className='ai-title' >ROTATION</div>
                                    <input
                                        type="range"
                                        value={rotation}
                                        min="0"
                                        max="360"
                                        onChange={(e) => handleRotateArrow(e.target.value)}
                                        disabled={selectedIconId==='' || !arrowEditable }
                                    />
                                </div>
                                <div className='arrow-i-mini' >
                                    <div className='ai-title' >SIZE</div>
                                    <input
                                        type="range"
                                        value={iconHeight}
                                        min="0"
                                        max="200"
                                        onChange={(e) => handleIconHeight(e.target.value)}
                                        disabled={!arrowEditable }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
export default Home