import React, { useContext, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import DrillsComp from './DrillsComp';
import UpdateSessionPopup from './UpdateSessionPopup';
import ConfirmPopup from './ConfirmPopup';
import { userData } from '../../../Context/Context';

function DrillsPopup({club,session,drills,setopenDrillsPopup,getClubDataById}) {
    const {url,userdata} = useContext(userData);
    const [search,setsearch] = useState('');
    const [openUpdateSessionPopup,setopenUpdateSessionPopup] = useState(false);
    const [openDeleteSessionPopup,setopenDeleteSessionPopup] = useState(false);
    // console.log(drills);
    // const matchedDrills = user.drills.filter((userDrill) =>
    //     drills.some((drill) => drill.id === userDrill._id)
    // );
    // const [filteredDrills, setfilteredDrills] = useState(matchedDrills);

    const handleSearch = (e) => {
        // e.preventDefault();
        // if (search === '') {
        //     setfilteredDrills(matchedDrills);
        // } else {
        //   const filtered = matchedDrills.filter((drillData) =>
        //     drillData.name.toLowerCase().includes(search.toLowerCase())
        //   );
        //   setfilteredDrills(filtered);
        // }
    };
    const handleChange = (e) =>{
        // if(e.target.value === '' ){
        //     setfilteredDrills(matchedDrills);
        // }
        // setsearch(e.target.value);
    }
    const [loading,setloading] = useState(false);
    const handleConfirmDeletePopup = async () =>{
        // console.log(session);
        setloading(true);
        const res = await fetch(`${url}deleteClubSession`,{
            method: "POST",
            headers: {
              "Content-Type" : "application/json"
            },
            body: JSON.stringify({
              clubId: club._id,
              sessionId: session._id,
              userId: userdata._id,
            }),
        });
        const data = await res.json();
        setloading(false);
        // console.log(data.data);
        if(data.msg === "success"){
            getClubDataById();
            setopenDeleteSessionPopup(false);
            setopenDrillsPopup(false);
        }
    }
    return (
    <>
        <div className='popup_full_page' >
            <div className='search_nav flex'>
                <div className='page_head width_50rem' >
                    <div className='page_head_left' >
                        <div className='page-title m0 mr_15'>Drills of Sessions</div>
                        <form onSubmit={handleSearch} >
                            <input
                                className='search_input'
                                name='drill'
                                placeholder='Drill name'
                                value={search}
                                onChange={handleChange}
                            />
                            <button className='search_btn ml_left' type='submit' >Search</button>
                        </form>
                        <div className='reload'>
                            <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.52031 8.52031C12.0839 4.95676 17.8417 4.93958 21.4281 8.46301L19.0677 10.8177C18.6724 11.213 18.5578 11.8031 18.7698 12.3187C18.9818 12.8344 19.4859 13.1667 20.0417 13.1667H26.888H27.375C28.137 13.1667 28.75 12.5536 28.75 11.7917V4.45833C28.75 3.9026 28.4177 3.39843 27.9021 3.18645C27.3865 2.97447 26.7964 3.08906 26.401 3.48437L24.0177 5.8677C18.999 0.911973 10.9151 0.92916 5.925 5.92499C4.52708 7.32291 3.51875 8.96718 2.9 10.726C2.56198 11.6828 3.06615 12.7255 4.01719 13.0635C4.96823 13.4016 6.01667 12.8974 6.35469 11.9463C6.79583 10.6974 7.51198 9.52291 8.52031 8.52031ZM1.25 18.2083V18.6437V18.6838V25.5417C1.25 26.0974 1.58229 26.6016 2.09792 26.8135C2.61354 27.0255 3.20365 26.9109 3.59896 26.5156L5.98229 24.1323C11.001 29.088 19.0849 29.0708 24.075 24.075C25.4729 22.6771 26.487 21.0328 27.1057 19.2797C27.4438 18.3229 26.9396 17.2802 25.9885 16.9422C25.0375 16.6042 23.9891 17.1083 23.651 18.0594C23.2099 19.3083 22.4938 20.4828 21.4854 21.4854C17.9219 25.049 12.1641 25.0661 8.5776 21.5427L10.9323 19.1823C11.3276 18.787 11.4422 18.1969 11.2302 17.6812C11.0182 17.1656 10.5141 16.8333 9.95833 16.8333H3.10625H3.06615H2.625C1.86302 16.8333 1.25 17.4463 1.25 18.2083Z" fill="#49D0DC"/>
                            </svg>
                        </div>
                    </div>
                    <div className='page_head_right' >
                        {/* <Link to={`/new-drill?club=${club._id}&session=${session._id}`} target='_blank' >
                            <button className='search_btn mr_10' >Add Drill</button>
                        </Link> */}
                        <button className='search_btn mr_10' onClick={()=>{setopenUpdateSessionPopup(true)}} >Edit Session</button>
                        <button className='search_btn_red' onClick={()=>{setopenDeleteSessionPopup(true)}} >Delete Session</button>
                    </div>
                </div>
            </div>
            <div className='flex3' >
                <div className='g_mini_text flex' >Creator Information</div>
                <div className='flex' >
                    <div className='user_info_line w_max'><span className='gradient_span_text2' >Name : </span> {session.created_by.name} <span className='gradient_span_text2' >Email : </span> {session.created_by.email} </div>
                </div>
            </div>
            <div className='clubs_body scroll_admin_section scroll_drill_height' >
                { drills && drills.length>0 ?(
                    <>
                        {
                            drills.map((drill,index)=>{
                                return(
                                    <>
                                        <DrillsComp club={club} session={session} drill={drill} index={index} getClubDataById={getClubDataById} />
                                    </>
                                )
                            })
                        }
                    </>
                ):(
                    <>
                        <div className='empty_page flex' >
                            No Drills added to this session.
                        </div>
                    </>
                )
                }
            </div>
        </div>
        <div className='x_mark' onClick={()=>{setopenDrillsPopup(false)}} >
            <svg width="22" height="32" viewBox="0 0 30 40" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.7656 11.7656C27.7422 10.7891 27.7422 9.20312 26.7656 8.22656C25.7891 7.25 24.2031 7.25 23.2266 8.22656L15 16.4609L6.76562 8.23437C5.78906 7.25781 4.20312 7.25781 3.22656 8.23437C2.25 9.21094 2.25 10.7969 3.22656 11.7734L11.4609 20L3.23438 28.2344C2.25781 29.2109 2.25781 30.7969 3.23438 31.7734C4.21094 32.75 5.79687 32.75 6.77344 31.7734L15 23.5391L23.2344 31.7656C24.2109 32.7422 25.7969 32.7422 26.7734 31.7656C27.75 30.7891 27.75 29.2031 26.7734 28.2266L18.5391 20L26.7656 11.7656Z" fill="white"/>
            </svg>
        </div>
        { openUpdateSessionPopup?
            <>
                <UpdateSessionPopup club={club} session={session} setopenUpdateSessionPopup={setopenUpdateSessionPopup} 
                    getClubDataById={getClubDataById} setopenDrillsPopup={setopenDrillsPopup} />
            </>:<></>
        }
        { openDeleteSessionPopup?
            <>
                <ConfirmPopup open={openDeleteSessionPopup} setOpen={setopenDeleteSessionPopup} handleconfirm={handleConfirmDeletePopup}
                    message={`Are you sure you want to delete your session ${session.name}?`} loading={loading} />
            </>:<></>
        }
    </>
  )
}

export default DrillsPopup
