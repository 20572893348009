import React,{useContext,useState,useEffect} from 'react'
import { NavLink } from 'react-router-dom'
import './Library.css'
import SessionComp from './SessionComp'
import DrillComp from './DrillComp'
import { userloggedIn } from '../Context/Context'
import { userData } from '../Context/Context'
import Navbar from '../Navbar'

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Library() {
    const { userlogged,setuserLogged } = useContext(userloggedIn);
    const { userdata,setuserdata,url,homeNav,sethomeNav,getLoggedin } = useContext(userData);
    sethomeNav(false);
    const [loading,setloading] = useState(false);
    const [category,setcategory] = useState('');
    // const [session,setSession] = useState(userdata.session);
    // const [drills,setDrills] = useState(userdata.drills);
    const [alradyErrorMsg,setalradyErrorMsg] = useState(false);
    const [addSuccessMsg,setaddSuccessMsg] = useState(false);
    const [deleteSuccessMsg,setdeleteSuccessMsg] = useState(false);
    const [copySuccessMsg,setcopySuccessMsg] = useState(false);
    const [sortedByname,setsortedByname] = useState(true);
    const [sortedDrillsByname,setsortedDrillsByname] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredSessions, setFilteredSessions] = useState([]);
    const [showFiltered,setshowFiltered] = useState(false);
    const [searchQueryDrills, setSearchQueryDrills] = useState('');
    const [filteredSessionsDrills, setFilteredSessionsDrills] = useState([]);
    const [showFilteredDrills,setshowFilteredDrills] = useState(false);
    const [sessionPopup,setsessionPopup] = useState(false);
    const [selectedDrillId,setselectedDrillId] = useState('');
    const sortSessionsByName = () => {
        setsortedByname(false);
        const sortedSessions = [...userdata.session];
        sortedSessions.sort((a, b) => {
            return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
        });
        setuserdata({ ...userdata, session: sortedSessions });
    };
    const reverseSortSessionsByName = () => {
        setsortedByname(true);
        const sortedSessions = [...userdata.session];
        sortedSessions.sort((a, b) => {            
            return b.name.localeCompare(a.name, undefined, { sensitivity: 'base' });
        });
        setuserdata({ ...userdata, session: sortedSessions });
    };
    const sortDrillsByName = () => {
        setsortedDrillsByname(false);
        const sortedSessions = [...userdata.drills];
        sortedSessions.sort((a, b) => {
            return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
        });
        setuserdata({ ...userdata, drills: sortedSessions });
    };
    const reverseSortDrillsByName = () => {
        setsortedDrillsByname(true);
        const sortedSessions = [...userdata.drills];
        sortedSessions.sort((a, b) => {            
            return b.name.localeCompare(a.name, undefined, { sensitivity: 'base' });
        });
        setuserdata({ ...userdata, drills: sortedSessions });
    };
    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        if (query.trim() === '') {
            // If the search query is empty, reset the filteredSessions            
            setFilteredSessions([]);
            setshowFiltered(false);
        } else {
            setshowFiltered(true);
            // Filter sessions based on the search query
            const filtered = userdata.session.filter((session) =>
                session.name.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredSessions(filtered);
        }
    };
    const handleSearchChangeDrills = (e) => {
        const query = e.target.value;
        setSearchQueryDrills(query);

        if (query.trim() === '') {
            // If the search query is empty, reset the filteredSessions            
            setFilteredSessionsDrills([]);
            setshowFilteredDrills(false);
        } else {
            setshowFilteredDrills(true);
            // Filter sessions based on the search query
            const filtered = userdata.drills.filter((session) =>
                session.name.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredSessionsDrills(filtered);
        }
    };
    const handleCategoryDrills = (e) => {
        // console.log('Hello');
        console.log(e.target.value);
        const categoryVal = e.target.value;
        setcategory(categoryVal);
        // console.log(e.taget.value);

        if (e.target.value.trim() === '') {
            // If the search query is empty, reset the filteredSessions            
            setFilteredSessionsDrills([]);
            setshowFilteredDrills(false);
        } else {
            setshowFilteredDrills(true);
            // Filter sessions based on the search query
            const filteredSessionsDrills = userdata.drills.filter(drill => {
                if (e.target.value === '') {
                  return true; // If no category is selected, show all drills
                }
                return drill.category === e.target.value; // Adjust 'category' to the property in your data
            });
            setFilteredSessionsDrills(filteredSessionsDrills);
        }
    };

  return (
    <>
        <Snackbar open={alradyErrorMsg} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="error" sx={{ width: '100%' }}>
                Drill is already added to a session!
            </Alert>
        </Snackbar>
        <Snackbar open={addSuccessMsg} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="success" sx={{ width: '100%' }}>
                Drill Added successfully!
            </Alert>
        </Snackbar>
        <Snackbar open={deleteSuccessMsg} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="success" sx={{ width: '100%' }}>
                Drill Deleted successfully!
            </Alert>
        </Snackbar>
        <Snackbar open={copySuccessMsg} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="success" sx={{ width: '100%' }}>
                Drill Copied successfully!
            </Alert>
        </Snackbar>
        <Navbar />
        <div className='library-main' style={{display:sessionPopup?'flex':'',justifyContent:sessionPopup?'center':''}} >
            {/* <div className='device-error' >The browser does not have enough width, should have atleast 1024px.</div> */}
            <div className='overlay' ></div>
            <div class="overlay" style={{zIndex: loading?'3':'-1', backgroundColor: loading?'rgb(0 0 0 / 0.6);':'rgba(0, 0, 0, 0.5)'}} ></div>
            <div id="loader" class="nfLoader" style={{ display:loading?'':'none' }}></div>
            { !sessionPopup?
            <>
            <div>
                <div className='session-con'>
                    <div className='session-title' > SESSION </div>
                    <hr className='session-line' />
                    <div className='flex-gap' >
                        <NavLink className='no-underline' to='/new-session'>
                            <div className='session-btn1'>
                                <div className='flex' ><svg xmlns="http://www.w3.org/2000/svg" fill='#2f8c2f' height="1em" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg></div>
                                <div className='session-title2' style={{color:'#2f8c2f'}} >NEW SESSION</div>
                            </div>
                        </NavLink>
                        <div className='search-btn' >
                            <input 
                                className='search-input'
                                placeholder='Search Session'
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>                        
                    </div>
                    <hr className='session-line' />
                    <div className='flex-align2' >
                        <div className='flex-align' >
                            <div>Name</div>
                            <div className='border-bot' onClick={reverseSortSessionsByName}  style={{ cursor:'pointer',borderBottom: sortedByname?'2px solid #45454580':'none'}} >
                                <svg xmlns="http://www.w3.org/2000/svg" fill='#45454590' height="1em" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                            </div>
                            <div className='border-bot' onClick={sortSessionsByName} style={{ cursor:'pointer',borderBottom: sortedByname?'none':'2px solid #45454580'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill='#45454590' height="1em" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                            </div>
                        </div>
                        {/* <div className='flex-align' >
                            <div>Date:</div>
                            <div className='border-bot' onClick={getLoggedin} style={{borderBottom: '2px solid #45454580'}} >
                                <svg xmlns="http://www.w3.org/2000/svg" fill='#45454590' height="1em" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                            </div>
                            <div className='border-bot' >
                                <svg xmlns="http://www.w3.org/2000/svg" fill='#45454590' height="1em" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                            </div>
                        </div> */}
                    </div>
                    <div className='session-title-con' style={{maxHeight:'27rem',overflowY: userdata.session.length>4?'scroll':'' }} >
                        {userdata.session.length > 0 && !showFiltered && userdata.session.map((session, index) => {
                            return (
                                <SessionComp session={session} popup={false} index={index} userdata={userdata} setuserdata={setuserdata} url={url} getLoggedin={getLoggedin} />
                            );
                        })}
                        {userdata.session.length > 0 && showFiltered && filteredSessions.map((session, index) => {
                            return (
                                <SessionComp session={session} popup={false} index={index} userdata={userdata} setuserdata={setuserdata} url={url} getLoggedin={getLoggedin} />
                            );
                        })}
                        {userdata.session.length === 0 &&
                            <div className='flex' style={{height:'8rem'}} >
                                No Data to Show
                            </div>
                        }
                        {showFiltered && filteredSessions.length === 0 &&
                            <div className='flex' style={{height:'8rem'}} >
                                No Matched Results
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className='drills-con' >
                <div className='session-con'>
                    <div className='session-title'> DRILLS </div>
                    <hr className='session-line' />
                    <div className='flex-gap' >
                        <NavLink className='no-underline' to='/new-drill'>
                            <div className='session-btn1'>
                                <div className='flex' ><svg xmlns="http://www.w3.org/2000/svg" fill='#2f8c2f' height="1em" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg></div>
                                <div className='session-title2' style={{color:'#2f8c2f'}} >NEW DRILLS</div>
                            </div>
                        </NavLink>
                        <div className='search-btn' >
                            <input 
                                className='search-input'
                                placeholder='Search Session'
                                value={searchQueryDrills}
                                onChange={handleSearchChangeDrills}
                            />
                        </div>
                    </div>
                    <hr className='session-line' />
                    <div className='flex-align2' >
                        <div className='flex-align' >
                            <div>Name</div>
                            <div className='border-bot' onClick={reverseSortDrillsByName} style={{ cursor:'pointer',borderBottom: sortedDrillsByname?'2px solid #45454580':'none'}} >
                                <svg xmlns="http://www.w3.org/2000/svg" fill='#45454590' height="1em" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                            </div>
                            <div className='border-bot' onClick={sortDrillsByName} style={{ cursor:'pointer',borderBottom: sortedDrillsByname?'none':'2px solid #45454580'}} >
                                <svg xmlns="http://www.w3.org/2000/svg" fill='#45454590' height="1em" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                            </div>
                        </div>
                        <select className='tiu-category-option' onChange={handleCategoryDrills} style={{background:'none',width:'8rem'}} >
                            <option value="" >Category</option>
                            <option value="warm-up">Warm Up</option>
                            <option value="arrival-activity">Arrival Activity</option>
                            <option value="recognition-game">Recognition Game</option>
                            <option value="intervention-skill-breakdown">Intervention: Skill Breakdown</option>
                            <option value="evaluation-game">Evaluation: Game</option>
                            <option value="rondo">Rondo</option>
                            <option value="passing-practice">Passing Practice</option>
                            <option value="possession-game">Possession Game</option>
                            <option value="game-training">Game Training</option>
                            <option value="training-game">Training Game</option>
                            <option value="tactical-component">Tactical Component</option>
                        </select>
                    </div>
                    <div className='session-title-con' style={{overflow: 'auto'}} >
                        {userdata.drills.length > 0 && !showFilteredDrills && userdata.drills.map((drill, index) => {
                            return (
                                <DrillComp loading={loading} setloading={setloading} copySuccessMsg={copySuccessMsg} setcopySuccessMsg={setcopySuccessMsg} deleteSuccessMsg={deleteSuccessMsg} setdeleteSuccessMsg={setdeleteSuccessMsg} setselectedDrillId={setselectedDrillId} setsessionPopup={setsessionPopup} drill={drill} index={index} userdata={userdata} setuserdata={setuserdata} url={url} getLoggedin={getLoggedin} />
                            );
                        })}
                        {userdata.drills.length > 0 && showFilteredDrills && filteredSessionsDrills.map((drill, index) => {
                            return (
                                <DrillComp loading={loading} setloading={setloading} copySuccessMsg={copySuccessMsg} setcopySuccessMsg={setcopySuccessMsg} deleteSuccessMsg={deleteSuccessMsg} setdeleteSuccessMsg={setdeleteSuccessMsg} setselectedDrillId={setselectedDrillId} setsessionPopup={setsessionPopup} drill={drill} index={index} userdata={userdata} setuserdata={setuserdata} url={url} getLoggedin={getLoggedin} />
                            );
                        })}
                        {userdata.drills.length === 0 &&
                            <div className='flex' style={{height:'8rem'}} >
                                No Data to Show!
                            </div>
                        }
                        {showFilteredDrills && filteredSessionsDrills.length === 0 &&
                            <div className='flex' style={{height:'8rem'}} >
                                No Matched Results!
                            </div>
                        }
                    </div>
                </div>
            </div>
            </>
            :
            <>
                <div className='session-con'>
                    <div className='session-title' onClick={()=>{console.log(filteredSessions)}} >CHOOSE SESSION </div>
                    <hr className='session-line' />
                    <div className='flex-gap' >
                        <NavLink className='no-underline' to='/new-session'>
                            <div className='session-btn1'>
                                <div className='flex' ><svg xmlns="http://www.w3.org/2000/svg" fill='#2f8c2f' height="1em" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg></div>
                                <div className='session-title2' style={{color:'#2f8c2f'}} >NEW SESSION</div>
                            </div>
                        </NavLink>
                        <div className='search-btn' >
                            <input 
                                className='search-input'
                                placeholder='Search Session'
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>                        
                    </div>
                    <hr className='session-line' />
                    <div className='flex-align2' style={{justifyContent: 'space-between'}} >
                        <div className='flex-align' >
                            <div>Name</div>
                            <div className='border-bot' onClick={reverseSortSessionsByName}  style={{ cursor:'pointer',borderBottom: sortedByname?'2px solid #45454580':'none'}} >
                                <svg xmlns="http://www.w3.org/2000/svg" fill='#45454590' height="1em" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                            </div>
                            <div className='border-bot' onClick={sortSessionsByName} style={{ cursor:'pointer',borderBottom: sortedByname?'none':'2px solid #45454580'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill='#45454590' height="1em" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                            </div>
                        </div>
                        <div className='es-buttons' style={{marginTop: '0'}} >
                            <button onClick={()=>{setsessionPopup(false)}} >Cancel</button>
                        </div>
                        {/* <div className='flex-align' >
                            <div>Date:</div>
                            <div className='border-bot' onClick={getLoggedin} style={{borderBottom: '2px solid #45454580'}} >
                                <svg xmlns="http://www.w3.org/2000/svg" fill='#45454590' height="1em" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                            </div>
                            <div className='border-bot' >
                                <svg xmlns="http://www.w3.org/2000/svg" fill='#45454590' height="1em" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                            </div>
                        </div> */}
                    </div>
                    <div className='session-title-con' style={{maxHeight:'27rem',overflowY: userdata.session.length>6?'scroll':'' }} >
                        {userdata.session.length > 0 && !showFiltered && userdata.session.map((session, index) => {
                            return (
                                <SessionComp alradyErrorMsg={alradyErrorMsg} setalradyErrorMsg={setalradyErrorMsg} addSuccessMsg={addSuccessMsg} setaddSuccessMsg={setaddSuccessMsg} setsessionPopup={setsessionPopup} setselectedDrillId={setselectedDrillId} selectedDrillId={selectedDrillId} popup={true} session={session} index={index} userdata={userdata} setuserdata={setuserdata} url={url} getLoggedin={getLoggedin} />
                            );
                        })}
                        {userdata.session.length > 0 && showFiltered && filteredSessions.map((session, index) => {
                            return (
                                <SessionComp alradyErrorMsg={alradyErrorMsg} setalradyErrorMsg={setalradyErrorMsg} addSuccessMsg={addSuccessMsg} setaddSuccessMsg={setaddSuccessMsg} setsessionPopup={setsessionPopup} setselectedDrillId={setselectedDrillId} selectedDrillId={selectedDrillId} popup={true} session={session} index={index} userdata={userdata} setuserdata={setuserdata} url={url} getLoggedin={getLoggedin} />
                            );
                        })}
                        {userdata.session.length === 0 &&
                            <div className='flex' style={{height:'8rem'}} >
                                No Data to Show
                            </div>
                        }
                        {showFiltered && filteredSessions.length === 0 &&
                            <div className='flex' style={{height:'8rem'}} >
                                No Matched Results
                            </div>
                        }
                    </div>
                </div>           
            </>
            }
       
        </div>
    </>
  )
}

export default Library
