import React, { useState,useContext } from 'react'
import './NewSession.css'
import { NavLink, useNavigate } from 'react-router-dom'
import { userloggedIn } from './Context/Context'
import { userData } from './Context/Context'
import Navbar from './Navbar'

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Profile() {
    const { userlogged,setuserLogged } = useContext(userloggedIn);
    const { userdata,setuserdata,url,homeNav,sethomeNav,getLoggedin } = useContext(userData);
    sethomeNav(false);
    const [name,setName] = useState(userdata.name);
    const [email,setEmail] = useState(userdata.email);
    const [newEmail,setnewEmail] = useState(userdata.email);
    const [password,setPassword] = useState(userdata.password);
    const [newpassword,setnewPassword] = useState(userdata.password);
    const [birthday,setBirthday] = useState(userdata.birthday);
    
    const [userExistError,setuserExistError] = useState(false);
    const Navigate = useNavigate();

    const [submitting,setSubmitting] = useState(false);

    const submitform = async (event)=>{
        event.preventDefault();
        setSubmitting(true);
        const res = await fetch(`${url}updateProfile`,{
          method: "POST",
          headers: {
            "Content-Type" : "application/json"
          },
          body: JSON.stringify({
            id: userdata._id, name, email, newEmail , password, newpassword,birthday
          }),
        });
        const data = await res.json();
        
        setSubmitting(false);
        if(data.msg === "success"){
            console.log('success');
            getLoggedin();
            Navigate('/');
        }else if(data.msg === 'alreadyExist'){
            setuserExistError(true);
            setTimeout(() => {
                setuserExistError(false);
            }, 4000);
        }
        else{
            console.log('failed');
        }
    }
    return (
    <> 
    <Snackbar open={userExistError} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
        <Alert severity="error" sx={{ width: '100%' }}>
            User already exist, Please try another email.
        </Alert>
    </Snackbar>
    <Navbar />
    <form onSubmit={submitform}>
        <div className='new-session-main'>
            <div className='overlay' ></div>
            <div className='ns-con' style={{height:'26rem'}} >
                <div className='ns-title' > PROFILE </div>
                <div className='ns-elem' >
                    <div>Name</div>
                    <input type='text' placeholder='Name' required value={name} onChange={(e)=>{setName(e.target.value)}} />
                </div>
                <div className='ns-elem' >
                    <div>Email</div>
                    <input type='email' placeholder='Email' required value={newEmail} onChange={(e)=>{setnewEmail(e.target.value)}} />
                </div>
                <div className='ns-elem' >
                    <div>Password</div>
                    <input type='password' placeholder='New Password' autoComplete="off" minLength={8} onChange={(e)=>{setPassword(e.target.value)}} />
                </div>
                <div className='ns-elem' >
                    <div>Birthday ({new Date(userdata.birthday).toLocaleString('en-US', { day: 'numeric', month: 'short',year: 'numeric' })}) </div>
                    <input type='date' placeholder='Birthday' value={birthday} onChange={(e)=>{setBirthday(e.target.value)}} />
                </div>

                <div className='sn-btn' >
                    <button className='sn-btn1' disabled={submitting} > { submitting?'Updating':'Update' } </button>
                    <NavLink to='/' ><button className='sn-btn2' > Cancel </button></NavLink>
                </div>
            </div>
        </div>
    </form>
    </>
  )
}

export default Profile
