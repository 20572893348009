import React,{useContext,useState,useEffect} from 'react'
import { NavLink, useParams, useNavigate } from 'react-router-dom'
import './Library.css'
import SessionComp from './SessionComp'
import DrillCompEditSession from './DrillCompEditSession'
import { userloggedIn } from '../Context/Context'
import { userData } from '../Context/Context'
import Navbar from '../Navbar'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DrillCompAdd from './DrillCompAdd'
import ChooseDrill from './ChooseDrill'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function EditSession() {
    const { userlogged,setuserLogged } = useContext(userloggedIn);
    const { userdata,setuserdata,url,homeNav,sethomeNav,getLoggedin } = useContext(userData);
    const { id } = useParams();
    const Navigate = useNavigate();
    // console.log('Session');
    // console.log(id);
    const [name,setName] = useState('');
    const [notes,setNotes] = useState('');
    const [team,setTeam] = useState('');
    const [duration,setDuration] = useState('');
    const [intensity,setIntensity] = useState('');
    const [date,setDate] = useState('');
    
    const [loading,setLoading] = useState(false);
    const [submitting,setSubmitting] = useState(false);
    const [showEdit,setshowEdit] = useState(false);
    const [removeDrillMsg,setremoveDrillMsg] = useState(false);
    const [alradyErrorMsg,setalradyErrorMsg] = useState(false);
    const [addSuccessMsg,setaddSuccessMsg] = useState(false);
    const [deleteSuccessMsg,setdeleteSuccessMsg] = useState(false);
    
    const [sortedByname,setsortedByname] = useState(true);
    const [sortedDrillsByname,setsortedDrillsByname] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredSessions, setFilteredSessions] = useState([]);
    const [showFiltered,setshowFiltered] = useState(false);
    const [searchQueryDrills, setSearchQueryDrills] = useState('');
    const [filteredSessionsDrills, setFilteredSessionsDrills] = useState([]);
    const [showFilteredDrills,setshowFilteredDrills] = useState(false);
    const [sessionPopup,setsessionPopup] = useState(false);
    const [selectedDrillId,setselectedDrillId] = useState('');
    const [drills,setdrills] = useState([]);
    const sortSessionsByName = () => {
        setsortedByname(false);
        const sortedSessions = [...userdata.session];
        sortedSessions.sort((a, b) => {
            return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
        });
        setuserdata({ ...userdata, session: sortedSessions });
    };
    const reverseSortSessionsByName = () => {
        setsortedByname(true);
        const sortedSessions = [...userdata.session];
        sortedSessions.sort((a, b) => {            
            return b.name.localeCompare(a.name, undefined, { sensitivity: 'base' });
        });
        setuserdata({ ...userdata, session: sortedSessions });
    };
    const sortDrillsByName = () => {
        setsortedDrillsByname(false);
        const sortedSessions = [...drills];
        sortedSessions.sort((a, b) => {
            return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
        });
        setdrills({ sortedSessions });
    };
    const reverseSortDrillsByName = () => {
        setsortedDrillsByname(true);
        const sortedSessions = [...drills];
        sortedSessions.sort((a, b) => {            
            return b.name.localeCompare(a.name, undefined, { sensitivity: 'base' });
        });
        setdrills({sortedSessions });
    };
    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        if (query.trim() === '') {
            // If the search query is empty, reset the filteredSessions            
            setFilteredSessions([]);
            setshowFiltered(false);
        } else {
            setshowFiltered(true);
            // Filter sessions based on the search query
            const filtered = userdata.session.filter((session) =>
                session.name.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredSessions(filtered);
        }
    };
    const handleSearchChangeDrills = (e) => {
        const query = e.target.value;
        setSearchQueryDrills(query);

        if (query.trim() === '') {
            // If the search query is empty, reset the filteredSessions            
            setFilteredSessionsDrills([]);
            setshowFilteredDrills(false);
        } else {
            setshowFilteredDrills(true);
            // Filter sessions based on the search query
            const filtered = drills.filter((session) =>
                session.name.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredSessionsDrills(filtered);
        }
    };

    const getSessionInfo = async ()=>{
        setLoading(true);
        const res = await fetch(`${url}getSessionInfo`,{
          method: "POST",
          headers: {
            "Content-Type" : "application/json"
          },
          body: JSON.stringify({
            id: userdata._id,
            sessionId: id,
          }),
        });
        const data = await res.json();
        // console.log(data);
        setLoading(false);
        if(data.msg === "success"){
            // console.log('success');
            getLoggedin();
            setName(data.data.name);
            setNotes(data.data.notes);
            setTeam(data.data.team);
            setIntensity(data.data.intensity);
            setDuration(data.data.duration);
            setDate(data.data.date);
        }else{
            // console.log('failed');
        }
    }
    const getSessionDrills = async ()=>{
        const res = await fetch(`${url}getSessionDrills`,{
          method: "POST",
          headers: {
            "Content-Type" : "application/json"
          },
          body: JSON.stringify({
            id: userdata._id,
            sessionId: id,
          }),
        });
        const data = await res.json();
        // console.log(data);
        if(data.msg === "success"){
            console.log(data.data);
            setdrills(data.data);
            getLoggedin();
        }else{
            // console.log('failed');
        }
    }
    
    const submitform = async (event)=>{
        event.preventDefault();
        setSubmitting(true);
        const res = await fetch(`${url}updateSession`,{
          method: "POST",
          headers: {
            "Content-Type" : "application/json"
          },
          body: JSON.stringify({
            id: userdata._id,
            sessionId: id, 
            name, 
            notes, 
            team, 
            duration, 
            intensity, 
            date
          }),
        });
        const data = await res.json();
        
        setSubmitting(false);
        if(data.msg === "success"){
            console.log('success');
            getSessionInfo();
            setshowEdit(false);
            // getLoggedin();
            setuserdata(data.data);
            Navigate('/library');
        }else{
            console.log('failed');
        }
      }
    useEffect(()=>{
        getSessionInfo();
        getSessionDrills();
      },[])
  return (
    <>
         <Snackbar open={alradyErrorMsg} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="error" sx={{ width: '100%' }}>
                Drill is already added to a session!
            </Alert>
        </Snackbar>
        <Snackbar open={removeDrillMsg} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="success" sx={{ width: '100%' }}>
                Drill has been removed successfully!
            </Alert>
        </Snackbar>
        <Snackbar open={addSuccessMsg} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="success" sx={{ width: '100%' }}>
                Drill added successfully!
            </Alert>
        </Snackbar>
        <Snackbar open={deleteSuccessMsg} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="success" sx={{ width: '100%' }}>
                Drill deleted successfully!
            </Alert>
        </Snackbar>
        <Navbar />
        <div className='library-main' style={{display:sessionPopup?'flex':'',justifyContent:sessionPopup?'center':''}} >
            <div className='overlay' ></div>
            { ! sessionPopup ?
            (
                <>
                    <div>
                        { showEdit?
                        (
                        <form onSubmit={submitform} >
                            <div className='ns-con2'>
                                <div className='ns-title' > EDIT SESSION </div>
                                <div className='ns-elem' >
                                    <div>Name</div>
                                    <input type='text' placeholder='Name' value={name} onChange={(e)=>{setName(e.target.value)}} />
                                </div>
                                <div className='ns-elem' >
                                    <div>Notes</div>
                                    <textarea type='text' placeholder='Notes' value={notes} onChange={(e)=>{setNotes(e.target.value)}} />
                                </div>
                                <div className='ns-elem' >
                                    <div>Team</div>
                                    <input type='text' placeholder='Team name' value={team} onChange={(e)=>{setTeam(e.target.value)}} />
                                </div>
                                <div className='ns-elem' >
                                    <div>Duration</div>
                                    <input type='text' placeholder='Duration' value={duration} onChange={(e)=>{setDuration(e.target.value)}} />
                                </div>
                                <div className='ns-elem' >
                                    <div>Intensity</div>
                                    <select value={intensity} onChange={(e)=>{setIntensity(e.target.value)}} >
                                        <option>0</option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                        <option>6</option>
                                        <option>7</option>
                                        <option>8</option>
                                        <option>9</option>
                                        <option>10</option>
                                    </select>
                                </div>
                                <div className='ns-elem'>
                                    <div>Date</div>
                                    <input type='date' placeholder='Date' value={date} onChange={(e)=>{setDate(e.target.value)}} />
                                </div>
                                <div className='sn-btn' >
                                    <button className='sn-btn1' disabled={submitting} > { submitting?'Updating':'Update' } </button>
                                    <button className='sn-btn2' onClick={()=>{setshowEdit(false)}} > Cancel </button>
                                </div>
                            </div>
                        </form>
                        ):
                        (
                        <div className='session-con'>
                            <div className='session-title' > {name} </div>
                            <hr className='session-line' />
                            <div> <span className='es-mini-title' >Date:</span> { date } </div>
                            <div> <span className='es-mini-title' >Team:</span> {team} </div>
                            <div> <span className='es-mini-title' >Duration:</span> {duration} </div>
                            <div> <span className='es-mini-title' >Notes:</span> {notes} </div>
                            <div> <span className='es-mini-title' >Intensity:</span> {intensity} </div>
                            <div className='es-buttons'>
                                <button onClick={()=>{setshowEdit(true)}} >Edit</button>
                                <button onClick={()=>{setsessionPopup(true)}} >Add Drill</button>
                                <NavLink to={`/print-session/${id}`} style={{textDecoration:'none'}} >
                                    <button>Print</button>
                                </NavLink>
                                <NavLink to={`/library`} style={{textDecoration:'none'}} >
                                    <button>Cancel</button>
                                </NavLink>
                            </div>
                        </div>
                        )
                        }
                    </div>
                    <div className='drills-con' >
                        <div className='session-con'>
                            <div className='session-title'> DRILLS </div>
                            <hr className='session-line' />
                            <div className='flex-gap' >
                                <NavLink className='no-underline' to='/'>
                                    <div className='session-btn1'>
                                        <div className='flex' ><svg xmlns="http://www.w3.org/2000/svg" fill='#2f8c2f' height="1em" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg></div>
                                        <div className='session-title2' style={{color:'#2f8c2f'}} >NEW DRILLS</div>
                                    </div>
                                </NavLink>
                                <div className='search-btn' >
                                    <input 
                                        className='search-input'
                                        placeholder='Search Session'
                                        value={searchQueryDrills}
                                        onChange={handleSearchChangeDrills}
                                    />
                                </div>
                            </div>
                            <hr className='session-line' />
                            <div className='flex-align2' >
                                {/* <div className='flex-align' >
                                    <div>Name</div>
                                    <div className='border-bot' onClick={reverseSortDrillsByName} style={{ cursor:'pointer',borderBottom: sortedDrillsByname?'2px solid #45454580':'none'}} >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill='#45454590' height="1em" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                                    </div>
                                    <div className='border-bot' onClick={sortDrillsByName} style={{ cursor:'pointer',borderBottom: sortedDrillsByname?'none':'2px solid #45454580'}} >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill='#45454590' height="1em" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                                    </div>
                                </div> */}
                            </div>
                            <div className='session-title-con' style={{overflowY: drills.length>=6?'scroll':''}} >
                                {drills.length > 0 && !showFilteredDrills && drills.map((drill, index) => {
                                    return (
                                        <DrillCompEditSession id={id} removeDrillMsg={removeDrillMsg} setremoveDrillMsg={setremoveDrillMsg} getSessionDrills={getSessionDrills} setselectedDrillId={setselectedDrillId} setsessionPopup={setsessionPopup} drill={drill} index={index} userdata={userdata} setuserdata={setuserdata} url={url} getLoggedin={getLoggedin} />
                                    );
                                })}
                                {drills.length > 0 && showFilteredDrills && filteredSessionsDrills.map((drill, index) => {
                                    return (
                                        <DrillCompEditSession id={id} removeDrillMsg={removeDrillMsg} setremoveDrillMsg={setremoveDrillMsg} getSessionDrills={getSessionDrills} setselectedDrillId={setselectedDrillId} setsessionPopup={setsessionPopup} drill={drill} index={index} userdata={userdata} setuserdata={setuserdata} url={url} getLoggedin={getLoggedin} />
                                    );
                                })}
                                {drills.length === 0 &&
                                    <div className='flex' style={{height:'8rem'}} >
                                        No Data to Show!
                                    </div>
                                }
                                {showFilteredDrills && filteredSessionsDrills.length === 0 &&
                                    <div className='flex' style={{height:'8rem'}} >
                                        No Matched Results!
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </>
            ):(
                <>
                <div className='session-con' style={{maxWidth: '40rem',minWidth: '40rem'}} >
                    <ChooseDrill userdata={userdata} drills={drills} setdrills={setdrills} getSessionDrills={getSessionDrills} alradyErrorMsg={alradyErrorMsg} setalradyErrorMsg={setalradyErrorMsg} addSuccessMsg={addSuccessMsg} setaddSuccessMsg={setaddSuccessMsg} deleteSuccessMsg={deleteSuccessMsg} setdeleteSuccessMsg={setdeleteSuccessMsg} setselectedDrillId={setselectedDrillId} setsessionPopup={setsessionPopup} id={id} setuserdata={setuserdata} url={url} getLoggedin={getLoggedin} />
                </div>
                </>
            )
            }
        </div>
    </>
  )
}

export default EditSession
