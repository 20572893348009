import React, { useState } from 'react'
import {NavLink} from 'react-router-dom'

function DrillCompEditSession({drill,index,id,userdata,setuserdata,url,getLoggedin,getSessionDrills,setsessionPopup,setselectedDrillId,deleteSuccessMsg,setdeleteSuccessMsg,removeDrillMsg,setremoveDrillMsg}) {
    const [dropDown,setDropDown] = useState(false);
    const [showSetting,setshowSetting] = useState(false);
    const [showInputs,setshowInputs] = useState(false);
    const [duration,setDuration] = useState('');
    const [fieldSize,setfieldSize] = useState('');
    const [submitting,setSubmitting] = useState(false);
    const handleMouseOver = ()=>{
        setshowSetting(true);
    }
    const handleMouseLeave = ()=>{
        setshowSetting(false);
        setDropDown(false);
    }
    const updateParameters = async ()=>{
        setSubmitting(true);
        const res = await fetch(`${url}updateParameters`,{
          method: "POST",
          headers: {
            "Content-Type" : "application/json"
          },
          body: JSON.stringify({
            id: userdata._id,
            drillId: drill._id,
            duration, fieldSize,
          }),
        });
        setSubmitting(false);
        const data = await res.json();        
        if(data.msg === "success"){
            console.log('success');
            console.log('paragra');
            // getLoggedin();
            setuserdata(data.data);
            setshowInputs(false);
        }else{
            console.log('failed');
        }
    }
    const deleteDrill = async ()=>{
        // setSubmitting(true);
        const res = await fetch(`${url}deleteDrill`,{
          method: "POST",
          headers: {
            "Content-Type" : "application/json"
          },
          body: JSON.stringify({
            id: userdata._id,
            drillId: drill._id,
          }),
        });
        // setSubmitting(false);
        const data = await res.json();

        if(data.msg === "success"){
            console.log('success');
            // getLoggedin();
            setuserdata(data.data);
            setdeleteSuccessMsg(true);
            setTimeout(() => {
                setdeleteSuccessMsg(false);                
            }, 4000);
        }else{
            console.log('failed');
        }
    }
    const handleAddSession = () =>{
        setsessionPopup(true);
        setselectedDrillId(drill._id);
    }
    const removeDrillfromSession = async ()=>{
        // setSubmitting(true);
        const res = await fetch(`${url}removeDrillfromSession`,{
            method: "POST",
            headers: {
              "Content-Type" : "application/json"
            },
            body: JSON.stringify({
              id: userdata._id,
              sessionId: id,
              drillId: drill._id,
            }),
          });
        //   setSubmitting(false);
          const data = await res.json();
  
          if(data.msg === "success"){
              console.log('success');
            //   setsessionPopup(false);
            //   setselectedDrillId('');
                setremoveDrillMsg(true);
                setTimeout(() => {
                    setremoveDrillMsg(false);
                }, 4000);
                getSessionDrills()
                // getLoggedin();
                setuserdata(data.data);
          }else{
              setsessionPopup(false);
              console.log('failed');
          }
    }
    return (
    <>       
        <div className='drills-elem' onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseOver} >
            <NavLink to={`/library/drill-edit/${drill._id}`} >
                <div className='drill-elem-img'> <img src={drill.imageUrl} /> </div>
            </NavLink>
            <div className='drill-elem-right'>
                <NavLink to={`/library/drill-edit/${drill._id}`} style={{textDecoration:'none'}} >
                    <div className='s-con-title1' > {drill.name} </div>
                    <div className='s-con-title2' > {drill.setup} </div>
                </NavLink>
                <div className='flex-space'>
                    { showInputs?
                    (
                        <>
                            <div className='flex-gap-2' >
                                <div className='s-con-title2' >Duration: <input type='text' required className='parameters-input' placeholder='Enter Duration' onChange={(e)=>{setDuration(e.target.value)}} /> </div>
                                <div className='s-con-title2' >Field Size: <input type='text' required className='parameters-input' placeholder='Enter Field Size' onChange={(e)=>{setfieldSize(e.target.value)}} /> </div>
                            </div>
                            <button className='session-btn1' onClick={()=>updateParameters()} style={{width:'6rem',fontSize:'14px',padding:'5px',color:'#2f8c2f'}} > { submitting?'Updating':'Update' } </button>
                        </>
                    ):
                    (
                        <>
                            <div className='flex-gap-2' >
                                <div className='s-con-title2' >Duration: {drill.duration} </div>
                                <div className='s-con-title2' >Field Size: {drill.fieldSize} </div>
                            </div>
                            <button className='session-btn1' style={{width:'6rem',fontSize:'14px',padding:'5px',color:'#2f8c2f'}} onClick={()=>{setshowInputs(true)}} >Parameter</button>
                        </>
                    )
                    }
                </div>
            </div>
            { showSetting &&
                <>
                    <svg onClick={()=>{setDropDown(!dropDown)}} xmlns="http://www.w3.org/2000/svg" height="1.2em" viewBox="0 0 512 512"><path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/>
                    </svg>
                </>
            }
            { dropDown &&
                <div className='setting-dropdown'>
                    <div style={{padding:'0'}} ><NavLink to={`/print-drill/${drill._id}`} style={{textDecoration:'none',color:'black',display: 'block',height: '100%',width: '100%', padding: '3px 10px'}} >Print</NavLink></div>
                    <div style={{padding:'0'}} ><NavLink to={`/library/drill-edit/${drill._id}`} style={{textDecoration:'none',color:'black',display: 'block',height: '100%',width: '100%', padding: '3px 10px'}} >Edit</NavLink></div>
                    <div onClick={deleteDrill} >Delete</div>
                    <div onClick={removeDrillfromSession} >Remove from Session</div>
                </div>
            }
        </div>  
    
    </>
  )
}

export default DrillCompEditSession
