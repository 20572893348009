import React, { useRef, useState, useEffect } from 'react'
import Draggable from 'react-draggable';

function DraggableIcon({ icon, index, arrowEditable, setarrowEditable, setselectedIconId, seticonHeight, setRotation, handleDrag, updateDrill, 
  showDeleteIcon, setActiveDeleteIconIndex, icons, setIcons, cursorSelection, setShapeActiveIndex }) {
    const [showHighlight,setshowHighlight] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const textRef = useRef(null);
    const handleTextClick = () => {
      setEditMode(true);
      // textRef.current.focus();
    };
    const ShowDeleteIconFun = (e,index) => {      
      // console.log(icon.iconType);
      e.stopPropagation();
      console.log("ICON");
      if(icon.iconType==='arrow-left'){
        setarrowEditable(true);
      }else{
        setarrowEditable(false);
      }
      setselectedIconId(icon.id);
      setRotation(icon.rotation);
      seticonHeight(icon.height);
      // console.log(icon.id);
      if(cursorSelection === 7){
        // console.log('Show HIGHLIGHT');
        // setshowHighlight(true);
        toggleHighlight(index);
      }else{
        setActiveDeleteIconIndex(index);
        setShapeActiveIndex(-1);
      }
    };
    const handleIconDrag = ()=>{
      setselectedIconId(icon.id);
    }
    const deleteIcon = (indexToDelete) => {
      setIcons((prevIcons) => prevIcons.filter((_, newindex) => newindex !== indexToDelete));
    };
    
    const handleDeleteClick = () => {      
      deleteIcon(index);
    };
    const handleIconsMouseUp = () => {
      // updateDrill();
    };
    const toggleHighlight = (index) => {
      setIcons((prevIcons) =>
        prevIcons.map((icon, i) => {
          if (i === index) {
            return {
              ...icon,
              highlight: !icon.highlight,
            };
          }
          return icon;
        })
      );
    };
    
    const handleChange = (e) => {
      const { value } = e.target;
  
      setIcons((prevIcons) =>
          prevIcons.map((prevIcon, i) => {
              if (i === index) {
                  return {
                      ...prevIcon,
                      textValue: value,
                  };
              }
              return prevIcon;
          })
      );
    };
    const handleSubmit = (e) => {
      e.preventDefault();
      setEditMode(false);
    }
    const handleDragClick = (e) =>{
      e.stopPropagation();
      console.log('Icon CLicked');
    }

    const handleOutsideClick = (e) => {
      if (textRef.current && !textRef.current.contains(e.target)) {
        setEditMode(false);
        console.log("Outer of input");
      }
    };

    useEffect(() => {
      document.addEventListener('click', handleOutsideClick);
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, []);

    return (
      <>
        <Draggable
          key={index}
          bounds="parent"
          onDrag={(e, data) => handleDrag(e, data, index)}
          defaultPosition={{ x: icon.left, y: icon.top }}
          disabled={cursorSelection!==1}
          style={{zIndex:'3'}}
          onClick={handleDragClick}
        >
          <div
            className='draggable-image'
            style={{
              // backgroundImage: `url(${icon.path})`,
              border: showDeleteIcon && icon.highlight===false ? '1px dashed #ffffff63' : '1px solid transparent',
              // borderStyle: showDeleteIcon ? 'dashed' : '',
            }}
            onMouseUp={handleIconsMouseUp}
            onClick={(e) => ShowDeleteIconFun(e,index)}
            onDragStart={handleIconDrag}
          >
            { icon.iconType === 'icon' &&
              <>
                <img
                  src={icon.path} 
                  draggable="false" 
                  style={{
                    height: icon.height,
                    position: 'relative', 
                    zIndex: '3',
                    cursor: cursorSelection === 7 ? 'cell' : '',
                  }}
                />
                { icon.highlight && <div className='highlight-circle'></div> }
              </>
            }
            { icon.iconType === 'arrow-left' &&             
              <svg transform={`rotate(${icon.rotation})`} fill={icon.color} height={icon.arrowHeight} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg>            
            }
            {/* { icon.iconType === 'arrow-left' &&
                <img src='img/black/Player1-01.png' style={{height: `30px`,transform: `rotate(${icon.rotation})`}} />
            } */}

            {/* { icon.iconType === 'text' &&
              <div className='dragged-text' style={{height: icon.height, color:icon.color }} > { icon.textValue } </div>
            } */}

            {icon.iconType === 'text' && (
            <>
              {!editMode && (
                <div ref={textRef} className='dragged-text' style={{ height: icon.height, color: icon.color }} onClick={handleTextClick}>
                  {icon.textValue}
                </div>
              )}
              {editMode && (
                <form onSubmit={handleSubmit} >
                  <input
                    ref={textRef}
                    className='drag_input'
                    style={{ color: icon.color, minWidth: '3ch', width: `${icon.textValue.length}ch` }}
                    type='text'
                    defaultValue={icon.textValue}
                    onChange={handleChange}
                    autoCorrect='false'
                    autoComplete='false'
                    autoCapitalize='false'
                  />
                </form>
              )}
              {icon.highlight && <div className='highlight-circle'></div>}
            </>
          )}

            {/* { icon.type === 'arrow-right' &&
                <svg className='arrow-svg' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
            } */}
            <svg
              className='icon-cross'
              style={{display: showDeleteIcon?'block':'none' }}
              onClick={handleDeleteClick}
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 512 512"
            >
              <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
            </svg>
          </div>
        </Draggable>
      </>
    );
  }
export default DraggableIcon;