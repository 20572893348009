import React,{useState,useEffect,useContext} from 'react'
import Navbar from '../Navbar'
import './Club.css'
import { userData } from '../Context/Context'
import Clubs from './MyClubs/Clubs';
import JClubs from './JoinedClubs/Clubs';
import axios from 'axios';
// import Clubs from '';

function ClubMain() {
    const { userdata,setuserdata,url } = useContext(userData);
    const [activeSidebar,setactiveSidebar] = useState(1);
    function handleChange(number){
        // console.log(number);
        setactiveSidebar(number);
    }
  return (
    <>
        <Navbar />
        <div className='admin_cover' >
            <div className='eclipse_bot_left'></div>
            <div className='eclipse_top_right'></div>
            {/* <div className='a_navbar'>
                <div className='a_nav_left'>
                    <img src='/img/soccer-specific-logo-square.png' />
                </div>
                <div className='a_nav_right'>
                    <svg width="22" height="26" viewBox="0 0 27 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_199785_134)">
                        <path d="M13.5 15.5C15.546 15.5 17.5081 14.6835 18.9548 13.2301C20.4015 11.7767 21.2143 9.80543 21.2143 7.75C21.2143 5.69457 20.4015 3.72333 18.9548 2.26992C17.5081 0.816516 15.546 0 13.5 0C11.454 0 9.49189 0.816516 8.04518 2.26992C6.59847 3.72333 5.78571 5.69457 5.78571 7.75C5.78571 9.80543 6.59847 11.7767 8.04518 13.2301C9.49189 14.6835 11.454 15.5 13.5 15.5ZM10.7458 18.4062C4.80937 18.4062 0 23.2379 0 29.2018C0 30.1947 0.801563 31 1.78996 31H25.21C26.1984 31 27 30.1947 27 29.2018C27 23.2379 22.1906 18.4062 16.2542 18.4062H10.7458Z" fill="#49D0DC"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_199785_134">
                        <rect width="27" height="31" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>
                    <div className='mini-bold-title' >
                        Alanmayorga
                    </div>
                </div>
            </div> */}
            <div className='admin_body_main' >
                <div className='a_body_left' >
                    <div className='g_mini_text m_10' >Admin</div>
                    <div className='sidebar_comp' >
                        <div className={`sidebar_selection ${activeSidebar===1?'sidebar_bg':''}`} onClick={()=>{handleChange(1)}} >
                            <div className='sidebar_svg_con' >
                                <svg width="22" height="18" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_199794_1245)">
                                    <path d="M27.9903 12.4756C27.9903 13.3545 27.2611 14.043 26.4347 14.043H24.8792L24.9132 21.8652C24.9132 21.9971 24.9035 22.1289 24.8889 22.2607V23.0469C24.8889 24.126 24.0188 25 22.9444 25H22.1667C22.1132 25 22.0597 25 22.0063 24.9951C21.9382 25 21.8701 25 21.8021 25H20.2222H19.0556C17.9813 25 17.1111 24.126 17.1111 23.0469V21.875V18.75C17.1111 17.8857 16.416 17.1875 15.5556 17.1875H12.4444C11.584 17.1875 10.8889 17.8857 10.8889 18.75V21.875V23.0469C10.8889 24.126 10.0188 25 8.94445 25H7.77778H6.22708C6.15417 25 6.08125 24.9951 6.00833 24.9902C5.95 24.9951 5.89167 25 5.83333 25H5.05556C3.98125 25 3.11111 24.126 3.11111 23.0469V17.5781C3.11111 17.5342 3.11111 17.4854 3.11597 17.4414V14.043H1.55556C0.680556 14.043 0 13.3594 0 12.4756C0 12.0361 0.145833 11.6455 0.486111 11.3037L12.95 0.390625C13.2903 0.0488281 13.6792 0 14.0194 0C14.3597 0 14.7486 0.0976562 15.0403 0.341797L27.4556 11.3037C27.8444 11.6455 28.0389 12.0361 27.9903 12.4756Z" fill="white"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_199794_1245">
                                    <rect width="28" height="25" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div>My Clubs</div>
                        </div>
                        <div className={`sidebar_selection ${activeSidebar===2?'sidebar_bg':''}`} onClick={()=>{handleChange(2)}} >
                            <div className='sidebar_svg_con' >
                                <svg width="24" height="18" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_199794_1240)">
                                    <path d="M6.75 0C7.74456 0 8.69839 0.395088 9.40165 1.09835C10.1049 1.80161 10.5 2.75544 10.5 3.75C10.5 4.74456 10.1049 5.69839 9.40165 6.40165C8.69839 7.10491 7.74456 7.5 6.75 7.5C5.75544 7.5 4.80161 7.10491 4.09835 6.40165C3.39509 5.69839 3 4.74456 3 3.75C3 2.75544 3.39509 1.80161 4.09835 1.09835C4.80161 0.395088 5.75544 0 6.75 0ZM24 0C24.9946 0 25.9484 0.395088 26.6516 1.09835C27.3549 1.80161 27.75 2.75544 27.75 3.75C27.75 4.74456 27.3549 5.69839 26.6516 6.40165C25.9484 7.10491 24.9946 7.5 24 7.5C23.0054 7.5 22.0516 7.10491 21.3484 6.40165C20.6451 5.69839 20.25 4.74456 20.25 3.75C20.25 2.75544 20.6451 1.80161 21.3484 1.09835C22.0516 0.395088 23.0054 0 24 0ZM0 14.0016C0 11.2406 2.24062 9 5.00156 9H7.00312C7.74844 9 8.45625 9.16406 9.09375 9.45469C9.03281 9.79219 9.00469 10.1438 9.00469 10.5C9.00469 12.2906 9.79219 13.8984 11.0344 15C11.025 15 11.0156 15 11.0016 15H0.998437C0.45 15 0 14.55 0 14.0016ZM18.9984 15C18.9891 15 18.9797 15 18.9656 15C20.2125 13.8984 20.9953 12.2906 20.9953 10.5C20.9953 10.1438 20.9625 9.79688 20.9062 9.45469C21.5438 9.15938 22.2516 9 22.9969 9H24.9984C27.7594 9 30 11.2406 30 14.0016C30 14.5547 29.55 15 29.0016 15H18.9984ZM10.5 10.5C10.5 9.30653 10.9741 8.16193 11.818 7.31802C12.6619 6.47411 13.8065 6 15 6C16.1935 6 17.3381 6.47411 18.182 7.31802C19.0259 8.16193 19.5 9.30653 19.5 10.5C19.5 11.6935 19.0259 12.8381 18.182 13.682C17.3381 14.5259 16.1935 15 15 15C13.8065 15 12.6619 14.5259 11.818 13.682C10.9741 12.8381 10.5 11.6935 10.5 10.5ZM6 22.7484C6 19.2984 8.79844 16.5 12.2484 16.5H17.7516C21.2016 16.5 24 19.2984 24 22.7484C24 23.4375 23.4422 24 22.7484 24H7.25156C6.5625 24 6 23.4422 6 22.7484Z" fill="white"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_199794_1240">
                                    <rect width="30" height="24" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div>Joined Clubs</div>
                        </div>
                    </div>
                </div>
                <div className='a_body_right'>
                    {   activeSidebar === 1 ?
                            <Clubs />
                        :
                        activeSidebar === 2 ?
                            <JClubs />
                        :
                        <></>
                    }
                </div>
            </div>
        </div>
    </>
  )
}

export default ClubMain
