import React, { useState,useContext, useEffect } from 'react';
import ClubsComp from './ClubsComp';
import AddClubPopup from './AddClubPopup';
import { userData } from '../../Context/Context';
import JoinClubPopup from './JoinClubPopup';

function Clubs() {
    const { userdata,setuserdata,url,allClubsData,setallClubsData,getAllDataforAdmin} = useContext(userData);
    const [email,setemail] = useState('');
    const [openAddClubPopup,setopenAddClubPopup] = useState(false);
    const [openJoinClubPopup,setopenJoinClubPopup] = useState(false);

    const [search,setsearch] = useState('');
    const [filteredSessions, setFilteredSessions] = useState(allClubsData);

    useEffect(()=>{
        setFilteredSessions(allClubsData);
    },[allClubsData]);

    const handleSearch = (e) => {
        e.preventDefault();
        if (search === '') {
            setFilteredSessions(allClubsData);
        } else {
            const filtered = allClubsData.filter((club) =>{
                return club.title.toLowerCase().includes(search.toLowerCase())
            });
            setFilteredSessions(filtered);
        }
    };
    const handleChange = (e) =>{
        if(e.target.value === '' ){
            setFilteredSessions(allClubsData);
        }
        setsearch(e.target.value);
    };
    
  return (
    <>
        <div className='page_head mb_10' >
            <div className='page_head_left' >
                <div className='page-title m0 mr_15'>All Clubs</div>
                <form onSubmit={handleSearch} >
                    <input
                        className='search_input'
                        name='sesison'
                        placeholder='Session name'
                        value={search}
                        onChange={handleChange}
                    />
                    <button className='search_btn ml_left' type='submit' >Search</button>
                </form>
            </div>
            <div className='page_head_right flex_align3' >
                {/* <div className='reload' onClick={()=>{setopenAddClubPopup(true)}}>
                    <svg fill='#49D0DC' xmlns="http://www.w3.org/2000/svg" height="18" width="16" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
                </div> */}
                <button className='search_btn ml_left' type='submit' onClick={()=>{setopenAddClubPopup(true)}} >Club Requests</button>
                <button className='search_btn ml_left' type='submit' onClick={()=>{setopenJoinClubPopup(true)}} >Join Requests</button>
                <div className='reload' onClick={getAllDataforAdmin} >
                    <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.52031 8.52031C12.0839 4.95676 17.8417 4.93958 21.4281 8.46301L19.0677 10.8177C18.6724 11.213 18.5578 11.8031 18.7698 12.3187C18.9818 12.8344 19.4859 13.1667 20.0417 13.1667H26.888H27.375C28.137 13.1667 28.75 12.5536 28.75 11.7917V4.45833C28.75 3.9026 28.4177 3.39843 27.9021 3.18645C27.3865 2.97447 26.7964 3.08906 26.401 3.48437L24.0177 5.8677C18.999 0.911973 10.9151 0.92916 5.925 5.92499C4.52708 7.32291 3.51875 8.96718 2.9 10.726C2.56198 11.6828 3.06615 12.7255 4.01719 13.0635C4.96823 13.4016 6.01667 12.8974 6.35469 11.9463C6.79583 10.6974 7.51198 9.52291 8.52031 8.52031ZM1.25 18.2083V18.6437V18.6838V25.5417C1.25 26.0974 1.58229 26.6016 2.09792 26.8135C2.61354 27.0255 3.20365 26.9109 3.59896 26.5156L5.98229 24.1323C11.001 29.088 19.0849 29.0708 24.075 24.075C25.4729 22.6771 26.487 21.0328 27.1057 19.2797C27.4438 18.3229 26.9396 17.2802 25.9885 16.9422C25.0375 16.6042 23.9891 17.1083 23.651 18.0594C23.2099 19.3083 22.4938 20.4828 21.4854 21.4854C17.9219 25.049 12.1641 25.0661 8.5776 21.5427L10.9323 19.1823C11.3276 18.787 11.4422 18.1969 11.2302 17.6812C11.0182 17.1656 10.5141 16.8333 9.95833 16.8333H3.10625H3.06615H2.625C1.86302 16.8333 1.25 17.4463 1.25 18.2083Z" fill="#49D0DC"/>
                    </svg>
                </div>
            </div>
        </div>
        { openAddClubPopup ? (
          <>
            <AddClubPopup getAllDataforAdmin={getAllDataforAdmin} setopenAddClubPopup={setopenAddClubPopup} />
          </>
        ):<></>
        }
        { openJoinClubPopup ? (
          <>
            <JoinClubPopup getAllDataforAdmin={getAllDataforAdmin} setopenJoinClubPopup={setopenJoinClubPopup} />
          </>
        ):<></>
        }
        <div className='clubs_body scroll_admin_section scroll6_height' >
            { allClubsData && allClubsData.length>0 ?(
                <>
                    {
                        filteredSessions.map((club,index)=>{
                            return(
                                <>
                                    <ClubsComp club={club} index={index} />
                                </>
                            )
                        })
                    }
                </>
            ):(
                <>
                    <div className='empty_page flex' >
                        No club added yet.
                    </div>
                </>
            )
            }
        </div>
    </>
  )
}

export default Clubs
