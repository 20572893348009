import React, {useContext, useEffect, useState} from 'react'
import { userData } from '../Context/Context'

function Dashboard({numberOfUsers,numberOfClubs,userSignedNotify}) {

  return (
    <>
        <div className='page-title'>Dashboard</div>
        <div className='dash_users_con'>
          <div>
            <div className='mini-bold-title'>Total Users</div>
            <div className='number_of_users'>{numberOfUsers?numberOfUsers-1:0}</div>
          </div>
          <div>
            <div className='mini-bold-title'>Total Clubs</div>
            <div className='number_of_users'>{numberOfUsers?numberOfClubs:0}</div>
          </div>
        </div>
        <div className='page-title mt_20'>Notifications</div>
        <div className='scroll_admin_section' >
          { userSignedNotify && userSignedNotify.length>0? userSignedNotify.map((notify,index)=>{
            return(
              <>
                <div className='notifications_con' >New user <span className='notify_gradient' > {notify.email} </span> just signed up.</div>
              </>
            )
          }):
            <div className='flex h_100' >
              <div className='empty_page2' > No notification yet</div>
            </div>
          }
        </div>
    </>
  )
}

export default Dashboard
