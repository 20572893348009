import React, { useEffect, useState, useContext } from 'react'
import ImageViewer from './ImageViewer';
import { userData } from '../Context/Context';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import SelectUserSessions from './SelectUserSessions';
import ConfirmPopup from './ConfirmPopup';
import { Link } from 'react-router-dom';
import SelectUserPrivateSessions from './PrivateSessionsPopup/SelectUserPrivateSessions';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function DrillsComp({club,session,drill,index,userSession,isMember,isOwner,getClubDataById,userSessions}) {
    const {url,userdata} = useContext(userData);
    // console.log(club.drills);
    // console.log(drill);
    const [drillData,setdrillData] = useState({});
    const [showDropdown,setshowDropdown] = useState(false);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    
    const openImageViewer = () => {
        setshowDropdown(false);
        setIsViewerOpen(true);
    };
    const closeImageViewer = () => {
      setIsViewerOpen(false);
    };
    const handleMouseLeave = ()=>{
        setshowDropdown(false);
    }
    const [openUserSessions,setopenUserSessions] = useState(false);
    const [copySuccessOpen,setcopySuccessOpen] = useState(false);
    const [copyFailedOpen,setcopyFailedOpen] = useState(false);
    const [loading,setloading] = useState(false);
    const handleCopySessionDrill = async () =>{
      // console.log(session);
      if(loading){
        return;
      }
      setshowDropdown(false);
      setloading(true);
      const res = await fetch(`${url}CopyClubSessionDrill`,{
          method: "POST",
          headers: {
            "Content-Type" : "application/json"
          },
          body: JSON.stringify({
            clubId: club._id,
            sessionId: session._id,
            drillId :drillData._id,
            userId: userdata._id,
          }),
      });
      const data = await res.json();
      setloading(false);
      if(data.msg === "success"){
        setcopySuccessOpen(true);
        getClubDataById();
        setTimeout(() => {
          setcopySuccessOpen(false);
          setcopyFailedOpen(false);
        }, 3000);
      }else{
        setcopyFailedOpen(true);
        setTimeout(() => {
          setcopySuccessOpen(false);
          setcopyFailedOpen(false);
        }, 3000);
      }
    }
    const handleCopyToSessionClick = ()=>{
      setshowDropdown(false);
      setopenUserSessions(true);
    }
    const [openPrivateUserSessions,setopenPrivateUserSessions] = useState(false);
    const handleCopyToPrivateSessionClick = ()=>{
      setshowDropdown(false);
      setopenPrivateUserSessions(true);
    }

    const [openDeleteDrillPopup,setopenDeleteDrillPopup] = useState(false);
    const handleDeleteDrillClick = ()=>{
      setshowDropdown(false);
      setopenDeleteDrillPopup(true);
    }
    const [loading2,setloading2] = useState(false);
    const handleConfirmDeletePopup = async () =>{
          if(loading2){
            return;
          }
          setloading2(true);
          const res = await fetch(`${url}deleteClubSessionDrill`,{
              method: "POST",
              headers: {
                "Content-Type" : "application/json"
              },
              body: JSON.stringify({
                clubId: club._id,
                sessionId: session._id,
                drillId: drillData?._id,
                userId: userdata._id,
              }),
          });
          const data = await res.json();
          setloading2(false);
          getClubDataById();
          if(data.msg === "success"){
            setopenDeleteDrillPopup(false);
          }
    }

    useEffect(()=>{
        const matchedDrill = club.drills.filter( (mydrill) =>{ return mydrill._id === drill.id });
        setdrillData(matchedDrill[0]);
    },[getClubDataById]);

  return (
    <>
        <Snackbar open={copySuccessOpen} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
            <Alert severity="success" sx={{ width: '100%' }}>
                Drill Copied successfully!
            </Alert>
        </Snackbar>
        <Snackbar open={copyFailedOpen} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
            <Alert severity="error" sx={{ width: '100%' }}>
                Copying failed, refresh and try again!
            </Alert>
        </Snackbar>
        <div className='drills_comp' style={{position:'relative'}} onMouseLeave={handleMouseLeave} >
            <div className='drills_comp_left' >
                <img src={drillData?.imageUrl} className='pointer' onClick={() => openImageViewer()} />
                {isViewerOpen && (
                    <ImageViewer image={drillData?.imageUrl} closeImageViewer={closeImageViewer} />
                )}
            </div>
            <div className='drills_comp_right' >
                <div className='grid_2_col' >
                    <div>
                        <div className='normal_text' > <span className='g_mini_text fs_20' >Drill Name : </span> {drillData?.name} </div>
                    </div>
                    <div>
                        <div className='normal_text' > <span className='g_mini_text fs_20' >Category : </span> {drillData?.category} </div>
                    </div>
                </div>
                <div className='flex_align' >
                    <div>
                        <div className='normal_text' > <span className='g_mini_text fs_20' >Field Size : </span> {drillData?.fieldSize} </div>
                    </div>
                    <div>
                        <div className='normal_text' > <span className='g_mini_text fs_20' >Duration : </span> {drillData?.duration} </div>
                    </div>
                </div>
                <div className='grid_3_col' >
                    <div>
                        <div className='normal_text' > <span className='g_mini_text fs_20' >Setup : </span></div>
                        <div className='normal_text scroll_admin_section height_3r' > {drillData?.setup} </div>
                    </div>
                    <div>
                        <div className='normal_text' > <span className='g_mini_text fs_20' >Instructions : </span></div>
                        <div className='normal_text scroll_admin_section height_3r' > {drillData?.instructions} </div>
                    </div>
                    <div>
                        <div className='normal_text' > <span className='g_mini_text fs_20' >Coaching Points : </span></div>
                        <div className='normal_text scroll_admin_section height_3r' > {drillData?.points} </div>
                    </div>
                </div>
            </div>
            <div style={{position:'absolute',right:'11px'}} >
                { (isOwner || isMember) &&
                    <div className='three-dots' onClick={()=>{setshowDropdown(!showDropdown)}} >
                        <svg xmlns="http://www.w3.org/2000/svg" fill='#49d0dc' height="20" width="6" viewBox="0 0 128 512"><path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"/></svg>
                    </div>
                }
                { showDropdown &&
                    <>
                      {
                        userSession ?
                        <>
                          <div className='club-setting-dropdown' >
                            <div className='club-setting-dropdown-div b-t-lr p-top-54' onClick={openImageViewer} >View Drill</div>
                            <div className='club-setting-dropdown-link' >
                              <Link className='bsjdvb' to={`/new-drill?update-club-drill=true&club=${club._id}&session=${session._id}&drill=${drill.id}`} target='_blank' >Update</Link>
                            </div>
                            <div className='club-setting-dropdown-link' >
                              <Link className='bsjdvb' to={`/print-club-drill?club=${club._id}&drill=${drill.id}`} target='_blank' >Print</Link>
                            </div>
                            {/* <div className='club-setting-dropdown-div' >Update</div> */}
                            {/* <div className='club-setting-dropdown-div' >Print</div> */}
                            <div className='club-setting-dropdown-div' onClick={handleCopySessionDrill} >Copy</div>
                            <div className='club-setting-dropdown-div' onClick={handleCopyToSessionClick} >Copy to Club Session</div>
                            <div className='club-setting-dropdown-div' onClick={handleCopyToPrivateSessionClick} >Copy to Private Session</div>
                            {/* <div className='club-setting-dropdown-link' >
                              <Link className='bsjdvb' to={`/print-club-session?club=${club._id}&session=${session._id}`} target='_blank' >Print Session</Link>
                            </div> */}
                            <div className='b-b-lr p-bot-54 club-setting-dropdown-div' onClick={handleDeleteDrillClick} >Delete</div>
                          </div>
                          </>
                          :
                          <>
                          <div className='club-setting-dropdown' >
                            <div className='club-setting-dropdown-div b-t-lr p-top-54' onClick={openImageViewer} >View Drills</div>
                            {/* <div className='club-setting-dropdown-div' >Print</div> */}
                            <div className='club-setting-dropdown-link' >
                              <Link className='bsjdvb' to={`/print-club-drill?club=${club._id}&drill=${drill.id}`} target='_blank' >Print</Link>
                            </div>
                            <div className='club-setting-dropdown-div b-b-lr p-bot-54' onClick={handleCopyToSessionClick} >Copy to Session</div>
                          </div>
                        </>
                      }
                    </>
                }
            </div>
        </div>
        { openUserSessions &&
          <SelectUserSessions setopenUserSessions={setopenUserSessions} userSessions={userSessions} getClubDataById={getClubDataById}
            fromSession={session} club={club} drill={drillData}
          />
        }
        { openPrivateUserSessions &&
          <SelectUserPrivateSessions setopenPrivateUserSessions={setopenPrivateUserSessions} userSessions={userSessions} getClubDataById={getClubDataById}
            fromSession={session} club={club} drill={drillData}
          />
        }
        { openDeleteDrillPopup?
            <>
                <ConfirmPopup open={openDeleteDrillPopup} setOpen={setopenDeleteDrillPopup} handleconfirm={handleConfirmDeletePopup}
                    message={`Are you sure you want to delete your drill: ${drillData?.name} ?`} loading={loading2} />
            </>:<></>
        }
    </>
  )
}

export default DrillsComp