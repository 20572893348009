import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ClubsPopup from './ClubsPopup/ClubsPopup';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SessionComp({session,index,userdata,setuserdata,url,alradyErrorMsg,setalradyErrorMsg,addSuccessMsg,setaddSuccessMsg,getLoggedin,popup,selectedDrillId,setsessionPopup,setselectedDrillId}) {
    const [dropDown,setDropDown] = useState(false);
    const [showSetting,setshowSetting] = useState(false);
    const [submitting,setSubmitting] = useState(false);
    const handleMouseOver = ()=>{
        setshowSetting(true);
    }
    const handleMouseLeave = ()=>{
        setshowSetting(false);
        setDropDown(false);
    }
    const deleteSession = async ()=>{
        // setSubmitting(true);
        setDropDown(false);
        const res = await fetch(`${url}deleteSession`,{
          method: "POST",
          headers: {
            "Content-Type" : "application/json"
          },
          body: JSON.stringify({
            id: userdata._id,
            sessionId: session._id,
          }),
        });
        const data = await res.json();
        
        if(data.msg === "success"){
            console.log('success');
            // getLoggedin();
            setuserdata(data.data);
        }else{
            console.log('failed');
        }
    }
    const handleAddDrillToSession = async ()=>{
        setSubmitting(true);
        const res = await fetch(`${url}addDrillToSession`,{
            method: "POST",
            headers: {
              "Content-Type" : "application/json"
            },
            body: JSON.stringify({
              id: userdata._id,
              sessionId: session._id,
              drillId: selectedDrillId,
            }),
          });
          setSubmitting(false);
          const data = await res.json();
  
          if(data.msg === "success"){
            //   console.log('success');
              setaddSuccessMsg(true);
              setsessionPopup(false);
              setselectedDrillId('');
              // getLoggedin();
              setuserdata(data.data);
          }else if(data.msg=== 'already exists'){
            setalradyErrorMsg(true);
            setTimeout(() => {
              setalradyErrorMsg(false);
            }, 4000);
          }else{
              setsessionPopup(false);
            //   console.log('failed');
          }
    }
    const [openClubPopup,setopenClubPopup] = useState(false);
    const handleClosePopup = ()=>{
      setopenClubPopup(false);
    }
    const handleOpenPopup = ()=>{
      setopenClubPopup(true);
    }
    const [copySuccessOpen,setcopySuccessOpen] = useState(false);
    const [copyFailedOpen,setcopyFailedOpen] = useState(false);
    // const CopyPrivateSessionToClubSession = async () =>{
    //   const res = await fetch(`${url}CopyPrivateSessionToClubSession`,{
    //       method: "POST",
    //       headers: {
    //         "Content-Type" : "application/json"
    //       },
    //       body: JSON.stringify({
    //         clubId: club._id,
    //         sessionId: session._id,
    //         userId: userdata._id,
    //       }),
    //   });
    //   const data = await res.json();
    //   if(data.msg === "success"){
    //     setcopySuccessOpen(true);
    //     setTimeout(() => {
    //       setcopySuccessOpen(false);
    //       setcopyFailedOpen(false);
    //     }, 3000);
    //   }else{
    //     setcopyFailedOpen(true);
    //     setTimeout(() => {
    //       setcopySuccessOpen(false);
    //       setcopyFailedOpen(false);
    //     }, 3000);
    //   }
    // }

    return (
    <>  
        <Snackbar open={copySuccessOpen} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
            <Alert severity="success" sx={{ width: '100%' }}>
                Session Copied successfully!
            </Alert>
        </Snackbar>
        <Snackbar open={copyFailedOpen} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
            <Alert severity="error" sx={{ width: '100%' }}>
                Copying failed, refresh and try again!
            </Alert>
        </Snackbar>
        <div className='session-elem' style={{display:popup?'flex':'',justifyContent:popup?'space-between':''}} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseOver} >
            <NavLink to={`/library/edit-session/${session._id}`} style={{textDecoration:'none'}} >
                <div className='s-con-title1' > {session.name} </div>
                <div className='s-con-title2' > {new Date(session.date).toLocaleString('en-US', { day: 'numeric', month: 'short',year: 'numeric' })} ( {session.drills.length} drills) </div>
            </NavLink>
            { showSetting && !popup &&
                <svg onClick={()=>{setDropDown(!dropDown)}} xmlns="http://www.w3.org/2000/svg" height="1.2em" viewBox="0 0 512 512"><path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/>
                </svg>
            }
            { dropDown && !popup &&
                <div className='setting-dropdown'>
                    <NavLink to={`/library/edit-session/${session._id}`} style={{textDecoration:'none'}} >
                        <div>Edit</div>
                    </NavLink>
                    <div onClick={()=>{deleteSession()}} >Delete</div>
                    <div onClick={handleOpenPopup} >Copy to club</div>
                </div>
            }
            { popup &&
                <div className='select-add-session' onClick={handleAddDrillToSession} style={{color:'#2f8c2f'}} > { submitting?'SAVING':'SELECT' } </div>
            }
        </div>
        { openClubPopup &&
            <ClubsPopup session={session} handleClosePopup={handleClosePopup} />
        }
    </>
  )
}

export default SessionComp
