import React, { useState,useEffect,useContext } from 'react'
import { userData } from '../../Context/Context';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function UpdateUserPopup({user,setopenUpdateUserPopup}) {
    const {userdata,setuserdata,url,getAllDataforAdmin} = useContext(userData);
    const [email,setemail] = useState('');
    const [emailUpdatesuccess,setemailUpdatesuccess] = useState(false);
    const [emailUpdateFail,setemailUpdateFail] = useState(false);
    const [loading,setloading] = useState(false);

    const handleSubmitEmail =async (e)=> {
        e.preventDefault();
        setloading(true);
        const res = await fetch(`${url}changeUserEmail`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "authToken": localStorage.getItem("authToken")
              },
              body: JSON.stringify({
                id: user._id,
                newEmail: email,
              }),
            });
            setloading(false);
            const data = await res.json();
            if(data.msg==='success'){
                getAllDataforAdmin();
              setemailUpdatesuccess(true);
              setemail('');
              setTimeout(() => {
                  setemailUpdatesuccess(false);
              }, 2000);
            }
            else{
                setemailUpdateFail(true);
                setTimeout(() => {
                    setemailUpdateFail(false);
                }, 2000);
            };
    }

    const [password,setpassword] = useState('');
    const [confirmPassword,setconfirmPassword] = useState('');
    const [passwordUpdatesuccess,setpasswordUpdatesuccess] = useState(false);
    const [passwordUpdateFail,setpasswordUpdateFail] = useState(false);
    const [notMatching,setnotMatching] = useState(false);
    const [openLengthError,setopenLengthError] = useState(false);

    const handleSubmitUpdatePassword =async (e)=> {
        e.preventDefault();
        if(password.length<8){
            setopenLengthError(true);
            setTimeout(() => {
                setopenLengthError(false);
            }, 3000);
            return;
        }
        if(password !== confirmPassword){
            setnotMatching(true);
            setTimeout(() => {
                setnotMatching(false);
            }, 3000);
            return;
        }
        setloading(true);
        const res = await fetch(`${url}changeUserPassword`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "authToken": localStorage.getItem("authToken")
              },
              body: JSON.stringify({
                id: user._id,
                newPassword: password,
              }),
            });
            setloading(false);
            const data = await res.json();
            if(data.msg==='success'){
                getAllDataforAdmin();
              setpasswordUpdatesuccess(true);
              setpassword('');
              setconfirmPassword('');
              setTimeout(() => {
                setpasswordUpdatesuccess(false);
              }, 2000);
            }
            else{
                setpasswordUpdateFail(true);
                setTimeout(() => {
                    setpasswordUpdateFail(false);
                }, 2000);
            };
    }
    const [openerrorDeleteUser,setopenerrorDeleteUser] = useState(false);
    const [openConfirmDelete,setopenConfirmDelete] = useState(false);
    
    const handleDeleteUser =async (e)=> {
        e.preventDefault();
        setloading(true);
        const res = await fetch(`${url}deleteUserByAdmin`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "authToken": localStorage.getItem("authToken")
              },
              body: JSON.stringify({
                id: user._id,
              }),
            });
            setloading(false);
            const data = await res.json();
            if(data.msg==='success'){
                getAllDataforAdmin();
                setopenConfirmDelete(false);
                setopenUpdateUserPopup(false);
            }
            else{
                getAllDataforAdmin();
                setopenerrorDeleteUser(true);
                setTimeout(() => {
                    setopenerrorDeleteUser(false);
                }, 3000);
            };
    }

  return (
    <>
        <Snackbar open={emailUpdatesuccess} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="success" sx={{ width: '100%' }}>
                Email changed Successfully!
            </Alert>
        </Snackbar>
        <Snackbar open={emailUpdateFail} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="error" sx={{ width: '100%' }}>
                Email already exist or update failed!
            </Alert>
        </Snackbar>
        <Snackbar open={passwordUpdatesuccess} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="success" sx={{ width: '100%' }}>
                Password changed Successfully!
            </Alert>
        </Snackbar>
        <Snackbar open={passwordUpdateFail} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="error" sx={{ width: '100%' }}>
                Password update failed, please try later!
            </Alert>
        </Snackbar>
        <Snackbar open={notMatching} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="error" sx={{ width: '100%' }}>
                Password not matching!
            </Alert>
        </Snackbar>
        <Snackbar open={openLengthError} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="error" sx={{ width: '100%' }}>
                New Password should contain atleast 8 characters!
            </Alert>
        </Snackbar>
        <Snackbar open={openerrorDeleteUser} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity="error" sx={{ width: '100%' }}>
                Error deleting user, please try later!
            </Alert>
        </Snackbar>
        <Dialog
          open={openConfirmDelete}
          onClose={()=>{setopenConfirmDelete(false)}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <div className='normal_text' style={{color:'black'}} >Agree to delete user.</div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete user {user.email}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>{setopenConfirmDelete(false)}}>Disagree</Button>
            <Button onClick={handleDeleteUser} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
        <div className='popup_full_page' >
            <div className='update_user_inner'>
                <div className='register_con' >
                    <div>
                        <div className='flex_between2 width_65' >
                            <div className='g_mini_text width_rt fw_700 font_19 ' >
                                <div>
                                    Update Email
                                </div>
                            </div>
                            <div>
                                <div className='delete_bg pointer flex' onClick={()=>{setopenConfirmDelete(true)}} >Delete</div>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmitEmail}>
                        <div className='title_input_con' >
                            <div className='register-mini-bold-title' >New Email</div>
                            <input 
                                className='register_input'
                                name='email'
                                placeholder='Enter new email'
                                type='email'
                                onChange={(e)=>{setemail(e.target.value)}}
                                value={email}
                            />
                        </div>
                        <button type='submit' disabled={loading} className='register_btn' >Update Email</button>
                    </form>
                    <form onSubmit={handleSubmitUpdatePassword} >
                        <div>
                            <div className='g_mini_text fw_700 width_65 m_55 ' >Update Password</div>
                        </div>
                        <div className='title_input_con' >
                        <div className='register-mini-bold-title' >New Password</div>
                        <input 
                            className='register_input'
                            name='password'
                            placeholder='Enter password'
                            type='password'
                            onChange={(e)=>{setpassword(e.target.value)}}
                            value={password}
                        />
                        </div>
                        <div className='title_input_con' >
                        <div className='register-mini-bold-title' >Confirm Password</div>
                        <input 
                            className='register_input'
                            name='email'
                            placeholder='Enter password again'
                            type='password'
                            onChange={(e)=>{setconfirmPassword(e.target.value)}}
                            value={confirmPassword}
                        />
                        </div>
                        <button type='submit' disabled={loading} className='register_btn' >Update Password</button>
                    </form>
                </div>
            </div>
        </div>
        <div className='x_mark' onClick={()=>{setopenUpdateUserPopup(false)}} >
            <svg width="22" height="32" viewBox="0 0 30 40" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.7656 11.7656C27.7422 10.7891 27.7422 9.20312 26.7656 8.22656C25.7891 7.25 24.2031 7.25 23.2266 8.22656L15 16.4609L6.76562 8.23437C5.78906 7.25781 4.20312 7.25781 3.22656 8.23437C2.25 9.21094 2.25 10.7969 3.22656 11.7734L11.4609 20L3.23438 28.2344C2.25781 29.2109 2.25781 30.7969 3.23438 31.7734C4.21094 32.75 5.79687 32.75 6.77344 31.7734L15 23.5391L23.2344 31.7656C24.2109 32.7422 25.7969 32.7422 26.7734 31.7656C27.75 30.7891 27.75 29.2031 26.7734 28.2266L18.5391 20L26.7656 11.7656Z" fill="white"/>
            </svg>
        </div>
    </>
  )
}

export default UpdateUserPopup
